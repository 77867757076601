import React, { useCallback, useState } from 'react';
import { Edit, Bookmark, BookmarkBorderOutlined } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { dumpAllIndexedData, toggleBookmarkedRow } from '../../services/IndexedDbService';
import { useTableDispatch, useTableState, SET_INDEXED_DATA, SET_TABLE_DATA } from "../../contexts/TableContext";

export const BookmarkListItem = () => {
  const tableDispatch = useTableDispatch();
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const [expanded, setExpanded] = useState(false);

  const { data, selectedData } = useTableState();
  const [ t ] = useTranslation('translations');

  const toggleBookmarkOnSelected = (bookmark) => {
    const tempTableData = data;
    Object.keys(selectedData).forEach((artifactId, index) => {
      const rowIndex = tempTableData.findIndex((row) => row.id === artifactId);

      if (rowIndex !== -1) {
        tempTableData[rowIndex].bookmarked = bookmark;
      }

      toggleBookmarkedRow(artifactId, bookmark).then();

      if (index === Object.keys(selectedData).length - 1) {
        dumpAllIndexedData().then((data) => {
          tableDispatchCallback({
            type: SET_INDEXED_DATA,
            indexedData: data
          })
        });
      }

      tableDispatchCallback({
        type: SET_TABLE_DATA,
        data: [...tempTableData]
      })
    })
  }

  return (
    <>
      <ListItemButton onClick={() => setExpanded(!expanded)}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText primary={t('menu.actionHeader.listItems.bookmark')} />
      </ListItemButton>
      <Collapse in={expanded}>
        <List componen='div' disablePadding>
          <ListItemButton
            onClick={() => toggleBookmarkOnSelected(true)}
            sx={{
              pl: 4
            }}
          >
            <ListItemIcon>
              <Bookmark />
            </ListItemIcon>
            <ListItemText primary={t('menu.actionHeader.listItems.addBookmark')} />
          </ListItemButton>
          <ListItemButton
            onClick={() => toggleBookmarkOnSelected(false)}
            sx={{
              pl: 4
            }}
          >
            <ListItemIcon>
              <BookmarkBorderOutlined />
            </ListItemIcon>
            <ListItemText primary={t('menu.actionHeader.listItems.removeBookmark')} />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  )
}
