import { Box, CircularProgress, Fade, MenuItem, TextField } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'whatwg-fetch';

import { useAuthsState } from '../../app/auths/AuthsContext';
import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from "../../contexts/MainContext";
import { SET_BASE_URL, SET_DESCRIPTION_OBJECT, usePrimusDispatch, usePrimusState } from "../../contexts/PrimusContext";
import { RESET_TABLE_STATE, useTableDispatch } from "../../contexts/TableContext";
import { EkulturAPI } from '../../services/EkulturAPI';
import { PrimusApi } from "../../services/PrimusApi";

export const DatabaseSelector = () => {
  const [availableServers, setAvailableServers] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = usePrimusDispatch();
  const tableDispatch = useTableDispatch();
  const dispatchCallback = useCallback(dispatch, [dispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);
  const mainDispatch = useMainDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);

  const { baseUrl } = usePrimusState()
  const { notifications } = useMainState();
  const { t } = useTranslation('translations');
  const { userData } = useAuthsState();

  const dispatchError = (transKey, error) => {
    mainDispatchCallback({
      type: SET_NOTIFICATIONS,
      notifications: [...notifications,
        {
          type: 'error',
          text: t(transKey),
          error: error
        }
      ]
    });
  };

  useEffect(() => {
    if (baseUrl !== '') {
      let primus = new PrimusApi(baseUrl);

      primus.createDescriptionObject().then((descriptionObject) => {
        dispatchCallback({
          type: SET_DESCRIPTION_OBJECT,
          descriptionObject: descriptionObject
        });
      }).catch((e) => dispatchError('table.default.jobs.errorDatabaseContextData', e));
    }
  }, [baseUrl])

  useEffect(() => {
    const api = new EkulturAPI();

    if (!availableServers.length) {
      api.getAvailablePrimusInstances(userData.uniqueId).then((res) => {
        let servers = res ?? [];
        if (window.location.host.startsWith('localhost')) {
          servers.unshift({
            id: -1,
            name: 'Localhost',
            serverUrl: 'http://localhost:5000'
          });
        }
        setAvailableServers(servers);
      }).finally(() => {
        setLoading(false);
      }).catch((e) => dispatchError('table.default.jobs.errorGettingInstances', e));
    } else {
      setLoading(false);
    }
  }, [availableServers, userData])

  return (
    <>
      {!loading ? (
        <Fade in={true} unmountOnExit={true}>
          <Box sx={{
            padding: '0 .5rem',
          }}>
            <TextField
              label={t('menu.washer.selectPrimusServer')}
              disabled={availableServers.length < 1}
              fullWidth
              onChange={(event) => {
                fetch(`${event.target.value}/cms_api/v1.0/setting/get_client_config`, {
                  headers: new Headers({
                    Accept: 'application/json',
                    "Content-Type": 'application/json'
                  }),
                  method: 'GET'
                }).then((config) => {
                  return config.json();
                }).then((config) => {
                  dispatchCallback({
                    type: SET_BASE_URL,
                    baseUrl: event.target.value,
                    language: config.CLIENT_LANGUAGE,
                    version: parseInt(config.VERSION.split('.')[2])
                  });

                  tableDispatchCallback({
                    type: RESET_TABLE_STATE
                  });
                })
              }}
              select
              value={baseUrl}
            >
              {availableServers.map((server) => (
                <MenuItem key={server.id} value={server.serverUrl}>
                  {server.name}
                </MenuItem>
              ))
              }
            </TextField>
          </Box>
        </Fade>
      ) : (
        <Fade in={true} unmountOnExit={true}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '8px 10px',
              width: '100%'
            }}
          >
            <CircularProgress />
          </Box>
        </Fade>
      )}
    </>
  )
}