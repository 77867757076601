import { SolrSearchParams } from "../declarations/SolrSearchParameters";

import { DEFAULT_SOLR_FIELDS } from "../declarations/DefaultSolrFields";

export const buildSolrSearchParameters = (type, selectedList, statusFilter) => {
    let searchParameters = new SolrSearchParams();
    let onlyValid = statusFilter !== 'all' ? statusFilter === 'valid' : null;

    switch(type) {
        case 'classificationDuplicate':
        case 'classificationList': {
          searchParameters.addToQuery('object_type', 'ct_25');
          searchParameters.addToQuery('m_path', `*${selectedList}*`);
          searchParameters.setResultLimit(1000000);
          searchParameters.setQueryOpAnd(true);
          if (onlyValid !== null) {
            if (onlyValid) {
              searchParameters.setValidOnly(true);
            }
            if (!onlyValid) {
              searchParameters.setInvalidOnly(true);
            }
          }

          if (type === 'classificationDuplicate') {
            searchParameters.setFacetFields(['full_path']);
            searchParameters.setFacetSort('count');
            searchParameters.setFacetMincount(2);
          }

          break;
        }

        case 'properNameDuplicate':
        case 'properNameList': {
          searchParameters.setObjectType('agent');
          searchParameters.addToQuery('agent_type_id', selectedList);
          searchParameters.setQueryOpAnd(true);
          searchParameters.setResultLimit(1000000);
          searchParameters.setFields(DEFAULT_SOLR_FIELDS);
          if (onlyValid !== null) {
            if (onlyValid) {
              searchParameters.setValidOnly(true);
            }
            if (!onlyValid) {
              searchParameters.setInvalidOnly(true);
            }
          }

          if (type === 'properNameDuplicate') {
            searchParameters.setFacetFields(['artifact_name']);
            searchParameters.setFacetSort('count');
            searchParameters.setFacetMincount(2);
          }

          break;
        }

        case 'termDuplicate':
        case 'termList': {
          searchParameters.addToQuery('object_type', selectedList);
          searchParameters.setResultLimit(1000000);
          if (onlyValid !== null) {
            if (onlyValid) {
              searchParameters.setValidOnly(true);
            }
            if (!onlyValid) {
              searchParameters.setInvalidOnly(true);
            }
          }

            if (type === 'termDuplicate') {
              searchParameters.setFacetFields(['full_path']);
              searchParameters.setFacetSort('count');
              searchParameters.setFacetMincount(2);
            }

            break;
        }

        default: {
            throw new Error(`[primusSolrSearchBuilder] Unknown search type ${type}`);
        }
    }

    return searchParameters;
}