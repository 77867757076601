import React, { useCallback, useMemo, useState } from 'react';
import { Badge, Box, IconButton, Tooltip, Typography } from '@mui/material';
import { LinkOff, LinkOutlined} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { getMatchingColumns } from '../../declarations/TvattTableColumnDeclarations';
import { StepperDialog } from '../StepperDialog/StepperDialog';
import { dumpAllIndexedData, updateMatchedRows } from '../../services/IndexedDbService';
import { useTableDispatch, useTableState, SET_INDEXED_DATA, SET_TABLE_DATA } from "../../contexts/TableContext";

export const MatchCellButton = ({
  renderedCellValue,
  row
}) => {
  const [matchDialogOpen, setMatchDialogOpen] = useState(false);

  const tableDispatch = useTableDispatch();
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const { data } = useTableState();
  const { t } = useTranslation('translations');

  const columns = useMemo(() => {
    return getMatchingColumns(t, row.original.hasOwnProperty('code'));
  }, [t])

  const onConfirm = (rows, noMatch=false) => {
    if (noMatch) {
      let row = rows[0];
      let tempTableData = data;
      let index = tempTableData.findIndex((r) => r.id === row.id);

      if (index !== -1) {
        tempTableData[index].matchings = [];

        tableDispatchCallback({
          type: SET_TABLE_DATA,
          data: [...tempTableData]
        });

        updateMatchedRows({ id: row.id, matches: [] }).then(() => {
          return dumpAllIndexedData();
        }).then((data) => {
          tableDispatchCallback({
            type: SET_INDEXED_DATA,
            indexedData: data
          });
        });
      }
    }

    setMatchDialogOpen(false);
  };

  return (
    <>
      {renderedCellValue.length ? (
        <Tooltip title={<Typography>{t('table.default.hasMatches')}</Typography>} aria-label='add'>
          <IconButton aria-label='match' onClick={() => setMatchDialogOpen(true)}>
            <Badge badgeContent={renderedCellValue.length} color='primary'>
              <LinkOutlined />
            </Badge>
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={<Typography>{t('table.default.noMatches')}</Typography>} aria-label='add'>
          <Box p='8px'>
            <LinkOff color='disabled' />
          </Box>
        </Tooltip>
      )}
      <StepperDialog
        key={row.index}
        columns={columns}
        matchData={renderedCellValue}
        matchRow={row}
        disableBackdropDismiss={true}
        displayObjectInTitle={true}
        open={matchDialogOpen}
        onCancel={() => setMatchDialogOpen(false)}
        onConfirm={onConfirm}
        translationKey={'matchings'}
      />
    </>
  )
}