import { BookmarkBorderOutlined, BookmarkOutlined, Warning } from '@mui/icons-material';
import { Badge, Checkbox, CircularProgress, MenuItem, ToggleButton, Tooltip, Typography } from '@mui/material';
import React from 'react';

import { DuplicateCellButton } from '../components/TvattTable/DuplicateCellButton';
import { ErrorCellButton } from '../components/TvattTable/ErrorCellButton';
import { MatchCellButton } from '../components/TvattTable/MatchCellButton';
import { authorityMap } from "./UUIDs";

export const getMatchSourceColumns = (t) => {
  return [
    {
      accessorKey: 'original.caption',
      header: t('table.default.header.name'),
      Cell: ({ renderedCellValue, row }) => {
        if (row.original.original.authorityIdValue !== '') {
          return (
            <Badge color='primary' variant='dot'>
              {renderedCellValue}
            </Badge>
          )
        }
        return (
          <>
            {renderedCellValue}
          </>
        )
      }
    },
    {
      accessorKey: 'original.agentTypeIdValue',
      header: t('table.default.header.type')
    },
    {
      accessorKey: 'original.authorityIdValue',
      header: t('table.default.header.authority'),
      Cell: ({ renderedCellValue, row }) => {
        if (renderedCellValue !== '') {
          return (
            <a href={`https://kulturnav.org/${row.id.split('-').splice(1).join('-')}`} target='_blank' rel='noreferrer'>{renderedCellValue}</a>
          )
        }
        return null;
      },
    },
    {
      accessorKey: 'original.beginDate',
      header: t('table.default.header.born')
    },
    {
      accessorKey: 'original.endDate',
      header: t('table.default.header.death')
    },
    {
      accessorKey: 'original.inValid',
      header: t('table.default.header.invalid'),
      Cell: ({ renderedCellValue, row }) => (
        <>
          { !row.original.isLoading &&
            <Checkbox
              checked={renderedCellValue}
              disabled={true}
            />
          }
          { row.original.isLoading &&
            <CircularProgress />
          }
        </>
      )
    },
    {
      accessorKey: 'original.frequency',
      header: t('table.default.header.frequency'),
      muiTableBodyCellProps: () => ({
        align: 'right'
      })
    }
  ]
}

export const getTermsMatchSourceColumns = (t) => {
  return [
    {
      accessorKey: 'original.caption',
      header: t('table.default.header.name'),
      Cell: ({ renderedCellValue, row }) => {
        if (row.original.original.authorityIdValue !== '') {
          return (
            <Badge color='primary' variant='dot'>
              {renderedCellValue}
            </Badge>
          )
        }
        return (
          <>
            {renderedCellValue}
          </>
        )
      }
    },
    {
      accessorKey: 'original.code',
      header: t('table.default.header.code'),
    },
    {
      accessorKey: 'original.hierarchy',
      header: t('table.default.header.hierarchy'),
    },
    {
      accessorKey: 'original.authorityIdValue',
      header: t('table.default.header.authority'),
      Cell: ({ renderedCellValue, row }) => {
        if (renderedCellValue !== '') {
          return (
            <a href={`https://kulturnav.org/${row.id.split('-').splice(1).join('-')}`} target='_blank' rel='noreferrer'>
              {renderedCellValue}
            </a>
          )
        }
        return null;
      },
    },
    {
      accessorKey: 'original.inValid',
      header: t('table.default.header.invalid'),
      Cell: ({ renderedCellValue, row }) => (
        <>
          { !row.original.isLoading &&
            <Checkbox
              checked={renderedCellValue}
              disabled={true}
            />
          }
          { row.original.isLoading &&
            <CircularProgress />
          }
        </>
      )
    },
    {
      accessorKey: 'original.frequency',
      header: t('table.default.header.frequency'),
    },
    {
      accessorKey: 'hasChildren',
      header: t('dialogs.duplicates.hasChildren'),
      size: 40,
      Cell: ({ row }) => {
        if (row.original.original.hasChildren) {
          return (
            <Tooltip title={t('dialogs.duplicates.hasChildrenTooltip')}>
              <Warning color='warning' />
            </Tooltip>
          )
        }
        return null;
      },
    }
  ]
}

export const getDefaultColumns = (t, api, sir, bmr) => {
  return [
    {
      accessorKey: 'edit',
      header: 'edited (hidden)',
      size: 120,
      columnFilterModeOptions: [],
      visibleInShowHideMenu: false,
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return !!row.original.edit;
        }

        if (!filterValue) {
          return !row.original.edit;
        }

        return true;
      }
    },
    {
      accessorKey: 'caption',
      header: t('table.default.header.name'),
      size: 310,
      Cell: ({ renderedCellValue, row }) => {
        if (row.original.authorityIdValue !== '') {
          return (
            <Badge color='primary' variant='dot'>
              {renderedCellValue}
            </Badge>
          )
        }
        return (
          <>
            {renderedCellValue}
          </>
        )
      },
      filterFn: 'contains',
      getGroupingValue: (row) => row.caption.toLowerCase(),
      renderColumnFilterModeMenuItems: ({ column, onSelectFilterMode }) => [
        <MenuItem
          key='contains'
          onClick={() => onSelectFilterMode('contains')}
        >
          {t('table.default.filterContains')}
        </MenuItem>,
        <MenuItem
          key='containsCapsSensitive'
          onClick={() => onSelectFilterMode('captionContainsCaps')}
        >
          {t('table.default.filterContainsCaps')}
        </MenuItem>,
        <MenuItem
          key='fuzzy'
          onClick={() => onSelectFilterMode('fuzzy')}
        >
          {t('table.default.filterFuzzy')}
        </MenuItem>,
        <MenuItem
          key='startsWith'
          onClick={() => onSelectFilterMode('startsWith')}
        >
          {t('table.default.filterStartsWith')}
        </MenuItem>,
        <MenuItem
          key='endsWith'
          onClick={() => onSelectFilterMode('endsWith')}
        >
          {t('table.default.filterEndsWith')}
        </MenuItem>,
        <MenuItem
          key='equals'
          onClick={() => onSelectFilterMode('equals')}
        >
          {t('table.default.filterEquals')}
        </MenuItem>,
        <MenuItem
          key='notEquals'
          onClick={() => onSelectFilterMode('notEquals')}
        >
          {t('table.default.filterNotEquals')}
        </MenuItem>
      ]
    },
    {
      accessorKey: 'agentTypeIdValue',
      header: t('table.default.header.type'),
      size: 76,
      filterVariant: 'multi-select',
      columnFilterModeOptions: []
    },
    {
      accessorKey: 'authorityIdValue',
      header: t('table.default.header.authority'),
      size: 98,
      columnFilterModeOptions: [],
      Cell: renderAuthorityCell,
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.authorityIdValue !== '';
        }
        if (!filterValue) {
          return row.original.authorityIdValue === '' || row.original.authorityIdValue === null
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.hasAuthority'), value: true },
        { text: t('filters.noAuthority'), value: false },
      ],
    },
    {
      accessorKey: 'beginDate',
      header: t('table.default.header.born'),
      columnFilterModeOptions: [],
      size: 101,
      filterFn: (row, id, filterValue) => {
        if (filterValue === 'yes') {
          return row.original.beginDate !== null && row.original.beginDate !== '';
        }
        if (filterValue === 'no') {
          return row.original.beginDate === null || row.original.beginDate === '';
        }
        if (filterValue === 'or') {
          return (row.original.beginDate !== null && row.original.beginDate !== '') || (row.original.endDate !== null && row.original.endDate !== '');
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.hasDob'), value: 'yes' },
        { text: t('filters.noDob'), value: 'no' },
        { text: t('filters.hasDobDod'), value: 'or' }
      ],
    },
    {
      accessorKey: 'endDate',
      header: t('table.default.header.death'),
      size: 101,
      columnFilterModeOptions: [],
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.endDate !== null && row.original.endDate !== '';
        }
        if (!filterValue) {
          return row.original.endDate === null || row.original.endDate === '';
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.hasDod'), value: true },
        { text: t('filters.noDod'), value: false },
      ],
    },
    {
      accessorKey: 'inValid',
      header: t('table.default.header.invalid'),
      size: 93,
      columnFilterModeOptions: [],
      Cell: ({ renderedCellValue, row }) => (
          <>
            { !row.original.isLoading &&
              <Checkbox
                checked={ renderedCellValue }
                disabled={ row.original.frequency > 0 && !row.original.inValid}
                onChange={(e, c) => {
                  sir(row, c);
                }}
              />
            }
            { row.original.isLoading &&
              <CircularProgress />
            }
          </>
      ),
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.inValid;
        }
        if (!filterValue) {
          return !row.original.inValid;
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.isInvalid'), value: true },
        { text: t('filters.isValid'), value: false },
      ],
    },
    {
      accessorKey: 'frequency',
      header: t('table.default.header.frequency'),
      muiTableBodyCellProps: () => ({
        align: 'right'
      }),
      size: 102,
      columnFilterModeOptions: [],
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.frequency > 0;
        }
        if (!filterValue) {
          return row.original.frequency === 0;
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.hasFrequency'), value: true },
        { text: t('filters.noFrequency'), value: false },
      ],
    },
    {
      accessorKey: 'errors',
      header: t('table.default.header.warning'),
      size: 102,
      columnFilterModeOptions: [],
      Cell: ({ renderedCellValue, row }) => {
        if (renderedCellValue) {
          return (
            <ErrorCellButton
              errors={renderedCellValue}
              messages={row.original.messages}
              row={row.original}
            />
          )
        }
      },
      filterVariant: 'multi-select',
      filterFn: (row, id, filterValue) => {
        if (filterValue.length === 2 || filterValue.length === 0) {
          return true;
        }

        return filterValue.length > 0 && filterValue.includes(true)
          ? row.original.errors > 0
          : row.original.errors < 1;
      },
      filterSelectOptions: [
        { text: t('table.default.header.hasErrors'), value: true },
        { text: t('table.default.header.noErrors'), value: false },
      ],
    },
    {
      accessorKey: 'duplicates',
      field: 'duplicates',
      header: t('table.default.header.duplicates'),
      size: 111,
      columnFilterModeOptions: [],
      Cell: ({ renderedCellValue, row }) =>
        !!renderedCellValue && renderedCellValue > 1 ? (
          <DuplicateCellButton renderedCellValue={renderedCellValue} row={row} />
        ) : (
          <></>
        ),
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.duplicates > 1;
        }
        if (!filterValue) {
          return row.original.duplicates === 1;
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.hasDuplicates'), value: true },
        { text: t('filters.noDuplicates'), value: false },
      ],
    },
    {
      accessorKey: 'matchings',
      field: 'matchings',
      header: t('table.default.header.matchings'),
      size: 118,
      columnFilterModeOptions: [],
      sortingFn: (rowA, rowB, columnId) => {
        const rowAMatches = rowA.original.matchings?.length || 0;
        const rowBMatches = rowB.original.matchings?.length || 0;
        if (rowAMatches === rowBMatches) {
          return 0;
        }

        return rowAMatches > rowBMatches ? 1 : -1;
      },
      Cell: ({ renderedCellValue, row }) =>
        renderedCellValue
          ? <MatchCellButton renderedCellValue={renderedCellValue} row={row} />
          : <></>,
      filterVariant: 'multi-select',
      filterFn: (row, id, filterValue) => {
        if (
          (filterValue.length === 2 &&
            filterValue.includes(true) &&
            filterValue.includes(false)) ||
          filterValue.length === 0
        ) {
          return true;
        }


        if (filterValue.length === 1 && filterValue.includes(null)) {
          return row.original.matchings === null;
        }

        if (filterValue.length > 0 && filterValue.includes(true)) {
          if (row.original.matchings === null) {
            return filterValue.includes(null);
          }

          if (filterValue.includes(false)) {
            return true;
          }

          return row.original.matchings?.length >= 1;
        }

        if (row.original.matchings === null) {
          return false;
        }

        return row.original.matchings?.length < 1;
      },
      filterSelectOptions: [
        { text: t('table.default.header.noMatchPerformed'), value: null },
        { text: t('table.default.header.hasMatch'), value: true },
        { text: t('table.default.header.noMatch'), value: false },
      ],
    },
    {
      accessorKey: 'bookmarked',
      header: t('table.default.header.pin'),
      size: 75,
      columnFilterModeOptions: [],
      Cell: ({ renderedCellValue, row }) => {
        return (
          <ToggleButton
            onChange={(e, v) => {
              bmr(row);
            }}
            selected={renderedCellValue}
            value={renderedCellValue}
          >
            { renderedCellValue &&
              <BookmarkOutlined />
            }
            { !renderedCellValue &&
              <BookmarkBorderOutlined />
            }
          </ToggleButton>
        )
      },
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.bookmarked;
        }
        if (!filterValue) {
          return !row.original.bookmarked;
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.isBookmarked'), value: true },
        { text: t('filters.notBookmarked'), value: false },
      ],
    }
  ];
};

export const getMatchingColumns = (t, isTerms) => {
  let columns = [
    {
      accessorKey: 'score',
      header: t(isTerms ? 'dialogs.matchings.termScore' : 'dialogs.matchings.score'),
      Cell: ({ renderedCellValue, row }) => displayPercent(renderedCellValue),
      muiTableHeadCellProps: ({cell}) => ({
        sx: {
          borderStyle: 'solid',
          borderWidth: '0 1px 1px 1px',
          borderColor: 'rgb(224 224 224)'
        }
      }),
      muiTableBodyCellProps: ({cell}) => ({
        sx: {
          borderStyle: 'solid',
          borderWidth: '0 1px 1px 1px',
          borderColor: 'rgb(224 224 224)'
        }
      })
    },
    {
      accessorKey: 'fullCaption',
      header: t('dialogs.matchings.fullCaption'),
      size: 350,
      muiTableHeadCellProps: ({cell}) => ({
        sx: {
          borderStyle: 'solid',
          borderWidth: '0 1px 1px 0',
          borderColor: 'rgb(224 224 224)'
        }
      }),
      muiTableBodyCellProps: ({cell}) => ({
        sx: {
          borderStyle: 'solid',
          borderWidth: '0 1px 1px 0',
          borderColor: 'rgb(224 224 224)'
        }
      }),
      Cell: ({ renderedCellValue, row }) => (
        <a
          href={`https://kulturnav.org/${row.original.uuid}`}
          target='_blank'
          rel='noreferrer'
        >
          {renderedCellValue}
        </a>
      ),
    },
    {
      accessorKey: 'dataset',
      header: t('dialogs.matchings.dataset'),
      size: 350,
      muiTableHeadCellProps: ({cell}) => ({
        sx: {
          borderStyle: 'solid',
          borderWidth: '0 1px 1px 0',
          borderColor: 'rgb(224 224 224)'
        }
      }),
      muiTableBodyCellProps: ({cell}) => ({
        sx: {
          borderStyle: 'solid',
          borderWidth: '0 1px 1px 0',
          borderColor: 'rgb(224 224 224)'
        }
      })
    },
  ];
  if (!isTerms) {
    columns.splice(1, 0, {
      accessorKey: 'captionScore',
      header: t('dialogs.matchings.captionScore'),
      Cell: ({ renderedCellValue, row }) => displayPercent(renderedCellValue),
      size: 80,
      muiTableHeadCellProps: ({cell}) => ({
        sx: {
          borderStyle: 'solid',
          borderWidth: '0 1px 1px 0',
          borderColor: 'rgb(224 224 224)'
        }
      }),
      muiTableBodyCellProps: ({cell}) => ({
        sx: {
          borderStyle: 'solid',
          borderWidth: '0 1px 1px 0',
          borderColor: 'rgb(224 224 224)'
        }
      })
    });
  }
  return columns;
};

export const getDuplicatesColumns = (t, isTerm) => {
  const columns = [
    {
      accessorKey: 'caption',
      header: t('dialogs.duplicates.name'),
      Cell: ({ renderedCellValue, row }) => {
        if (row.original.authorityIdValue !== '') {
          return (
            <Badge color='primary' variant='dot'>
              {renderedCellValue}
            </Badge>
          )
        }
        return (
          <>
            {renderedCellValue}
          </>
        )
      },
    },
    {
      accessorKey: 'authorityIdValue',
      header: t('dialogs.duplicates.authority'),
      Cell: renderAuthorityCell,
    },
    {
      accessorKey: 'usage',
      header: t('dialogs.duplicates.usage')
    }
  ]
  if (isTerm) {
    columns.push({
      accessorKey: 'hasChildren',
      header: t('dialogs.duplicates.hasChildren'),
      size: 40,
      Cell: ({ row }) => {
        if (row.original.hasChildren) {
          return (
            <Tooltip title={t('dialogs.duplicates.hasChildrenTooltip')}>
              <Warning color='warning' />
            </Tooltip>
          )
        }
        return null;
      },
    });
  }
  return columns;
}

export const getTermsColumns = (t, api, bmr, sir) => {
  return [
    {
      accessorKey: 'edit',
      header: 'edited (hidden)',
      size: 120,
      columnFilterModeOptions: [],
      visibleInShowHideMenu: false,
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.edit !== null;
        }

        if (!filterValue) {
          return row.original.edit === null;
        }

        return true;
      }
    },
    {
      accessorKey: 'caption',
      header: t('table.default.header.term'),
      Cell: ({ renderedCellValue, row }) => {
        let displayElement;

        if (row.original.authorityIdValue !== '') {
          displayElement =
            <Badge color='primary' variant='dot'>
              {renderedCellValue}
            </Badge>;
        }
        else {
          displayElement = <>
            {renderedCellValue}
          </>
        }

        if (row.original.description !== '') {
          return (
            <Tooltip title={row.original.description}>
              {displayElement}
            </Tooltip>
          )
        }

        return displayElement;
      },
      aggregationFn: 'max',
      getGroupingValue: (row) => row.caption.toLowerCase(),
      filterFn: 'contains',
      renderColumnFilterModeMenuItems: ({ column, onSelectFilterMode }) => [
        <MenuItem
          key='contains'
          onClick={() => onSelectFilterMode('contains')}
        >
          {t('table.default.filterContains')}
        </MenuItem>,
        <MenuItem
          key='containsCapsSensitive'
          onClick={() => onSelectFilterMode('captionContainsCaps')}
        >
          {t('table.default.filterContainsCaps')}
        </MenuItem>,
        <MenuItem
          key='fuzzy'
          onClick={() => onSelectFilterMode('fuzzy')}
        >
          {t('table.default.filterFuzzy')}
        </MenuItem>,
        <MenuItem
          key='startsWith'
          onClick={() => onSelectFilterMode('startsWith')}
        >
          {t('table.default.filterStartsWith')}
        </MenuItem>,
        <MenuItem
          key='endsWith'
          onClick={() => onSelectFilterMode('endsWith')}
        >
          {t('table.default.filterEndsWith')}
        </MenuItem>,
        <MenuItem
          key='equals'
          onClick={() => onSelectFilterMode('equals')}
        >
          {t('table.default.filterEquals')}
        </MenuItem>,
        <MenuItem
          key='notEquals'
          onClick={() => onSelectFilterMode('notEquals')}
        >
          {t('table.default.filterNotEquals')}
        </MenuItem>
      ]
    },
    {
      accessorKey: 'code',
      header: t('table.default.header.code'),
      size: 93,
      filterFn: 'contains',
      renderColumnFilterModeMenuItems: ({ column, onSelectFilterMode }) => [
        <MenuItem
          key='contains'
          onClick={() => onSelectFilterMode('contains')}
        >
          {t('table.default.filterContains')}
        </MenuItem>,
        <MenuItem
          key='containsCapsSensitive'
          onClick={() => onSelectFilterMode('captionContainsCaps')}
        >
          {t('table.default.filterContainsCaps')}
        </MenuItem>,
        <MenuItem
          key='fuzzy'
          onClick={() => onSelectFilterMode('fuzzy')}
        >
          {t('table.default.filterFuzzy')}
        </MenuItem>,
        <MenuItem
          key='startsWith'
          onClick={() => onSelectFilterMode('startsWith')}
        >
          {t('table.default.filterStartsWith')}
        </MenuItem>,
        <MenuItem
          key='endsWith'
          onClick={() => onSelectFilterMode('endsWith')}
        >
          {t('table.default.filterEndsWith')}
        </MenuItem>,
        <MenuItem
          key='equals'
          onClick={() => onSelectFilterMode('equals')}
        >
          {t('table.default.filterEquals')}
        </MenuItem>,
        <MenuItem
          key='notEquals'
          onClick={() => onSelectFilterMode('notEquals')}
        >
          {t('table.default.filterNotEquals')}
        </MenuItem>
      ]
    },
    {
      accessorKey: 'hierarchy',
      header: t('table.default.header.hierarchy'),
      filterVariant: 'multi-select',
      columnFilterModeOptions: [],
      filterFn: customHierarchyFilterFn
    },
    {
      accessorKey: 'authorityIdValue',
      header: t('table.default.header.authority'),
      size: 98,
      columnFilterModeOptions: [],
      Cell: renderAuthorityCell,
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.authorityIdValue !== '';
        }
        if (!filterValue) {
          return row.original.authorityIdValue === '' || row.original.authorityIdValue === null
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.hasAuthority'), value: true },
        { text: t('filters.noAuthority'), value: false },
      ],
    },
    {
      accessorKey: 'inValid',
      header: t('table.default.header.invalid'),
      size: 93,
      columnFilterModeOptions: [],
      Cell: ({ renderedCellValue, row }) => (
        <>
          { !row.original.isLoading &&
            <Checkbox
              checked={ renderedCellValue }
              disabled={ row.original.frequency > 0 && !row.original.inValid}
              onChange={(e, c) => {
                sir(row, c);
              }}
            />
          }
          { row.original.isLoading &&
            <CircularProgress />
          }
        </>
      ),
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.inValid;
        }
        if (!filterValue) {
          return !row.original.inValid;
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.isInvalid'), value: true },
        { text: t('filters.isValid'), value: false },
      ],
    },
    {
      accessorKey: 'frequency',
      header: t('table.default.header.frequency'),
      size: 102,
      columnFilterModeOptions: [],
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.hasFrequency'), value: true },
        { text: t('filters.noFrequency'), value: false },
      ],
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.frequency > 0;
        }
        if (!filterValue) {
          return row.original.frequency === 0;
        }

        return true;
      },
    },
    {
      accessorKey: 'errors',
      header: t('table.default.header.warning'),
      size: 102,
      columnFilterModeOptions: [],
      Cell: ({ renderedCellValue, row }) => {
        if (renderedCellValue) {
          return (
            <ErrorCellButton
              errors={renderedCellValue}
              messages={row.original.messages}
              row={row.original}
            />
          )
        }
      },
      filterVariant: 'multi-select',
      filterFn: (row, id, filterValue) => {
        if (filterValue.length === 2 || filterValue.length === 0) {
          return true;
        }

        return filterValue.length > 0 && filterValue.includes(true)
          ? row.original.errors > 0
          : row.original.errors < 1;
      },
      filterSelectOptions: [
        { text: t('table.default.header.hasErrors'), value: true },
        { text: t('table.default.header.noErrors'), value: false },
      ],
    },
    {
      accessorKey: 'duplicates',
      header: t('table.default.header.duplicates'),
      size: 111,
      columnFilterModeOptions: [],
      Cell: ({ renderedCellValue, row }) =>
        !!renderedCellValue && renderedCellValue > 1 ? (
          <DuplicateCellButton renderedCellValue={renderedCellValue} row={row} />
        ) : (
          <></>
        ),
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.duplicates > 1;
        }
        if (!filterValue) {
          return row.original.duplicates === 1;
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.hasDuplicates'), value: true },
        { text: t('filters.noDuplicates'), value: false },
      ],
    },
    {
      accessorKey: 'matchings',
      header: t('table.default.header.matchings'),
      size: 118,
      columnFilterModeOptions: [],
      Cell: ({ renderedCellValue, row }) =>
        renderedCellValue ? (
          <MatchCellButton renderedCellValue={renderedCellValue} row={row} />
        ) : (
          <></>
        ),
      filterVariant: 'multi-select',
      filterFn: (row, id, filterValue) => {
        if (
          (filterValue.length === 2 &&
            filterValue.includes(true) &&
            filterValue.includes(false)) ||
          filterValue.length === 0
        ) {
          return true;
        }


        if (filterValue.length === 1 && filterValue.includes(null)) {
          return row.original.matchings === null;
        }

        if (filterValue.length > 0 && filterValue.includes(true)) {
          if (row.original.matchings === null) {
            return filterValue.includes(null);
          }

          if (filterValue.includes(false)) {
            return true;
          }

          return row.original.matchings?.length >= 1;
        }

        if (row.original.matchings === null) {
          return false;
        }

        return row.original.matchings?.length < 1;
      },
      filterSelectOptions: [
        { text: t('table.default.header.noMatchPerformed'), value: null },
        { text: t('table.default.header.hasMatch'), value: true },
        { text: t('table.default.header.noMatch'), value: false },
      ],
    },
    {
      accessorKey: 'bookmarked',
      header: t('table.default.header.pin'),
      size: 75,
      columnFilterModeOptions: [],
      Cell: ({ renderedCellValue, row }) => {
        return (
          <ToggleButton
            onChange={(e, v) => {
              bmr(row);
            }}
            selected={renderedCellValue}
            value={renderedCellValue}
          >
            { renderedCellValue &&
              <BookmarkOutlined />
            }
            { !renderedCellValue &&
              <BookmarkBorderOutlined />
            }
          </ToggleButton>
        )
      },
      filterFn: (row, id, filterValue) => {
        if (filterValue) {
          return row.original.bookmarked;
        }
        if (!filterValue) {
          return !row.original.bookmarked;
        }

        return true;
      },
      filterVariant: 'select',
      filterSelectOptions: [
        { text: t('filters.all'), value: null },
        { text: t('filters.isBookmarked'), value: true },
        { text: t('filters.notBookmarked'), value: false },
      ],
    }
  ]
}

const displayPercent = (percent) => `${(percent * 100).toFixed(0)}%`;

const renderAuthorityCell = ({ renderedCellValue, row }) => {
  if (renderedCellValue !== '') {
    let uuid = row.original.authorityLinkId ? row.original.authorityLinkId : row.original.id.split('-').splice(1).join('-');
    let domain = authorityMap[row.original.authorityId] || 'kulturnav';
    return (
      <Tooltip title={!!row.original.authorityDatasetId
        && (
          <Typography fontSize='14px'>
            {`${row.original.authorityDatasetIdValue}`}
            <br />
            {`${row.original.authorityDatasetId}`}
          </Typography>
        )}
      >
        <a href={`https://${domain}.org/${uuid}`} target='_blank' rel='noreferrer'>
          {renderedCellValue}
        </a>
      </Tooltip>
    )
  }
  return null;
};

export const customHierarchyFilterFn = (row, id, filterValues) => {
  if (filterValues?.length) {
    return filterValues.some((f) => f === row.original.hierarchy);
  } else {
    return true;
  }
}
