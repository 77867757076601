import { codeToToken } from '@ekultur/authentication';
import { useAuthsDispatch, USER_LOGGED_IN } from './AuthsContext';
import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useMatch, useNavigate } from 'react-router-dom';

export const Callback = () => {
  const match = useMatch('/oauth2/callback');

  const dispatch = useAuthsDispatch();
  const dispatchCallback = useCallback(dispatch, [dispatch]);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (match) {
      const searchParams = new URLSearchParams(location.search);
      codeToToken({
        apiGateway: window._env_.REACT_APP_API_GATEWAY,
        code: searchParams.get('code'),
        state: searchParams.get('state'),
        onSuccess: data => {
          dispatchCallback({type: USER_LOGGED_IN})
          navigate(data.previousPath)
        },
        sameSite: window._env_.REACT_APP_ENVIRONMENT === 'beta'
      });
    }
  }, [location, navigate, dispatchCallback, match]);

  return null;
};