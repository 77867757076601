import React from 'react';
import preval from 'preval.macro';

import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline } from '@mui/material';

import { AppContent } from './AppContent';
import { AppProvider } from './AppContext';
import { EkulturHeader } from '../components/EkulturHeader/EkulturHeader';
import { MainContextProvider } from '../contexts/MainContext';
import { PrimusContextProvider } from "../contexts/PrimusContext";
import { RoutesAnonymous } from './routes/RoutesAnonymous';
import { RoutesAuthenticated } from './routes/RoutesAuthenticated';
import { TableContextProvider } from "../contexts/TableContext";
import { Theme } from './Theme';
import { TvattHelpMenu } from '../components/TvattHelpMenu/TvattHelpMenu';
import { TvattMenu } from '../components/TvattMenu/TvattMenu';
import { WhenHeaderIsReady } from '../components/EkulturHeader/WhenHeaderIsReady';
import { WithUserData } from './auths/WithUserData';

export const App = () => {
  const buildDate = preval`module.exports = new Date().toDateString();`;
  console.info(buildDate);

  if (!window._env_?.REACT_APP_API_GATEWAY) {
    return null;
  }

  return (
    <Theme>
      <Router>
        <AppProvider>
          <CssBaseline />
          <EkulturHeader />
          <WhenHeaderIsReady>
            <RoutesAnonymous />
            <WithUserData>
              <MainContextProvider>
                <TableContextProvider>
                  <PrimusContextProvider>
                    <TvattMenu />
                    <AppContent>
                      <RoutesAuthenticated />
                    </AppContent>
                    <TvattHelpMenu />
                  </PrimusContextProvider>
                </TableContextProvider>
              </MainContextProvider>
            </WithUserData>
          </WhenHeaderIsReady>
        </AppProvider>
      </Router>
    </Theme>
  );
}
