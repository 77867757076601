import { HttpService } from './HttpService';
import { PrimusApi as PrimusAuthService } from './PrimusApi';

export class EkulturAPI extends HttpService {
    constructor() {
        super();
        this.baseUrl = window._env_.REACT_APP_API_GATEWAY;
    }

    static getToken() {
        return window.localStorage.getItem('kit.access_token');
    }

    static getAuthHeader() {
        const token = EkulturAPI.getToken();
        return token ? `Bearer ${token}` : '';
    }

    getBaseUrl() {
        return this.baseUrl;
    }

    getDefaultConfig() {
        return this.combineConfigs(super.getDefaultConfig(), {
            headers: new Headers({
                Authorization: EkulturAPI.getAuthHeader(),
            }),
            credentials: 'include',
        });
    }

    async getAvailablePrimusInstances(userId) {
        const applications = await this.getApplicationsAvailableToUser(userId);
        let availableMuseumIds = (applications?.museums || [])
            .filter((museum) =>
                museum.applications.some(
                    (app) => app.id === window._env_.REACT_APP_EKULTUR_PRIMUS_APP_ID
                )
            )
            .map((museum) => museum.id);

        if (availableMuseumIds.length < 1) {
            throw new Error('You do not have access to any museums for this application')
        }

        const museumsResponse = await this.getMuseums(['id', 'name']);
        const museums = museumsResponse?.museums || [];
        const availableMuseums = museums.filter((museum) =>
            availableMuseumIds.includes(museum.id)
        );

        return Promise.all(
            availableMuseums.map((museum) =>
                this.getMuseumUrls(
                    window._env_.REACT_APP_EKULTUR_PRIMUS_APP_ID,
                    museum.id
                )
            )
        ).then((urlResponses) => {
            if (urlResponses.length && Array.isArray(urlResponses[0])) {
                const urls = urlResponses.reduce((acc, val) => [...acc, ...val], []);
                return availableMuseums.map((museum) => ({
                    id: museum.id,
                    name: museum.name,
                    serverUrl: urls.find((url) => url.museumId === museum.id)?.url,
                }));
            } else throw new Error('Could not get museum urls')
        });
    }

    async getMuseums(fields, identifier, publishToEuropeana) {
        const params = {};
        if (identifier) {
            if (typeof identifier === 'string') {
                params.identifier = identifier;
            } else if (Array.isArray(identifier)) {
                params['identifier[]'] = identifier.join(',');
            }
        }
        if (publishToEuropeana !== undefined) {
            params.publish_to_europeana = String(publishToEuropeana);
        }
        if (fields) {
            params['fields[]'] = fields.join(',');
        }
        return this.getFromEkultur('/museum-api/museums', params);
    }

    async getMuseumUrls(appId, museumId, url) {
        return this.getFromEkultur('/app-registry/urls/', {
            appId,
            museumId: String(museumId),
            url,
        });
    }

    async getApplicationsAvailableToUser(userId) {
        return this.getFromEkultur(`/authz/users/${userId}/applications/`);
    }

    async getFromEkultur(path, params) {
        try {
            let search = '';
            if (params) {
                search = `?${Object.keys(params)
                  .filter((name) => params[name] !== undefined)
                  .map((name) => `${name}=${params[name]}`)
                  .join('&')}`;
            }
            const res = await fetch(`${this.getBaseUrl()}${path}${search}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: PrimusAuthService.getAuthHeader(),
                },
            });
            if (res?.ok) {
                return res.json();
            }
            return Promise.reject(res ? `${res.status} ${res.statusText}` : undefined);
        } catch (e) {
            return Promise.reject(e?.message)
        }
    }
}
