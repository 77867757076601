export class SolrSearchParams {
    artifact_id = null;
    fields = null;
    facetFields = null;
    facetLimit = null;
    facetMincount = null;
    facetSort = null;
    identifier = null;
    invalidOnly = false;
    object_type = null;
    queryOp = 'OR';
    query = '*:*'
    searchBody = null;
    rows = null;
    validOnly = false;

    addToQuery(key, parameter) {
        this._addToSearchString(key, parameter);
    }

    getSearchBody() {
        if (this.validOnly) {
            this.query += ' AND valid:true'
        }

        if (this.invalidOnly) {
            this.query += ' AND valid:false'
        }

        if (this.searchBody !== null) {
            return this.searchBody;
        }

        let body = {};
        body['query'] = this.query;
        body['q.op'] = this.queryOp;

        if (this.fields !== null) {
            body['fl'] = this.fields;
        }

        if (this.facetFields !== null) {
            body['facets'] = this.facetFields;

            if (this.facetLimit !== null) {
                body['facet.limit'] = this.facetLimit;
            }

            if (this.facetMincount !== null) {
                body['facet.mincount'] = this.facetMincount;
            }

            if (this.facetSort !== null) {
                body['facet.sort'] = this.facetSort;
            }
        }

        if (this.rows !== null) {
            body['rows'] = this.rows;
        }

        return body;
    }

    setArtifactId(id) {
        this.artifact_id = id;
        this._addToSearchString('artifact_id', this.artifact_id);
    }

    setFacetFields(fields) {
        this.facetFields = fields;
    }

    setFacetLimit(limit) {
        if (Number.isInteger(limit)) {
            this.facetLimit = limit;
        }
        else {
            throw Error('Facet Limit must be a number');
        }
    }

    setFacetMincount(mincount) {
        if (Number.isInteger(mincount)) {
            this.facetMincount = mincount;
        }
        else {
            throw Error('Facet Mincount must be a number');
        }
    }

    setFacetSort(sort) {
        this.facetSort = sort;
    }

    setFields(fields) {
        this.fields = fields;
    }

    setIdentifier(identifier) {
        this.identifier = identifier;
        this._addToSearchString('identifier_upper', this.identifier.toUpperCase());
    }

    setInvalidOnly(invalid) {
        if (this.validOnly) this.validOnly = false;
        this.invalidOnly = invalid;
    }

    setObjectType(objectType) {
        this.object_type = objectType;
        this._addToSearchString('object_type', this.object_type);
    }

    setQueryOpAnd(a) {
        let oldOp = this.queryOp;

        if (a) {
            this.queryOp = 'AND';
        }
        else {
            this.queryOp = 'OR';
        }

        if (this.query !== '*:*') {
            this.query = this.query.replace(` ${oldOp} `, ` ${this.queryOp} `)
        }
    }

    setResultLimit(rows) {
        if (Number.isInteger(rows)) {
            this.rows = rows;
        }
        else {
            throw Error('Result limit must be a number');
        }
    }

    setValidOnly(valid) {
        if (this.invalidOnly) this.invalidOnly = false;
        this.validOnly = valid;
    }

    _addToSearchString(key, parameter) {
        if (!this.query || this.query === '*:*') {
            this.query = `${key}:${parameter}`
        }
        else {
            this.query = this.query.concat(` ${this.queryOp} ${key}:${parameter}`);
        }
    }
}