import React, { useEffect } from 'react';
import { Alert } from '@mui/material';

export const JobNotificationItem = ({
  duration,
  jobData,
  onJobCompletion,
  text,
  success
}) => {

  useEffect(() => {
    setTimeout(() => {
      onJobCompletion(jobData);
    }, duration);
  }, [duration, jobData, onJobCompletion]);

  return (
    <Alert severity={success ? 'success' : 'info'}>
      {text}
    </Alert>
  )
}