import React from 'react';
import { MaterialReactTable } from 'material-react-table';
import { Box, Button, DialogActions, DialogTitle } from '@mui/material';
import { DetailPanel } from '../DetailPanels/DetailPanel';
import { TermDetailPanel } from "../DetailPanels/TermDetailPanel";

export const Step0Dialog = ({
  disableButtons,
  duplicateColumns = null,
  duplicateData,
  nextStep,
  onCancel,
  onConfirm,
  step0SelectedRows,
  setStep0SelectedRows,
  t,
}) => {
  return (
    <Box
      sx={{
        width: '90vw'
      }}
    >
      <DialogTitle>
        {t('dialogs.duplicates.titleStep0')}
      </DialogTitle>
      <Box maxHeight='calc(100vh - 181px)' overflow='auto'>
        <MaterialReactTable
          columns={duplicateColumns}
          data={duplicateData}
          enableMultiRowSelection={true}
          enablePagination={false}
          enableRowSelection={true}
          enableTopToolbar={false}
          initialState={{
            columnVisibility: {
              duplicates: false,
              edit: false
            }
          }}
          getRowId={(row) => row.id}
          layoutMode={'grid'}
          onRowSelectionChange={setStep0SelectedRows}
          renderDetailPanel={({ row }) => row.original.hasOwnProperty('code')
            ? <TermDetailPanel rowData={row.original} toggleIsExpanded={row.toggleExpanded} />
            : <DetailPanel row={row} />
          }
          muiDetailPanelProps={{
            sx: {
              width: '100%',
              '.MuiCollapse-vertical': {
                width: '100%'
              }
            }
          }}
          enableBottomToolbar={false}
          state={{
            rowSelection: step0SelectedRows
          }}
        />
      </Box>
      <DialogActions>
        <Button
          disabled={disableButtons || Object.keys(step0SelectedRows).length < 1}
          onClick={(e) => {
            e.stopPropagation();
            onConfirm(step0SelectedRows, null, true);
          }}
        >
          {t('dialogs.duplicates.noDuplicates')}
        </Button>
        <Button
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
        >
          {t('dialogs.duplicates.cancel')}
        </Button>
        <Button
          disabled={Object.keys(step0SelectedRows).length === 1}
          onClick={(e) => {
            e.stopPropagation();
            nextStep();
          }}
        >
          {t('dialogs.duplicates.goToMerge')}
        </Button>
      </DialogActions>
    </Box>
  )
}