import React from 'react';
import { Alert, Box } from '@mui/material';
import { useTranslation } from "react-i18next";

export const JobErrorItem = ({ jobData, onJobCompletion, text, error }) => {
  const { t } = useTranslation('translations');
  return (
    <Alert
      onClose={() => onJobCompletion(jobData)}
      severity={'error'}
    >
      <Box display='flex' flexDirection='column'>
        <span>{text}</span>
       {!!error &&  <span>{`${t('table.default.jobs.errorMessage')}: ${error.message || error}`}</span>}
      </Box>
    </Alert>
  )
}