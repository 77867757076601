import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import no from './../translations/no/translations.json';
import en from './../translations/en/translations.json';
import sv from './../translations/sv/translations.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: en },
      no: { translations: no },
      sv: { translations: sv }
    },
    fallbackLng: 'no',
    debug: process.env.NODE_ENV === 'development' && false,
    saveMissing: true,

    interpolation: {
      escapeValue: false
    }
  })
  .then(() => console.info('[AppI18n] Translations initialized'));

export default i18n;