import React, { createContext, useContext, useReducer } from 'react';
import { HeaderProvider } from '@ekultur/header-microfrontend';
import { useTranslation } from 'react-i18next';

import { AuthsProvider } from './auths/AuthsContext';
import { TvattLoader } from '../components/TvattLoader/TvattLoader';
import { WithAuthentication } from './auths/WithAuthentication';

export const APPS_RECEIVED = 'appsReceived';
export const MENU_CLOSED = 'menuClosed';
export const MENU_OPENED = 'menuOpened';
export const MUSEUMS_RECEIVED = 'museumsReceived';
export const RENDERED = 'rendered';

const AppStateContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);
const AppTranslationContext = createContext(undefined);

const initialState = {
  apps: [],
  museums: [],
  menuOpen: false,
  rendered: false
};

const appReducer = (state, action) => {
  switch (action.type) {
    case APPS_RECEIVED:
      return {
        ...state,
        apps: action.apps
      }
    case MENU_CLOSED:
      return {
        ...state,
      menuOpen: false
      }
    case MENU_OPENED:
      return {
        ...state,
        menuOpen: true
      }
    case MUSEUMS_RECEIVED:
      return {
        ...state,
        museums: action.museums
      }
    case RENDERED:
      return {
        ...state,
        rendered: true
      }
    default:
      throw new Error(`[AppContext] Unhandled action type ${action.type}`);
  }
}

export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, {...initialState}, undefined);
  const {t, ready} = useTranslation('virtueltmuseumAdmin', {useSuspense: false});

  if (!ready) {
    return <TvattLoader />
  }

  return (
    <AppStateContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        <AppTranslationContext.Provider value={t}>
          <HeaderProvider>
            <AuthsProvider>
              <WithAuthentication>
                {children}
              </WithAuthentication>
            </AuthsProvider>
          </HeaderProvider>
        </AppTranslationContext.Provider>
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  )
}

export const useAppDispatch = () => {
  const context = useContext(AppDispatchContext);
  if (undefined === context) {
    throw new Error(`[AppContext] useAppDispatch must be used within an AppProvider`);
  } else {
    return context;
  }
}

export const useAppState = () => {
  const context = useContext(AppStateContext);
  if (undefined === context) {
    throw new Error(`[AppContext] useAppState must be used within an AppProvider`);
  } else {
    return context;
  }
}

export const useAppTranslation = () => {
  const context = useContext(AppTranslationContext);
  if (undefined === context) {
    throw new Error("[AppContext] useAppTranslation must be used within an AppProvider")
  } else {
    return context;
  }
}
