import { useMatch } from 'react-router-dom';

import { useAuthsState } from './AuthsContext';

export const useAuthenticatedRouteMatch = path => {
  const { userIsAuthenticated } = useAuthsState();
  const match = useMatch(path);

  if (userIsAuthenticated) {
    return match;
  }
  else {
    return false;
  }
}