import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@mui/material';

import { JobErrorItem } from './JobErrorItem';
import { JobMatchItem } from './JobMatchItem';
import { JobNotificationItem } from './JobNotificationItem';
import { useMainDispatch, useMainState, SET_NOTIFICATIONS, SET_ROWS_TO_MATCH } from '../../contexts/MainContext';

export const JobHeader = () => {
  const [jobs, setJobs] = useState([]);

  const dispatch = useMainDispatch();
  const dispatchCallback = useCallback(dispatch, [dispatch]);

  const { notifications, rowsToMatch, noFuzzy } = useMainState();

  const onCompletedJob = (job) => {
    setJobs((current) => {
      return current.filter(j => j !== job);
    });
  }

  useEffect(() => {
    if (Object.keys(rowsToMatch).length > 0) {
      setJobs((current) => [...current, {
        id: new Date().toISOString(),
        rowsToMatch: { ...rowsToMatch },
        noFuzzy: noFuzzy,
        type: 'matching'
      }]);

      dispatchCallback({
        type: SET_ROWS_TO_MATCH,
        rowsToMatch: [],
        noFuzzy: false
      });
    }
  }, [dispatchCallback, rowsToMatch])

  useEffect(() => {
    if (notifications.length > 0) {
      notifications.forEach((notification) => {
        const index = jobs.findLastIndex((j) => !!j.replace)
        if (notification.replace && index > -1) {
          setJobs([
            ...jobs.slice(0, index),
            {
              id: new Date().toISOString(),
              text: notification.text,
              duration: notification.duration,
              type: notification.type,
              error: notification.error,
              replace: notification.replace
            },
            ...jobs.slice((index + 1))
          ])
        } else {
          setJobs((current) => [...current, {
            id: new Date().toISOString(),
            text: notification.text,
            duration: notification.duration,
            type: notification.type,
            error: notification.error,
            replace: notification.replace
          }]);
        }
      });

      dispatchCallback({
        type: SET_NOTIFICATIONS,
        notifications: []
      });
    }
  }, [dispatchCallback, notifications])

  return (
    <Box id="JobsBox"
      sx={{
        flexGrow: '0',
        flexShrink: '0',
        flexBasis: 'auto',
      }}
    >
      {
        jobs.map((job) => {
          switch (job.type) {
            case 'matching':
              return (
                <JobMatchItem
                  key={job.id}
                  jobData={job}
                  rowsToMatch={job.rowsToMatch}
                  noFuzzy={job.noFuzzy}
                  onJobCompletion={onCompletedJob}
                />
              )
            case 'information':
              return (
                <JobNotificationItem
                  key={job.id}
                  jobData={job}
                  onJobCompletion={onCompletedJob}
                  duration={job.duration}
                  text={job.text}
                />
              )
            case 'success':
              return (
                <JobNotificationItem
                  key={job.id}
                  jobData={job}
                  onJobCompletion={onCompletedJob}
                  duration={job.duration}
                  text={job.text}
                  success
                />
              )
            case 'error':
              return (
                <JobErrorItem
                  key={job.id}
                  jobData={job}
                  onJobCompletion={onCompletedJob}
                  text={job.text}
                  error={job.error}
                />
              )
            default:
              return null;
        }})
      }
    </Box>
  )
}