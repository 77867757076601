import { Close } from '@mui/icons-material';
import { Box, Drawer, IconButton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import remarkGfm from 'remark-gfm';

import { SET_HELP_MENU_OPEN, useMainDispatch, useMainState } from '../../contexts/MainContext';

export const TvattHelpMenu = () => {
  const [data, setData] = useState('');

  const dispatch = useMainDispatch();
  const dispatchCallback = useCallback(dispatch, [dispatch]);

  const { helpMenuContentUrl, helpMenuContentTitle, helpMenuOpen } = useMainState();

  useEffect(() => {
    if (helpMenuContentUrl !== '') {
      fetch(helpMenuContentUrl).then((r) => {
        return r.text();
      }).then((r) => {
        setData(r);
      })
    }
  }, [helpMenuContentUrl]);

  return (
    <Drawer
      anchor={'right'}
      open={helpMenuOpen}
    >
      <Box
        sx={{
          overflow: 'hidden'
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: (theme) => theme.palette.primary.dark,
            color: 'white',
            display: 'flex',
            height: '50px',
            justifyContent: 'flex-start',
            padding: '0 10px',
            width: '100%'
          }}
        >
          <Typography
            sx={{
              flexGrow: 1,
              textAlign: 'center'
            }}
            variant='h4'
          >
            {helpMenuContentTitle}
          </Typography>
          <IconButton
            onClick={() => {
              dispatchCallback({
                type: SET_HELP_MENU_OPEN,
                helpMenuOpen: false
              })
            }}
            sx={{
              color: 'white',
            }}
          >
            <Close />
          </IconButton>
        </Box>
        <Box
          sx={{
            height: 'calc(100% - 50px)',
            overflow: 'auto',
            padding: '0 10px',
            width: '50vw'
          }}
        >
          <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
            {data}
          </ReactMarkdown>
        </Box>
      </Box>
    </Drawer>
  )
}