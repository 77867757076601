import { CheckCircle, Error } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Badge,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SET_INDEXED_DATA, SET_TABLE_DATA, useTableDispatch, useTableState } from "../../contexts/TableContext";

import { getErrorTypeDetails } from '../../declarations/ErrorTypes';
import { dumpAllIndexedData, toggleMarkedOkRow } from '../../services/IndexedDbService';
import { reAnalyzeWasherRow } from '../../utils/primusDataHandling';

import '@fontsource/roboto';

export const ErrorCellButton = ({
  errors,
  messages,
  row
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const tableDispatch = useTableDispatch();
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const { data } = useTableState();
  const { t } = useTranslation('translations');

  let markedOk = row.errors === -1;

  const renderErrorMessages = () => {
    return messages.map((message, index) => (
      <Typography key={index}>{t(getErrorTypeDetails(message).title)}</Typography>
    ));
  }

  const toggleRowOk = (ok) => {
    const tempTableData = data;
    const index = tempTableData.findIndex((r) => r.id === row.id);

    if (index !== -1) {
      if (ok) {
        tempTableData[index].errors = -1;
        tempTableData[index].messages = [];
      }
      else {
        let analysis = reAnalyzeWasherRow(t, row);
        tempTableData[index].errors = analysis.errors;
        tempTableData[index].messages = analysis.messages;
      }
    }

    tableDispatchCallback({
      type: SET_TABLE_DATA,
      data: [...tempTableData]
    });

    toggleMarkedOkRow(row.id, ok).then(() => {
      dumpAllIndexedData().then((data) => {
        tableDispatchCallback({
          type: SET_INDEXED_DATA,
          indexedData: data
        });
      });
    });
    if (ok) setOpenDialog(false);
  }

  return (
    <>
      <Dialog open={openDialog} maxWidth='md' fullWidth={!markedOk}>
        <DialogTitle>
          {`${t('dialogs.error.warningsAt')} ${row.caption}`}
        </DialogTitle>
        <Box display='flex' flexDirection='column' gap={2} px={2}>
          {messages.map((message, index) => {
            let details = getErrorTypeDetails(message);
            return (
              <Alert key={index} severity='warning' variant="outlined">
                <AlertTitle>{t(details.title)}</AlertTitle>
                {t(details.description)}
              </Alert>
            )
          })}
        </Box>
        <DialogActions sx={{ padding: 2, gap: 1 }}>
          <Button
            color={markedOk ? 'error' : 'success'}
            onClick={() => toggleRowOk(!markedOk)}
            variant='contained'
          >
            {markedOk ? t('dialogs.error.removeOk') : t('dialogs.error.markOk')}
          </Button>
          <Button onClick={() => setOpenDialog(false)} variant='outlined'>
            {t('dialogs.error.close')}
          </Button>
        </DialogActions>
      </Dialog>
      {errors > 0 && (
        <Tooltip title={renderErrorMessages()} aria-label='add'>
          <IconButton onClick={() => setOpenDialog(true)}>
            <Badge badgeContent={errors} color='primary'>
              <Error color='warning' />
            </Badge>
          </IconButton>
        </Tooltip>
      )}
      {errors === -1 && (
        <Tooltip title={<Typography>{t('table.default.markedOk')}</Typography>} aria-label='add'>
          <IconButton onClick={() => setOpenDialog(true)}>
            <CheckCircle color='success' />
          </IconButton>
        </Tooltip>
      )}
    </>
  )
}