import { Close } from "@mui/icons-material";
import { Avatar, Box, Drawer, IconButton, List, ListItem, ListItemAvatar, Typography } from '@mui/material';
import React from 'react';
import { getClickableItem, getIcon } from "./DetailPanelUtils";

export const SidePanelUsageList = ({ usages, handleClickField, open, setOpen, t }) => {
  return (
    <Drawer open={open} anchor='right' onClose={() => setOpen(false)}>
      <Box overflow='hidden'>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: (theme) => theme.palette.primary.dark,
            color: 'white',
            display: 'flex',
            height: '50px',
            justifyContent: 'flex-start',
            padding: '0 10px',
            width: '100%'
          }}
        >
          <Typography
            sx={{
              flexGrow: 1,
              textAlign: 'center'
            }}
            variant='h5'
          >
            {t('table.default.detailpane.usageTitle')}
          </Typography>
          <IconButton onClick={() => setOpen(false)} sx={{ color: 'white' }}>
            <Close />
          </IconButton>
        </Box>
        <Box height='calc(100% - 50px)' overflow='auto'>
          <List dense>
            {usages.map((usage) => {
              const openIn = !usage.object_type?.startsWith('ct_');
              return (
                <ListItem key={usage.artifact_id} sx={{ paddingTop: 0 }}>
                  <ListItemAvatar sx={{ minWidth: 34, paddingRight: '8px' }}>
                    <Avatar sx={{ width: 34, height: 34, bgcolor: '#1976d2' }}>
                      {getIcon(usage.object_type)}
                    </Avatar>
                  </ListItemAvatar>
                  {getClickableItem(usage.artifact_id, null, usage.artifact_name, handleClickField, openIn,
                    t(`table.default.detailpane.tooltips.${openIn ? 'open' : 'copy'}`))}
                </ListItem>
              )
            })}
          </List>
        </Box>
      </Box>
    </Drawer>
  )
}