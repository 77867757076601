import React from 'react'
import { FiberSmartRecord } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useTableState } from "../../contexts/TableContext";

export const MergeListItem = ({onClick}) => {
  const { selectedData } = useTableState();
  const { t } = useTranslation('translations');

  return (
    <ListItemButton
      disabled={Object.keys(selectedData).length < 2}
      onClick={onClick}
    >
      <ListItemIcon>
        <FiberSmartRecord />
      </ListItemIcon>
      <ListItemText primary={t('menu.actionHeader.merge')} />
    </ListItemButton>
  )
}