import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Drawer, Hidden, Toolbar } from '@mui/material';

import { DatabaseSelector } from './DatabaseSelector';
import { TvattMenuOptions } from './TvattMenuOptions';
import { useAppDispatch, useAppState, MENU_CLOSED } from '../../app/AppContext';
import { useAuthsState } from '../../app/auths/AuthsContext';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  drawer: {
    [theme.breakpoints.up('md')]: {
      flexShrink: 0,
      width: drawerWidth,
      zIndex: theme.zIndex.appBar - 1
    }
  },
  drawerPaper: {
    width: drawerWidth
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    marginTop: theme.spacing(2),
    overflow: 'auto'
  },
  drawerTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

export const TvattMenu = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { menuOpen } = useAppState();
  const { userIsAuthenticated } = useAuthsState();

  if (userIsAuthenticated) {
    return (
      <nav className={classes.drawer}>
        <Hidden mdUp implementation='css'>
          <Drawer
            onClose={() => dispatch({ type: MENU_CLOSED })}
            open={menuOpen}
            classes={{
              paper: classes.drawerPaper
            }}
            PaperProps={{
              elevation: 4
            }}
          >
            <div className={classes.drawerContainer}>
              <DatabaseSelector />
              <TvattMenuOptions />
            </div>
          </Drawer>
        </Hidden>
        <Hidden mdDown implementation='css'>
          <Drawer
            classes={{
              paper: classes.drawerPaper
            }}
            open
            variant='permanent'
          >
            <div className={classes.drawerContainer}>
              <Toolbar />
              <DatabaseSelector />
              <TvattMenuOptions />
            </div>
          </Drawer>
        </Hidden>
      </nav>
    );
  }
  else {
    return null;
  }
}