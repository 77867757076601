export const DEFAULT_SOLR_FIELDS =
  'artifact_name,' +
  'id,' +
  'description.description,' +
  'object_type,' +
  'valid,' +
  'history_events.from_date,' +
  'history_events.from_date_precision,' +
  'history_events.history_event_id,' +
  'history_events.history_event_id_value,' +
  'history_events.type_id,' +
  'authority_id,' +
  'agent_type_id,' +
  'name.name,' +
  'first_name.first_name,' +
  'middle_name.middle_name,' +
  'last_name.last_name,' +
  'gender.gender_id_value,' +
  'nationalities.natonality_id_value,' +
  'industries.industry,' +
  'industries.remarks,' +
  'agent_type_id_value,' +
  'authority_id_value,' +
  'authority_link_id,' +
  'authority_dataset_id,' +
  'authority_dataset_id_value,' +
  'has_authority,' +
  'is_leaf,' +
  'collection.collection_id,' +
  'collection.collection_id_value,' +
  '[child childFilter=object_type:SuperobjectHistoryEventItem]';
