import { ERROR_TYPES } from '../declarations/ErrorTypes';
import { HISTORY_EVENT_TYPE_BIRTH_ID, HISTORY_EVENT_TYPE_DEATH_ID } from "../declarations/UUIDs";

function analyzeBeginDate(beginDate) {
  return beginDate === '' || beginDate === null;
}

function analyzeName(name, isOrganization) {
  if (isOrganization) return name.includes('?') || name.includes(';') || name.includes('"');

  return !name.includes(',') || name.includes(';') || name.includes('"') ||  (name === String(name).toUpperCase());
}

function analyzeStatus(invalid, frequency) {
  return invalid && frequency > 0;
}

const analyzeTime = (time) => {
  if (time.length === 2) {
    let date1 = new Date(time[0]);
    let date2 = new Date(time[1]);

    return date1 > date2;
  }
}
  

function analyzeType(type) {
  return type === null || type === '';
}

export function getCaption(artifact, startDate, endDate) {
  if (!startDate && !endDate) return artifact.name.name;
  let startYear = startDate ? startDate.split('-')[0] : '';
  let endYear = endDate ? endDate.split('-')[0] : '';
  if (startYear && endYear) return `${artifact.name.name} (${startYear} - ${endYear})`;
  return `${artifact.name.name} (${startYear || endYear})`;
}

export function formatDateWithPrecision(date, precision) {
  let [d, _t] = date.split('T');

  if (d) {
    switch (precision) {
      case 'date':
        return d;
      case 'month':
        return d.split('-').splice(0, 2).join('-');
      default:
        return d.split('-')[0];
    }
  }
  return date;
}

export function primus10ToWasher(
  t,
  dataToAnalyze,
  bookmarkedRows,
  markedNoDuplicatesRows,
  markedOkRows,
  matchedRows,
  editedRows,
  usageData = [],
  facetData=null,
  removeMarkedNoDuplicate=false
) {
  let counts = {};

  let uData = {};
  usageData.forEach((u) => {
    uData[u.artifact_id] = u.usage;
  });

  console.time('analyze');
  let tableData = dataToAnalyze.map((row) => {
    let beginDate = '';
    let endDate = '';
    let beginDateId = '';
    let endDateId = '';
    let beginDatePrecision = 'year';
    let endDatePrecision = 'year';
    let messages = new Set();


    let events = row['_childDocuments_'] || [];
    if (events.length) {
      let beginDateIndex = events.findLastIndex((e) => e['history_events.type_id']?.[0] === HISTORY_EVENT_TYPE_BIRTH_ID);
      let endDateIndex = events.findLastIndex((e) => e['history_events.type_id']?.[0] === HISTORY_EVENT_TYPE_DEATH_ID);

      if (beginDateIndex >= 0) {
        const beginDateEvent = events[beginDateIndex]
        beginDate = beginDateEvent['history_events.from_date']?.[0] ?? '';
        beginDateId = beginDateEvent['history_events.history_event_id']?.[0] ?? '';
        beginDatePrecision =  beginDateEvent['history_events.from_date_precision']?.[0] || beginDatePrecision;
        beginDate = formatDateWithPrecision(beginDate, beginDatePrecision);
      }
      if (endDateIndex >= 0) {
        const endDateEvent = events[endDateIndex]
        endDate = endDateEvent['history_events.from_date']?.[0] ?? '';
        endDateId = endDateEvent['history_events.history_event_id']?.[0] ?? '';
        endDatePrecision =  endDateEvent['history_events.from_date_precision']?.[0] || endDatePrecision;
        endDate = formatDateWithPrecision(endDate, endDatePrecision);
      }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Counting artifacts for duplicate count
    if (!markedNoDuplicatesRows.includes(row.id)){
      const count = counts[row.artifact_name.toUpperCase()] || [];
      count.push({
        id: row.id,
        authority: row.authority_id_value
      });
      counts[row.artifact_name.toUpperCase()] = count;
    }
    ////////////////////////////////////////////////////////////////////////////////////////////////// Analyzing row with preset error checks. If not marked ok
    // Settings -1 to show marked as ok unless changed
    row.messages = [];
    row.errors = -1;
    
    if (!markedOkRows.includes(row.id)) {
      // Setting 0 as its not marked as ok
      row.errors = 0;

      if (analyzeTime([beginDate, endDate])) {
        messages.add(ERROR_TYPES.TIME_ERROR);
      }

      switch (row.agent_type_id_value) {
        case 'Dyr og ting med egennavn':
        case 'Firma':
        case 'Gruppe':
        case 'Instutisjon':
        case 'Organisasjon':
        case 'Annet':
        case 'Bygning':
        case 'Bygningsanlegg':
        case 'Fartøy':
        case 'Landemerke/byggverk':
        case 'Verk': {
          if (analyzeName(row.artifact_name, true)) {
            messages.add(ERROR_TYPES.NAME_WARNING);
          }
          break;
        }

        case 'Person': {
          if (analyzeName(row.artifact_name, false)) {
            messages.add(ERROR_TYPES.NAME_WARNING);
          }
          if (analyzeBeginDate(beginDate)) {
            messages.add(ERROR_TYPES.BEGIN_WARNING);
          }
          break;
        }

        case 'Ukjent': {
          // No analysis
          break;
        }

        default: {
          if(analyzeType(row.agent_type_id_value)) {
            messages.add(ERROR_TYPES.TYPE_ERROR);
          }
          break;
        }
      }
      row.messages = [...messages];
      row.errors = [...messages]?.length;
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////// Merging data into a raw solr data variable
    let solr = row['name.name'] ? row['name.name'] : '';
    solr += row['gender.gender_id_value'] ? ', ' + row['gender.gender_id_value'] : '';
    solr += row['nationalities.nationality_id_value'] ? ', ' + row['nationalities.nationality_id_value']  : '';
    solr += row['industries.industry'] ? ', ' + row['industries.industry'] : '';
    solr += row['industries.remarks'] ? ', ' + row['industries.remarks'] : '';

    if (row['history_events.history_event_id_value']) {
      row['history_events.history_event_id_value'].forEach((r) => {
        solr += ', ' + r;
      })
    }
    if (beginDate !== '') {
      solr += ', ' + beginDate;
    }
    if (endDate !== '') {
      solr += ', ' + endDate;
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// Finding usagedata, if it exists
    let usage = uData[row.id] || 0;

    if (analyzeStatus(!row.valid, usage)) {
      row.messages.push(ERROR_TYPES.STATE_ERROR)
      row.errors = row.errors + 1;
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    let authorityIdValue = '';
    if (row.authority_id_value) {
      authorityIdValue = row.authority_link_id ? `➔ ${row.authority_id_value}` : row.authority_id_value;
    }

    let matchings = null;
    if (!row.authority_link_id) {
      matchings = matchedRows?.find((r) => r.rowId === row.id)?.matchings || null;
    }

    let name = row['name.name'] || '';
    let firstName = row['first_name.first_name'] || '';
    let lastName = row['last_name.last_name'] || '';
    if (!firstName && !lastName && name) {
      let names = name.split(',');
      if (names?.length) {
        if (names.length > 1) {
          firstName = names[1].trim();
          lastName = names[0].trim();
        } else {
          lastName = names[0].trim();
        }
      }
    }

    if (facetData !== null) {
      if (facetData.findIndex((r) => r.name === row.artifact_name) !== -1) {
        return {
          key: row.id,
          id: row.id,
          caption: row.artifact_name,
          name: name,
          firstName: firstName,
          middleName: row['middle_name.middle_name'] || '',
          lastName: lastName,
          objectType: row.object_type,
          beginDate: beginDate,
          endDate: endDate,
          beginDateId: beginDateId,
          endDateId: endDateId,
          beginDatePrecision: beginDatePrecision,
          endDatePrecision: endDatePrecision,
          inValid: !row.valid,
          duplicates: row.duplicates,
          errors: row.errors,
          frequency: usage,
          messages: row.messages,
          matchings: matchings,
          description: row['description.description'] || '',
          collectionId: row['collection.collection_id'] || '',
          collectionIdValue: row['collection.collection_id_value'] || '',
          agentTypeId: row.agent_type_id || '',
          agentTypeIdValue: row.agent_type_id_value || '',
          authorityId: row.authority_id || '',
          authorityIdValue: authorityIdValue,
          authorityLinkId: row.authority_link_id || '',
          hasAuthority: row.has_authority,
          bookmarked: bookmarkedRows.includes(row.id),
          superobjectTypeId: row.superobject_type_id,
          solrData: solr,
          edit: editedRows?.find((r) => r.rowId === row.id)?.editDate || null,
          authorityDatasetId: row.authority_dataset_id || '',
          authorityDatasetIdValue: row.authority_dataset_id_value || ''
        }
      }
    } else {
      return {
        key: row.id,
        id: row.id,
        caption: row.artifact_name,
        name: name,
        firstName: firstName,
        middleName: row['middle_name.middle_name'] || '',
        lastName: lastName,
        objectType: row.object_type,
        beginDate: beginDate,
        endDate: endDate,
        beginDateId: beginDateId,
        endDateId: endDateId,
        beginDatePrecision: beginDatePrecision,
        endDatePrecision: endDatePrecision,
        inValid: !row.valid,
        duplicates: row.duplicates,
        errors: row.errors,
        frequency: usage,
        messages: row.messages,
        matchings: matchings,
        description: row['description.description'] || '',
        collectionId: row['collection.collection_id'] || '',
        collectionIdValue: row['collection.collection_id_value'] || '',
        agentTypeId: row.agent_type_id || '',
        agentTypeIdValue: row.agent_type_id_value || '',
        authorityId: row.authority_id || '',
        authorityIdValue: authorityIdValue,
        authorityLinkId: row.authority_link_id || '',
        hasAuthority: row.has_authority,
        bookmarked: bookmarkedRows.includes(row.id),
        superobjectTypeId: row.supertobject_type_id,
        solrData: solr,
        edit: editedRows?.find((r) => r.rowId === row.id)?.editDate || null,
        authorityDatasetId: row.authority_dataset_id || '',
        authorityDatasetIdValue: row.authority_dataset_id_value || ''
      }
    }
  });

  tableData = tableData.filter((f) => f !== undefined);

  tableData = tableData.map((row) => {
    if (!row.inValid && !markedNoDuplicatesRows.includes(row.id)) {
      let count = counts[row.caption.toUpperCase()] || [];
      row.duplicates = count.length;
      row.duplicateData = count;
    }
    else {
      row.duplicates = 1;
      row.duplicateData = [];
    }

    if (removeMarkedNoDuplicate) {
      if (!markedNoDuplicatesRows.includes(row.id)) {
        return row;
      }
    }

    return row;
  })

  console.timeEnd('analyze');
  return tableData;
}

export function reAnalyzeWasherRow (
  t,
  dataToAnalyze
) {
  let messages = new Set();

  if (analyzeStatus(dataToAnalyze.inValid, dataToAnalyze.frequency)) {
    messages.add(ERROR_TYPES.STATE_ERROR)
  }

  switch (dataToAnalyze.agentTypeIdValue) {
    case 'Dyr og ting med egennavn':
    case 'Firma':
    case 'Gruppe':
    case 'Instutisjon':
    case 'Organisasjon':
    case 'Annet':
    case 'Bygning':
    case 'Bygningsanlegg':
    case 'Fartøy':
    case 'Landemerke/byggverk':
    case 'Verk': {
      if (analyzeName(dataToAnalyze.caption, true)) {
        messages.add(ERROR_TYPES.NAME_WARNING);
      }
      break;
    }

    case 'Person': {
      if (analyzeName(dataToAnalyze.caption, false)) {
        messages.add(ERROR_TYPES.NAME_WARNING);
      }
      if (analyzeBeginDate(dataToAnalyze.beginDate)) {
        messages.add(ERROR_TYPES.BEGIN_WARNING);
      }
      break;
    }

    case 'Ukjent': {
      // No analysis
      break;
    }

    default: {
      if(analyzeType(dataToAnalyze.agentTypeIdValue)) {
        messages.add(ERROR_TYPES.TYPE_ERROR);
      }
      break;
    }
  }

  return {
    messages: [...messages],
    errors: [...messages].length
  }
}

export function getDuplicatesData(table, index, markedNoDuplicatesRows) {
  const data = {
    duplicates: 1,
    duplicateData: []
  };

  if (table[index].inValid || markedNoDuplicatesRows.includes(table[index].id)) return data;

  table.filter((r) => !r.inValid && r.caption.toUpperCase() === table[index].caption.toUpperCase()).forEach((d) => {
    if (d.id !== table[index].id) {
      data.duplicates++;
      data.duplicateData.push({
        id: d.id,
        authority: d.authorityIdValue || ''
      })
    }
  });
  return data;
}
