import React, { useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { kitFetch } from '@ekultur/fetch';

import { useAuthsDispatch, useAuthsState, SET_USER_DATA } from './AuthsContext';

export const WithUserData = ({children}) => {
  const authsDispatch = useAuthsDispatch();
  const authsDispatchCallback = useCallback(action => authsDispatch(action), [authsDispatch]);
  const { userData, userIsAuthenticated } = useAuthsState();

  useEffect(() => {
    if (userIsAuthenticated) {
      kitFetch('/authz/authorize/userData').then((json) => {
        authsDispatchCallback({
          type: SET_USER_DATA,
          userData: json
        });
      }).catch((error) => {
        console.error('[WithUserData] ', error);
      });
    }
  }, [authsDispatchCallback, userIsAuthenticated])

  if (Object.keys(userData).length) {
    return (
      <Box sx={{display:'flex'}}>
        {children}
      </Box>
    )
  }
  else {
    return null;
  }
}