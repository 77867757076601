import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Box, Button, ButtonGroup, ClickAwayListener, Grow, List, Paper, Popper, Typography } from '@mui/material';
import { isUUID } from "validator";
import { MoreHoriz } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { BookmarkListItem } from './BookmarkListItem';
import { getDuplicatesColumns } from '../../declarations/TvattTableColumnDeclarations';
import { MarkOkListItem } from './MarkOkListItem';
import { MatchListItem } from './MatchListItem';
import { MergeListItem } from './MergeListItem';
import { StatusListItem } from './StatusListItem';
import { ChangeTypeDialog } from './ChangeTypeDialog';
import { StepperDialog } from '../StepperDialog/StepperDialog';
import { TypeListItem } from './TypeListItem';
import { SET_ROWS_TO_MATCH, useMainDispatch } from '../../contexts/MainContext';
import { useTableState } from "../../contexts/TableContext";
import { usePrimusState } from "../../contexts/PrimusContext";

export const ActionButton = () => {
  const [dialogKey, setDialogKey] = useState(new Date().toString());
  const [openActionMenu, setOpenActionMenu] = useState(false);
  const [openMergeDialog, setOpenMergeDialog] = useState(false);
  const [openTypeDialog, setOpenTypeDialog] = useState(false);

  const anchorRef = useRef(null);
  const mainDispatch = useMainDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);

  const { selectedList } = usePrimusState();
  const { selectedData } = useTableState();
  const { t } = useTranslation('translations');

  const mergeColumns = useMemo(() => getDuplicatesColumns(t, selectedList.includes('ct_')), [t])

  const onMergeCancelled = () => {
    setOpenMergeDialog(false);
  }

  const onMergeConfirmed = () => {
    setOpenMergeDialog(false);
  }

  const handleOpenMergeDialog = () => {
    setDialogKey(new Date().toString());
    setOpenActionMenu(false);
    setOpenMergeDialog(true);
  }

  return (
    <Box>
      <ButtonGroup variant='contained' ref={anchorRef} color='primary'>
        <Button
          disabled={Object.keys(selectedData).length < 1}
          onClick={() => setOpenActionMenu(!openActionMenu)}
        >
          <Typography
            sx={{
              mr: 1
            }}
          >
            {t('menu.actionHeader.options')}
          </Typography>
          <MoreHoriz />
        </Button>
      </ButtonGroup>

      <Popper
        anchorEl={anchorRef.current}
        open={openActionMenu}
        placement={'bottom-start'}
        role={undefined}
        style={{
          zIndex: 1250
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Grow { ...TransitionProps }>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpenActionMenu(false)}>
                <List>
                  <BookmarkListItem />
                  <MarkOkListItem />
                  <StatusListItem />
                  {((isUUID(selectedList.split('-').splice(1).join('-')) || selectedList.startsWith('('))
                      && !selectedList.startsWith('ct_25')) && (
                    <TypeListItem onClick={() => {
                        setOpenTypeDialog(true);
                        setOpenActionMenu(false);
                      }}
                    />
                  )}
                  <MergeListItem onClick={handleOpenMergeDialog} />
                  <MatchListItem onClick={() => {
                    mainDispatchCallback({
                      type: SET_ROWS_TO_MATCH,
                      rowsToMatch: {...selectedData}
                    });
                    setOpenActionMenu(false);
                  }}/>
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      <ChangeTypeDialog
        onCancel={() => setOpenTypeDialog(false)}
        onConfirm={() => setOpenTypeDialog(false)}
        open={openTypeDialog}
      />
      <StepperDialog
        key={dialogKey}
        columns={mergeColumns}
        disableBackdropDismiss={true}
        displayObjectInTitle={false}
        enableRowSelection={true}
        open={openMergeDialog}
        onCancel={onMergeCancelled}
        onConfirm={onMergeConfirmed}
        translationKey='duplicates'
      />
    </Box>
  )
}