import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import ThemeProvider from '@mui/styles/ThemeProvider';

import { blue, grey, pink } from '@mui/material/colors';
import { Box, StyledEngineProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';

export const Theme = ({ children }) =>  {
  const appTheme = createTheme(themeOptions);
  const classes = useStyles();

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={appTheme}>
        <Box className={classes.root}>
          {children}
        </Box>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export const themeOptions = {
  palette: {
    primary: blue,
    secondary: pink,
    background: {
      default: grey[100]
    }
  },
  props: {
    MuiTextField: {
      variant: 'filled',
      color: 'primary'
    },
    MuiFormLabel: {
      color: 'primary'
    },
    MuiSelect: {
      variant: 'filled',
      color: 'primary'
    }
  }
};

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 1,
    top: 0,
    right: 0,
    left: 0,
    bottom: 0
  }
}));