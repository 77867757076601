import { ERROR_TYPES } from "../declarations/ErrorTypes";

function analyzeName(name) {
  return /[();"›‹»«0-9.\\/'?]/.test(name);
}

function analyzeStatus(frequency, invalid) {
  return invalid && frequency > 0;
}

function analyzeTermLength(name) {
  return name.trim().split(/\s+/).length > 2;
}

export function getTermCaption(artifact) {
  let name = artifact.name?.name || '';
  let caption = '';
  if (artifact.artifact_id.startsWith('ct_25')) {
    if (artifact.level === 2) caption = name;
    else if (artifact.level > 2) caption =`${name} (${artifact.full_path.split('» ')[1]})`;
  } else {
    caption  = artifact.parent_path ? `${name} (${artifact.parent_path.split('»')[0] || ''})` : name;
  }
  if (artifact.code) {
    caption =  `${caption} ${artifact.code}`;
  }
  return caption;
}

export function getTermHierarchy(artifact) {
  if (artifact.artifact_id.startsWith('ct_25')) {
    return artifact.parent_path.split('» ').splice(1).join('» ');
  } else {
    return artifact.parent_path ?? '';
  }
}

export const reAnalyzeTerm = (
  t,
  row
) => {
  let messages = new Set();

  if (analyzeName(row.name)) {
    messages.add(ERROR_TYPES.TERM_NAME_WARNING);
  }

  if (analyzeStatus(row.frequency, row.inValid)) {
    messages.add(ERROR_TYPES.TERM_STATE_ERROR);
  }

  if (analyzeTermLength(row.name)) {
    messages.add(ERROR_TYPES.TERM_TOO_LONG);
  }

  return {
    messages: [...messages],
    errors: [...messages].length
  }
}

export const primus10TermsToWasher = (
    t,
    bookmarkedRows,
    markedNoDuplicatesRows,
    markedOkRows,
    matchedRows,
    editedRows,
    data = [],
    usageData = [],
    removeMarkedNoDuplicate = false,
    duplicateView = false,
) => {
  let counts = {};
  let uData = {};

  usageData.forEach((u) => {
    uData[u.artifact_id] = u.usage;
  });

  let analyzedData = data.map((row) => {
    let messages = new Set();

    if (analyzeName(row['name.name'])) {
      messages.add(ERROR_TYPES.TERM_NAME_WARNING);
    }

    if (analyzeStatus(uData[row.artifact_id] || 0, !row.valid)) {
      messages.add(ERROR_TYPES.TERM_STATE_ERROR);
    }

    if (analyzeTermLength(row['name.name'])) {
      messages.add(ERROR_TYPES.TERM_TOO_LONG);
    }

    let caption = '';

    if (row.artifact_id.startsWith('ct_25')) {
      if (row.level === 2) {
        caption = row['name.name'];
      } else if (row.level > 2) {
        caption = `${row['name.name']} (${row.full_path.split('» ')[1]})`;
      }
    } else {
      caption = (row.parent_path !== '' && row.parent_path !== undefined)
        ? `${row['name.name']} (${row.parent_path.split('» ')[0] || ''})`
        : row['name.name'];
    }

    if (row.code) {
      caption = `${caption} ${row.code}`;
    }

    if (!markedNoDuplicatesRows.includes(row.artifact_id)) {
      const count = counts[caption.toUpperCase()] || [];
      count.push({
        id: row.artifact_id,
        authority: row.authority_id_value || ''
      })
      counts[caption.toUpperCase()] = count;
    }

    let solrData = row['name.name'] ? row['name.name'] : '';
    solrData += row['code'] ? ', ' + row['code'] : '';
    solrData += row['created_at'] ? ', ' + row['created_at'] : '';

    let hierarchy;
    if (row.artifact_id.startsWith('ct_25')) {
      hierarchy = row.parent_path.split('» ').splice(1).join('» ');
    } else {
      hierarchy = row.parent_path ?? '';
    }

    let authorityIdValue = '';
    if (row.authority_id_value) {
      authorityIdValue = row.authority_link_id ? `→ ${row.authority_id_value}` : row.authority_id_value;
    }

    let matchings = null;
    if (!row.authority_link_id) {
      matchings = matchedRows?.find((r) => r.rowId === row.id)?.matchings || null;
    }

    return {
      caption: caption,
      code: row.code || '',
      description: row['description.description'] || '',
      hierarchy: hierarchy,
      authorityId: row.authority_id || '',
      authorityIdValue: authorityIdValue,
      authorityLinkId: row.authority_link_id || '',
      hasAuthority: row.has_authority,
      id: row.artifact_id,
      inValid: !row.valid,
      frequency: uData[row.artifact_id] || 0,
      messages: markedOkRows.includes(row.artifact_id) ? [] : [...messages],
      mPath: row['m_path'] ? row['m_path'] : '',
      parentPath: row['parent_path'] ? row['parent_path'] : '',
      parentId: row.parent_id || '',
      name: row['name.name'],
      errors: markedOkRows.includes(row.artifact_id) ? -1 : [...messages].length,
      duplicates: 0,
      duplicateData: [],
      objectType: row.object_type,
      matchings: matchings,
      bookmarked: bookmarkedRows.includes(row.artifact_id),
      solrData: solrData,
      edit: editedRows?.find((r) => r.rowId === row.artifact_id)?.editDate || null,
      hasChildren: !row.is_leaf,
      collectionId: row['collection.collection_id'] || '',
      collectionIdValue: row['collection.collection_id_value'] || '',
      authorityDatasetId: row.authority_dataset_id || '',
      authorityDatasetIdValue: row.authority_dataset_id_value || ''
    }
  });

  analyzedData = analyzedData.map((row) => {
    let count = counts[row.caption.toUpperCase()] || [];
    if (!markedNoDuplicatesRows.includes(row.id)) {
      row.duplicates = count.length;
      row.duplicateData = count;
    } else {
      row.duplicates = 1;
      row.duplicateData = [];
    }

    if (removeMarkedNoDuplicate) {
      if (!markedNoDuplicatesRows.includes(row.id)) {
        return row;
      }
    }

    if (duplicateView) {
      if (row.duplicates > 1) {
        return row;
      }
    } else {
      return row;
    }
  });

  analyzedData = analyzedData.filter((f) => f !== undefined);

  return analyzedData;
}