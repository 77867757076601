import { SUPEROBJECT_TYPE_AGENT_ID, SUPEROBJECT_TYPE_CONCEPT_ID } from "../declarations/UUIDs";

export const createFieldValueOperation = (field, changeTo, artifact) => {
    let operation = {
        operation_type_id: 'e86d8412-d0dd-400c-9e75-7b67c3d7982e',
        operation_step_index: 0,
        operation_objects: [
            {
                add_replace_delete_choice: 'set_add',
                checksum: null,
                id: null,
                is_root_model: false,
                meta_type: 'operation_model',
                model_title: null,
                object_type: 'FieldValueOperationModel',
                operation_contexts: [],
                operation_contexts_restricted: [],
                operation_delete_value: null,
                operation_field_required: null,
                operation_inline_prop: field,
                operation_inline_unique_props: [],
                operation_replace_from: null,
                operation_replace_with: null,
                operation_set_value: null,
                operation_value_is_array: null,
                operation_value_is_text: null,
                select_field: null,
                select_field_name: field,
                selected_superobject_type_ids: [],
                set_add_choice: 'replace_text'
            }
        ],
        operation_contexts: [
            {
                artifact_id: artifact.id,
                artifact_name: artifact.name,
                superobject_type_id: artifact.superobjectTypeId,
                object_type: 'agent',
                meta_type: 'named'
            }
        ]
    }

    if (field === 'description') {
        operation.operation_objects[0].operation_set_value = [
            {
                model_title: null,
                is_root_model: false,
                checksum: null,
                object_type: 'SuperobjectDescription',
                meta_type: 'sub_model',
                context_id: null,
                context_id_value: null,
                revision_id: null,
                parent_id: null,
                parent_object_type: null,
                parent_meta_type: null,
                description: changeTo
            }
        ]
    } else if (field === 'name') {
        operation.operation_objects[0].operation_set_value = [
            {
                model_title: null,
                is_root_model: false,
                checksum: null,
                object_type: 'SuperobjectName',
                meta_type: 'sub_model',
                context_id: null,
                context_id_value: null,
                revision_id: null,
                parent_id: null,
                parent_object_type: null,
                parent_meta_type: null,
                name: changeTo
            }
        ]
    }

    return operation;
}

export const createReplaceFieldValueOperation = (field, changeFrom, changeTo, artifact) => {
    let operation = {
        operation_type_id: 'e86d8412-d0dd-400c-9e75-7b67c3d7982e',
        operation_step_index: 0,
        operation_objects: [
            {
                add_replace_delete_choice: 'replace',
                checksum: null,
                create: true,
                id: null,
                is_root_model: false,
                meta_type: 'operation_model',
                model_title: null,
                object_type: 'FieldValueOperationModel',
                operation_contexts: [],
                operation_contexts_restricted: [],
                operation_delete_value: null,
                operation_field_required: null,
                operation_inline_prop: null,
                operation_inline_unique_props: [],
                operation_replace_from: changeFrom,
                operation_replace_with: changeTo,
                operation_set_value: null,
                operation_value_is_array: null,
                operation_value_is_text: null,
                select_field: null,
                select_field_name: field,
                selected_superobject_type_ids: [],
            }
        ],
        operation_contexts: [
            {
                artifact_id: artifact.id,
                artifact_name: artifact.name,
                superobject_type_id: artifact.superobjectTypeId,
                object_type: 'agent',
                meta_type: 'named'
            }
        ],
        operation_params: {
            select_field: null
        }
    }

    if (field === 'agent_type_id') {
        operation.operation_params.select_field = 'MetaField-611cc917-6a7a-4a4f-9fca-b25b636508a9'
    }

    return operation;
}

export const createMergeOperations = (selectedRows, selectedRowId, usageData) => {
    let operations = [];
    let winner = selectedRowId;

    selectedRows.forEach((row) => {
        if (row.id !== selectedRowId && !row.hasAuthority) {
            usageData[row.id].forEach((usage) => {
                if (!operations[usage.field_id]) {
                    operations[usage.field_id] = [];
                }
                if (!operations[usage.field_id][row.id]) {
                    operations[usage.field_id][row.id] = {
                        operation_type_id: 'de0c8d1e-d001-400d-9d25-6748206664a0',
                        operation_step_index: 0,
                        operation_objects: [
                            {
                                select_field_name: usage.target_field || 'named_id',
                                operation_inline_unique_props: [usage.target_field || 'named_id'],
                                operation_replace_from: [],
                                operation_replace_with: {
                                    object_type: usage.inline_object_type || usage.object_type
                                }
                            }
                        ],
                        operation_contexts: [
                            {
                                artifact_id: usage.artifact_id
                            }
                        ]
                    }

                    let obj = {};
                    obj[usage.target_field || 'named_id'] = row.id;
                    operations[usage.field_id][row.id].operation_objects[0].operation_replace_from.push(obj);

                    operations[usage.field_id][row.id]
                      .operation_objects[0].operation_replace_with[usage.target_field || 'named_id'] = winner;
                } else {
                    operations[usage.field_id][row.id].operation_contexts.push({
                        artifact_id: usage.artifact_id
                    });
                }
           });
       }
    });

    return operations;
};

export const createJoinOperation = (selectedRows) => {
    let operation = {
        timezone_offset: -60,
        operation_type_id: "d60420e5-2a24-4760-b039-6ba143e920f6",
        operation_step_index: 0,
        operation_objects: [],
        operation_contexts: []
    }
    selectedRows.forEach((row) => {
        operation.operation_contexts.push({
            artifact_id: row.id,
            meta_type: row.objectType === 'agent' ? 'named' : 'concept',
            object_type: row.objectType,
            superobject_type_id: row.objectType === 'agent'
                ? SUPEROBJECT_TYPE_AGENT_ID
                : SUPEROBJECT_TYPE_CONCEPT_ID
        });
    })
    return operation;
};

export const createSetValidOperation = (selectedRows, selectedRowIndex) => {
    let operation = {
        operation_type_id: '6b1a38e5-26d7-4765-b997-126c329856c1',
        operation_step_index: 0,
        operation_objects: [
            {
                operation_contexts: []
            }
        ],
        operation_contexts: []
    }
    selectedRows.forEach((artifact, index) => {
        if (!selectedRowIndex || index !== Number(selectedRowIndex)) {
            operation.operation_objects[0].operation_contexts.push({
                context_id: artifact.id
            });

            operation.operation_contexts.push({
                artifact_id: artifact.id,
                meta_type: artifact.objectType === 'agent' ? 'named' : 'concept'
            })
        }
    });

    return operation;
}

export const createSetInvalidOperation = (selectedRows, selectedRowIndex) => {
    let operation = {
        operation_type_id: '49aa9676-6260-424f-a28f-e4fde6b1e4f0',
        operation_step_index: 0,
        operation_objects: [
            {
                operation_contexts: []
            }
        ],
        operation_contexts: []
    }
    selectedRows.forEach((artifact, index) => {
        if (!selectedRowIndex || index !== Number(selectedRowIndex)) {
            operation.operation_objects[0].operation_contexts.push({
                context_id: artifact.id
            });

            operation.operation_contexts.push({
                artifact_id: artifact.id,
                meta_type: artifact.objectType === 'agent' ? 'named' : 'concept'
            })
        }
    });

    return operation;
}

export const getJoinOperationObjectParams = (ids, objectType) => {
    const params = {
        context_objects: [],
        operation_step_index: 0,
        operation_type_id: 'd60420e5-2a24-4760-b039-6ba143e920f6'
    };
    ids.forEach((id) => {
        params.context_objects.push({
            artifact_id: id,
            meta_type: objectType === 'agent' ? 'named' : 'concept',
            object_type: objectType,
            superobject_type_id: objectType === 'agent'
                ? 'ct_128-d796ee93-0200-4b80-92c5-6f2913b559d5'
                : 'ct_128-d796ee93-1400-4b80-92c5-6f2913b559d5',
        });
    });
    return params;
}

// export const createFieldOperation = () => {
//     let operation = {
//         operation_type_id: 'e86d8412-d0dd-400c-9e75-7b67c3d7982e',
//         operation_steps: [
//             {
//                 operation_step_type: 'create_object',
//                 object_actions: [
//                     {
//
//                     }
//                 ]
//             }
//         ]
//     }
// }
