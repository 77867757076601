import { AddLink, ChangeCircle, MoreHoriz } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogTitle,
  LinearProgress,
  ListItemIcon,
  MenuItem,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useState } from 'react';

import { SET_INDEXED_DATA, SET_TABLE_DATA, useTableState } from "../../contexts/TableContext";
import { getMatchSourceColumns, getTermsMatchSourceColumns } from '../../declarations/TvattTableColumnDeclarations';
import {
  AUTHORITY_KULTURNAV_ID,
  authorityMap,
  HISTORY_EVENT_TYPE_BIRTH_ID,
  HISTORY_EVENT_TYPE_DEATH_ID,
  SUPEROBJECT_TYPE_CONCEPT_ID
} from "../../declarations/UUIDs";
import { dumpAllIndexedData, toggleEditedRow } from "../../services/IndexedDbService";
import { formatDateWithPrecision, getCaption, reAnalyzeWasherRow } from "../../utils/primusDataHandling";
import { createJoinOperation, getJoinOperationObjectParams } from "../../utils/primusMetaOperationHandler";
import { getTermCaption, getTermHierarchy, reAnalyzeTerm } from "../../utils/primusTermDataHandling";
import { generateTableTranslation } from '../../utils/tvattTableTranslation';
import { DetailPanel } from '../DetailPanels/DetailPanel';
import { getClickableItem } from "../DetailPanels/DetailPanelUtils";
import { TermDetailPanel } from "../DetailPanels/TermDetailPanel";

export const MatchDialog = ({
  activeStep,
  api,
  checkStatus,
  columns,
  disableButtons,
  dispatchTableCallback,
  dispatchNotification,
  displayObjectInTitle=false,
  handleClickField,
  invalidateMergedRows,
  isTerm,
  isTestEnv,
  matchData = null,
  matchRow = null,
  onCancel,
  onConfirm,
  open=false,
  progress,
  setActiveStep,
  showProgress,
  t,
}) => {
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState({});
  const [actionReplace, setActionReplace] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: tableData } = useTableState();

  const tableLocalization = generateTableTranslation(t);
  const matchSourceColumns = useMemo(() => {
    return matchRow.original.hasOwnProperty('code') ? getTermsMatchSourceColumns(t) : getMatchSourceColumns(t);
  }, [matchRow.original, t]);

  let objectType = matchRow.original.objectType;

  const updateTableRow = (artifact, oldId, usage) => {
    let tempTable = tableData;
    let index = tableData.findIndex((item) => item.id === oldId);
    if (artifact && index >= 0) {
      tempTable[index].id = artifact.artifact_id;
      tempTable[index].key = artifact.artifact_id;

      tempTable[index].name = artifact.name?.name;
      tempTable[index].description = artifact.description?.description;
      tempTable[index].solrData = artifact.artifact_name || artifact.name?.name;

      tempTable[index].authorityId = artifact.authority_id || '';
      let authorityIdValue = '';
      if (artifact.authority_id_value) authorityIdValue = artifact.authority_link_id
        ? `➔ ${artifact.authority_id_value}`
        : artifact.authority_id_value;
      tempTable[index].authorityIdValue = authorityIdValue;
      tempTable[index].hasAuthority = artifact.has_authority;
      tempTable[index].authorityDatasetId = artifact.authority_dataset_id || '';
      tempTable[index].authorityDatasetIdValue = artifact.authority_dataset_id_value || '';
      tempTable[index].authorityLinkId = artifact.authority_link_id || '';

      let analysisData;
      if (artifact.superobject_type_id === SUPEROBJECT_TYPE_CONCEPT_ID) {
        tempTable[index].caption = getTermCaption(artifact);

        tempTable[index].mPath = artifact.m_path || '';
        tempTable[index].parentPath = artifact.parent_path || '';
        tempTable[index].parentId = artifact.parent_id || '';
        tempTable[index].hierarchy = getTermHierarchy(artifact);

        analysisData = reAnalyzeTerm(t, tempTable[index]);
      } else {
        let beginEvent = artifact.history_events?.findLast((event) => event.type_id === HISTORY_EVENT_TYPE_BIRTH_ID);
        let endEvent = artifact.history_events?.findLast((event) => event.type_id === HISTORY_EVENT_TYPE_DEATH_ID);
        if (beginEvent) {
          tempTable[index].beginDateId = beginEvent.history_event_id || '';
          tempTable[index].beginDatePrecision = beginEvent.from_date_precision || 'year';
          let beginDate = beginEvent.timespan_historic?.from_date || beginEvent.from_date;
          tempTable[index].beginDate = beginDate
            ? formatDateWithPrecision(beginDate, tempTable[index].beginDatePrecision)
            : '';
        } else {
          tempTable[index].beginDate = '';
          tempTable[index].beginDateId = '';
          tempTable[index].beginDatePrecision = '';
        }
        if (endEvent) {
          tempTable[index].endDateId = endEvent.history_event_id || '';
          tempTable[index].endDatePrecision = endEvent.from_date_precision || 'year';
          let endDate = endEvent.timespan_historic?.from_date || endEvent.from_date;
          tempTable[index].endDate = endDate ? formatDateWithPrecision(endDate, tempTable[index].endDatePrecision) : '';
        } else {
          tempTable[index].endDate = '';
          tempTable[index].endDateId = '';
          tempTable[index].endDatePrecision = '';
        }

        tempTable[index].caption = getCaption(artifact, tempTable[index].beginDate, tempTable[index].endDate);

        tempTable[index].agentTypeId = artifact.agent_type_id || '';
        tempTable[index].agentTypeIdValue = artifact.agent_type_id_value || '';

        analysisData = reAnalyzeWasherRow(t, tempTable[index]);
      }
      tempTable[index].errors = analysisData?.errors;
      tempTable[index].messages = analysisData?.messages;
      tempTable[index].matchings = null;
      tempTable[index].frequency = usage ?? tempTable[index].frequency;

      tempTable[index].edit = new Date().toISOString();
      toggleEditedRow(tempTable[index]).then(dumpAllIndexedData)
        .then((data) => {
          dispatchTableCallback({
            type: SET_INDEXED_DATA,
            indexedData: data
          });
        });

      dispatchTableCallback({
        type: SET_TABLE_DATA,
        data: [...tempTable]
      });
    }
  };

  const nextStep = (row = null) => {
    if (activeStep === 0) {
      setActiveStep(1);
      setSelectedRow(row);
    } else if (activeStep === 1) {
      if (actionReplace) {
        dispatchNotification('information', 'table.default.jobs.startingReplace', 3000,
          { name: selectedRow.original.name });
        api.importFromKulturNav(selectedRow.original.uuid, objectType).then((res, e) => {
          if (!res || e) {
            if (isTestEnv) console.error('[MatchDialog] Error when importing from KulturNav, error:', e);
            dispatchNotification('error', 'table.default.jobs.KulturNavImportError', undefined, undefined, true);
          } else {
            let params = getJoinOperationObjectParams([res.artifact_id, matchRow.original.id], objectType);
            api.getOperationObject(params).then((obj) => {
              let operationObject = obj;
              let winnerIndex = operationObject.join_items.findIndex((item) => res.artifact_id === item.join_item_id);
              operationObject.join_items[winnerIndex].join_item_selected = true;
              return operationObject;
            }).then((obj) => {
              if (obj) {
                let operation = createJoinOperation(data);
                operation.operation_objects.push(obj)
                if (isTestEnv) console.info('[MatchDialog] Operation created', operation);
                return api.executeOperationStep(operation);
              } else {
                return Promise.reject(new Error('Failed to make join operation'));
              }
            }).then((operationRes) => {
              function callbackFn() {
                api.getUsage([res.artifact_id]).then((usageRes) => {
                  invalidateMergedRows([matchRow.original]).then(() => {
                    let usageNr = usageRes[res.artifact_id] ? usageRes[res.artifact_id].length : 0;
                    updateTableRow(res, matchRow.original.id, usageNr);
                  });
                }).catch((e) => {
                  dispatchNotification('error', 'table.default.jobs.errorGettingUsage', undefined, undefined, e, true);
                });
              }
              checkStatus(operationRes, callbackFn);
            }).catch((e) => {
              if (isTestEnv) console.error('[MatchDialog] Error when starting operations', e);
              dispatchNotification('error', 'table.default.jobs.operationError', undefined, undefined, e, true);
            });
          }
        });
      } else {
        dispatchNotification('information', 'table.default.jobs.startingLink', 3000,
          { name: selectedRow.original.name }, undefined, true);
        api.getArtifact(matchRow.original.id).then((res) => {
          let temp = res;
          temp['authority_id'] = AUTHORITY_KULTURNAV_ID;
          temp['authority_id_value'] = authorityMap[AUTHORITY_KULTURNAV_ID];
          temp['authority_dataset_id'] = selectedRow.original.datasetUuid;
          temp['authority_dataset_id_value'] = selectedRow.original.dataset;
          temp['authority_link_id'] = selectedRow.original.uuid;
          api.putArtifact(temp).then(() => {
            updateTableRow(temp, matchRow.original.id);
            dispatchNotification('success','dialogs.matchings.rowLinked', 5000, undefined, undefined, true);
          }).catch((e) => {
            if (isTestEnv) console.error('[MatchDialog] Error when updating artifact to link', e);
            dispatchNotification('error', 'table.default.jobs.putArtifactError', undefined,
              { name: matchRow.original.name }, e, true);
          });
        }).catch((e) => {
          if (isTestEnv) console.error('[MatchDialog] Error when getting artifact to link', e);
          dispatchNotification('error', 'table.default.jobs.getArtifactError', undefined,
            { name: matchRow.original.name }, e, true);
        });
      }
    }
  }

  const previousStep = () => {
    if (activeStep === 1) {
      setActiveStep(0);
    }
  }


  useEffect(() => {
    if (open && activeStep === 0) {
      setLoading(true);
      let tmpData = matchData.map((m) => ({
        ...m,
        knDuplicateExists: tableData.some((t) => m.uuid === t.id.slice(-36)),
      }));
      setData(tmpData);
      setLoading(false);
    }
  }, [activeStep, open, matchData, matchRow, tableData])

  return (
    <>
      <DialogTitle>
        {t('dialogs.matchings.title')}
        {displayObjectInTitle && matchRow.original.caption}
      </DialogTitle>

      <Box
        sx={{
          margin: '0 auto',
          width: 'fit-content'
        }}
      >
        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>{t('dialogs.matchings.step1')}</StepLabel>
          </Step>
          <Step>
            <StepLabel>{t('dialogs.matchings.step2')}</StepLabel>
          </Step>
        </Stepper>
      </Box>

      {activeStep === 0 && (
        <Box
          sx={{
            width: '90vw',
            overflowY: 'auto'
          }}
        >
          <Alert
            icon={false}
            severity={'info'}
            sx={{
              margin: '10px'
            }}
          >
            {t('dialogs.matchings.alert1')}
          </Alert>

          <Typography sx={{
            fontSize: '18px',
            fontWeight: '500',
            padding: '10px'
          }}>
            {t('dialogs.matchings.matchSource')}
          </Typography>

          <MaterialReactTable
            columns={matchSourceColumns}
            data={[matchRow]}
            enablePagination={false}
            enableRowActions={false}
            enableRowSelection={false}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            localization={tableLocalization}
            renderDetailPanel={({row}) => row.original.original.hasOwnProperty('code')
              ? <TermDetailPanel rowData={row.original.original} toggleIsExpanded={row.toggleExpanded} disableEditing/>
              : <DetailPanel disableEditing row={row.original} />
            }
            muiDetailPanelProps={{
              sx: {
                width: '100%',
                '.MuiCollapse-vertical': {
                  width: '100%'
                }
              }
            }}
          />

          <Typography sx={{
            fontSize: '18px',
            fontWeight: '500',
            padding: '10px'
          }}>
            {t('dialogs.matchings.matchTarget')}
          </Typography>

          <MaterialReactTable
            columns={columns}
            data={data}
            enableMultiRowSelection={false}
            enablePagination={false}
            enablePinning={true}
            enableRowActions={true}
            enableRowSelection={false}
            enableStickyHeader={true}
            enableStickyFooter={true}
            enableTopToolbar={false}
            enableBottomToolbar={false}
            icons={{MoreHorizIcon: () => (
              <MoreHoriz
                sx={{ backgroundColor: '#707070', borderRadius: '6px', color: '#FFFFFF', fontSize: '30px'}}
              />
            )}}
            localization={tableLocalization}
            onRowSelectionChange={(state) => {
              let index = tableData.findIndex((r) => r.id === data[Object.keys(state())[0]].id);
              setSelectedRow(tableData[index]);
              setSelectedRowIndex((s) => state(s));
            }}
            positionActionsColumn={'last'}
            state={{rowSelection: selectedRowIndex, isLoading: loading}}
            renderDetailPanel={({row}) => (
              <Box
                sx={{
                  margin: '0 auto',
                  width: 'fit-content'
                }}
              >
                <table>
                  <tbody>
                  <tr>
                    <td>{t('dialogs.matchings.matchLanguage')}</td>
                    <td>{row.original.matchingLanguage}</td>
                  </tr>
                  <tr>
                    <td>{t('dialogs.matchings.matchAttribute')}</td>
                    <td>{row.original.matchingProperty}</td>
                  </tr>
                  <tr>
                    <td>{t('dialogs.matchings.bestMatch')}</td>
                    <td>{row.original.bestMatchingProperty}</td>
                  </tr>
                  <tr>
                    <td>{t('dialogs.matchings.matchString')}</td>
                    <td>{row.original.matchingString}</td>
                  </tr>
                  </tbody>
                </table>
              </Box>
            )}
            renderRowActionMenuItems={({ row }) => row.original.knDuplicateExists ? [
                <MenuItem
                  key={0}
                  disabled={true}
                >
                  {t('dialogs.matchings.duplicateAuthority')}
                </MenuItem>
              ] : [
                <MenuItem
                  key={0}
                  onClick={() => {
                    setActionReplace(false);
                    nextStep(row);
                  }}
                  sx={{ m: 0 }}
                >
                  <ListItemIcon>
                    <AddLink/>
                  </ListItemIcon>
                  {t('dialogs.matchings.linkAction')}
                </MenuItem>,
                <MenuItem
                  key={1}
                  onClick={() => {
                    setActionReplace(true);
                    nextStep(row);
                  }}
                  sx={{ m: 0 }}
                  disabled={objectType === 'ct_25' || matchRow.original?.hasChildren}
                >
                  <ListItemIcon>
                    <ChangeCircle/>
                  </ListItemIcon>
                  {t('dialogs.matchings.replaceAction')}
                </MenuItem>
              ]
            }
          />
        </Box>
      )}

      {(activeStep === 1 && matchRow?.original) && (
        <Box width='90vw'>
          <Box padding='15px' width='90vw'>
            <Box display='flex' marginBottom={1} alignItems='center' gap={1}>
              {getClickableItem(matchRow.original.id, matchRow.original.objectType, matchRow.original?.caption,
                handleClickField, isTerm ? '' : 'Primus')
              } - {t(actionReplace ? 'dialogs.matchings.replace' : 'dialogs.matchings.linkWith')}
            </Box>
            <Box display='flex' alignItems='center' gap={1}>
              {getClickableItem(selectedRow?.original.uuid, 'KulturNav', selectedRow?.original?.caption,
                handleClickField, 'KulturNav')}
              {`(${t('dialogs.matchings.link')})`}
            </Box>
          </Box>
          {showProgress &&
            <LinearProgress variant={'determinate'} value={progress}/>
          }
        </Box>
      )}

      <DialogActions>
        <Button
          onClick={() => {
            onConfirm([matchRow.original], true);
          }}
        >
          {t('dialogs.matchings.noMatch')}
        </Button>
        <Button
          disabled={disableButtons}
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
        >
          {t('dialogs.matchings.cancel')}
        </Button>
        <Button
          disabled={activeStep < 1 || disableButtons}
          onClick={(e) => {
            e.stopPropagation();
            previousStep();
          }}
        >
          {t('dialogs.matchings.previous')}
        </Button>
        <Button
          disabled={activeStep < 1 || disableButtons}
          onClick={(e) => {
            e.stopPropagation();
            nextStep();
            onConfirm();
          }}
        >
          {t('dialogs.matchings.confirm')}
        </Button>
      </DialogActions>
    </>
  )
}