import React, { useCallback } from 'react';
import { Button } from '@mui/material';
import { HelpOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { useMainDispatch, SET_HELP_MENU_CONTENT_URL } from '../../contexts/MainContext';

export const TvattHelpButton = ({
  icon= false,
  title= null,
  url
}) => {
  const dispatch = useMainDispatch();
  const dispatchCallback = useCallback(dispatch, [dispatch]);

  const { t } = useTranslation('translations');

  const handleClick = () => {
    dispatchCallback({
      type: SET_HELP_MENU_CONTENT_URL,
      helpMenuContentTitle: title,
      helpMenuContentUrl: url
    })
  }

  return (
    <Button
      onClick={handleClick}
      startIcon={icon ? <HelpOutlined /> : false}
    >
      {t('menu.helpButtonText')}
    </Button>
  )
}