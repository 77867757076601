export const getDefaultListSelectorItems = () => {
  return {
    properName: {
      groupNameTranslationKey: 'menu.listSelectorGroups.properName',
      items: [
        {
          label: 'menu.listSelectorTypes.person',
          id: 'ct_23-784b329e-5a5d-4db7-ab34-f316d0b54f34',
          included: false,
          objectType: 'agent'
        },
        {
          label: 'menu.listSelectorTypes.organization',
          id: 'organizations',
          included: false,
          objectType: 'agent'
        },
        {
          label: 'menu.listSelectorTypes.group',
          id: 'ct_23-2c75fd64-7e4a-49a4-a7e7-74fa7fddaa6e',
          included: false,
          objectType: 'agent'
        },
        {
          label: 'menu.listSelectorTypes.animalThings',
          id: 'ct_23-16e2b243-dec6-4870-ac4c-c654fa83f42d',
          included: false,
          objectType: 'agent'
        },
      ]
    },
    namedObjects: {
      groupNameTranslationKey: 'menu.listSelectorGroups.namedObjects',
      items: [
        {
          label: 'menu.listSelectorTypes.vessel',
          id: 'ct_23-d18f4f7b-27ba-49f5-b245-d8f3944e123c',
          included: false,
          objectType: 'agent'
        },
        {
          label: 'menu.listSelectorTypes.building',
          id: 'building',
          included: false,
          objectType: 'agent'
        },
        {
          label: 'menu.listSelectorTypes.buildingFacilities',
          id: 'ct_23-904bc7f9-8a73-4e74-ac1d-7bf5d45c6ee7',
          included: false,
          objectType: 'agent'
        },
        {
          label: 'menu.listSelectorTypes.structure',
          id: 'ct_23-f3213454-7002-4130-b8bf-7b1190bf8ed6',
          included: false,
          objectType: 'agent'
        },
        {
          label: 'menu.listSelectorTypes.work',
          id: 'ct_23-040abd72-0318-49cd-bbc0-1afd0d2d1c69',
          included: false,
          objectType: 'agent'
        },
      ]
    },
    name: {
      groupNameTranslationKey: 'menu.listSelectorGroups.custom',
      items: [
        {
          label: 'menu.listSelectorTypes.namedOther',
          id: 'namedOther',
          included: false,
          objectType: 'agent'
        }
      ]
    },
    terms: {
      groupNameTranslationKey: 'menu.listSelectorGroups.terms',
      items: [
        {
          label: 'menu.listSelectorTypes.designations',
          id: null,
          included: false,
          objectType: 'ct_22'
        },
        {
          label: 'menu.listSelectorTypes.subject',
          id: null,
          included: false,
          objectType: 'ct_20'
        },
        {
          label: 'menu.listSelectorTypes.motifSubject',
          id: null,
          included: false,
          objectType: 'ct_21'
        },
        {
          label: 'menu.listSelectorTypes.technique',
          id: null,
          included: false,
          objectType: 'ct_6'
        },
        {
          label: 'menu.listSelectorTypes.materialObject',
          id: null,
          included: false,
          objectType: 'ct_9'
        }
      ]
    },
    classifications: {
      groupNameTranslationKey: 'menu.listSelectorGroups.classifications',
      items: []
    }
  }
}