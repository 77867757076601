import { Alert, Snackbar } from '@mui/material';
import React from 'react';

export const ClickAlert = ({ open, setOpen, clickCoordinates, message, duration }) => {
  return (
    <Snackbar open={open} autoHideDuration={duration || 2000} onClose={() => setOpen(false)}>
      <Alert
        severity='success'
        style={{
          padding: '0 12px',
          position: 'fixed',
          top: clickCoordinates?.y,
          left: clickCoordinates?.x,
        }}
      >
        {message}
      </Alert>
    </Snackbar>
  )
}