import React, { useCallback, useState } from 'react';
import { Edit, Check, Close } from '@mui/icons-material';
import { Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { isUUID } from "validator";
import { useTranslation } from 'react-i18next';

import { dumpAllIndexedData, toggleMarkedOkRow } from '../../services/IndexedDbService';
import { reAnalyzeWasherRow } from '../../utils/primusDataHandling';
import { reAnalyzeTerm } from "../../utils/primusTermDataHandling";
import { usePrimusState } from "../../contexts/PrimusContext";
import { useTableDispatch, useTableState, SET_INDEXED_DATA, SET_TABLE_DATA } from "../../contexts/TableContext";

export const MarkOkListItem = () => {
  const tableDispatch = useTableDispatch();
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const [expanded, setExpanded] = useState(false);

  const { selectedList } = usePrimusState();
  const { data, selectedData } = useTableState();
  const [ t ] = useTranslation('translations');

  const toggleMarkSelected = (ok) => {
    const tempTableData = data;
    Object.keys(selectedData).forEach((artifactId, index) => {
      const rowIndex = tempTableData.findIndex((row) => row.id === artifactId);

      if (rowIndex !== -1) {
        if (ok) {
          tempTableData[rowIndex].errors = -1;
        }

        if (!ok) {
          let analysis = [];

          if ((isUUID(selectedList.split('-').splice(1).join('-')) || selectedList.startsWith('(')) && !selectedList.startsWith('ct_25')) {
            analysis = reAnalyzeWasherRow(t, tempTableData[rowIndex]);
          }
          else {
            analysis = reAnalyzeTerm(t, tempTableData[rowIndex]);
          }

          tempTableData[rowIndex].messages = analysis.messages;
          tempTableData[rowIndex].errors = analysis.errors;
        }
      }

      toggleMarkedOkRow(artifactId, ok).then();

      if (index === Object.keys(selectedData).length - 1) {
        dumpAllIndexedData().then((data) => {
          tableDispatchCallback({
            type: SET_INDEXED_DATA,
            indexedData: data
          });
        });
      }

      tableDispatchCallback({
        type: SET_TABLE_DATA,
        data: [...tempTableData]
      })
    })
  }

  return (
    <>
      <ListItemButton onClick={() => setExpanded(!expanded)}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText primary={t('menu.actionHeader.listItems.changeOk')} />
      </ListItemButton>
      <Collapse in={expanded}>
        <List componen='div' disablePadding>
          <ListItemButton
            onClick={() => toggleMarkSelected(true)}
            sx={{
              pl: 4
            }}
          >
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText primary={t('menu.actionHeader.listItems.ok')} />
          </ListItemButton>
          <ListItemButton
            onClick={() => toggleMarkSelected(false)}
            sx={{
              pl: 4
            }}
          >
            <ListItemIcon>
              <Close />
            </ListItemIcon>
            <ListItemText primary={t('menu.actionHeader.listItems.notOk')} />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  )
}
