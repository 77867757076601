import React from 'react';
import { useHeaderState } from '@ekultur/header-microfrontend';

import { TvattLoader } from '../TvattLoader/TvattLoader';
import { useAppState } from '../../app/AppContext';

export const WhenHeaderIsReady = ({ children }) => {
  const { ready } = useHeaderState();
  const { rendered } = useAppState();

  if (!ready || !rendered) {
    return <TvattLoader />
  }

  return (
    <>
      {children}
    </>
  );
};