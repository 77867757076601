import React, { createContext, useContext, useEffect, useReducer } from 'react';

import { dumpAllIndexedData } from "../services/IndexedDbService";
import { TvattLoader } from "../components/TvattLoader/TvattLoader";

const TableDispatchContext = createContext(null);
const TableStateContext = createContext(null);

const initialState = {
  data: [],
  filter: [],
  filterName: 'none',
  indexedData: null,
  pagination: {pageIndex: 0, pageSize: 25},
  pinnedRows: {top: [], bottom: []},
  rowsToMatch: [],
  selectedData: {},
  sorting: [{id: 'caption', desc: false}]
}

const Reducer = (state, action) => {
  switch (action.type) {
    case RESET_TABLE_STATE: {
      return {
        ...state,
        data: initialState.data,
        filter: initialState.filter,
        filterName: initialState.filterName,
        pagination: { pageIndex: 0, pageSize: state.pagination.pageSize },
        pinnedRows: initialState.pinnedRows,
        selectedData: initialState.selectedData,
        sorting: initialState.sorting
      }
    }

    case SET_INDEXED_DATA: {
      return {
        ...state,
        indexedData: action.indexedData
      }
    }

    case SET_TABLE_DATA: {
      return {
        ...state,
        data: [...action.data]
      }
    }

    case SET_TABLE_DATA_SELECTION: {
      return {
        ...state,
        selectedData: action.selectedData
      }
    }

    case SET_TABLE_FILTER: {
      return {
        ...state,
        filter: action.filter
      }
    }

    case SET_TABLE_FILTER_NAME: {
      return {
        ...state,
        filterName: action.filterName
      }
    }

    case SET_TABLE_PAGINATION: {
      return {
        ...state,
        pagination: action.pagination
      }
    }

    case SET_TABLE_PINNED_ROWS: {
      return {
        ...state,
        pinnedRows: action.pinnedRows
      }
    }

    case SET_TABLE_SORTING: {
      return {
        ...state,
        sorting: action.sorting
      }
    }

    default:
      return {
        ...state
      }
  }
}

export function TableContextProvider({ children }) {
  const [state, dispatch] = useReducer(Reducer, {...initialState}, undefined);

  useEffect(() => {
    if (state.indexedData === null) {
      dumpAllIndexedData().then((data) => {
        dispatch({
          type: SET_INDEXED_DATA,
          indexedData: data
        })
      })
    }
  }, [state])

  if (state.indexedData === null) {
    return (
      <TvattLoader fullscreen={true} />
    )
  }

  return (
    <TableStateContext.Provider value={state}>
      <TableDispatchContext.Provider value={dispatch}>
        {children}
      </TableDispatchContext.Provider>
    </TableStateContext.Provider>
  )
}

export const useTableDispatch = () => {
  const context = useContext(TableDispatchContext);

  if (context === undefined) {
    throw new Error('[TableContext] TableDispatch must be used within a TableContextProvider');
  } else {
    return context;
  }
}

export const useTableState = () => {
  const context = useContext(TableStateContext);

  if (context === undefined) {
    throw new Error('[TableContext] TableState must be used within a TableContextProvider');
  }

  return context;
}

export const RESET_TABLE_STATE = 'resetTableFilterSorting';
export const SET_INDEXED_DATA = 'setIndexedData';
export const SET_TABLE_DATA = 'setTableData';
export const SET_TABLE_DATA_SELECTION = 'setTableDataSelection';
export const SET_TABLE_FILTER = 'setTableFilter';
export const SET_TABLE_FILTER_NAME = 'setTableFilterName';
export const SET_TABLE_PAGINATION = 'setTablePagination';
export const SET_TABLE_PINNED_ROWS = 'setTablePinnedRows';
export const SET_TABLE_SORTING = 'setTableSorting';