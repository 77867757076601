import React from 'react';

import { DuplicateView } from '../../views/duplicateView/DuplicateView';
import { ListView } from '../../views/listView/ListView';

export const RoutesAuthenticated = () => {
  return (
    <>
      <DuplicateView />
      <ListView />
    </>
  )
}