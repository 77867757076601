import Dexie from 'dexie';

export const indexedDb = new Dexie('tvattmaskinen');
indexedDb.version(7).stores({
  bookmarkedRows: '++id, rowId',
  markedNoDuplicates: '++id, rowId',
  markedOkRows: '++id, rowId',
  matchedRows: '++id, rowId, matchings',
  editedRows: '++id, rowId, editDate'
});

export const dumpAllIndexedData = async () => {
  return {
    bookmarkedRows: await getAllBookmarkedRows(),
    markedNoDuplicatesRows: await getAllMarkedNoDuplicatesRow(),
    markedOkRows: await getAllMarkedOkRows(),
    matchedRows: await getAllMatchedRows(),
    editedRows: await getAllEditedRows()
  }
}

export const getAllBookmarkedRows = async () => {
  let rows = await indexedDb.bookmarkedRows.toArray();
  let ids = [];

  rows.forEach((row) => {
    ids.push(row.rowId);
  })

  return ids;
}

export const getBookmarkedRow = async (parentId) => {
  return await indexedDb.bookmarkedRows.get({rowId: parentId});
}

export const getAllMarkedNoDuplicatesRow = async () => {
  let rows = await indexedDb.markedNoDuplicates.toArray();
  let ids = [];

  rows.forEach((row) => {
    ids.push(row.rowId);
  });

  return ids;
}

export const getAllMarkedOkRows = async () => {
  let rows = await indexedDb.markedOkRows.toArray();
  let ids = [];

  rows.forEach((row) => {
    ids.push(row.rowId);
  })

  return ids;
}

export const getAllMatchedRows = async () => {
  return await indexedDb.matchedRows.toArray();
}

export const toggleBookmarkedRow = async (rowId, bookmarked) => {
  let row = await indexedDb.bookmarkedRows.get({rowId: rowId});

  if (bookmarked && !row) {
    await indexedDb.bookmarkedRows.add({
      rowId: rowId
    })
  }

  if (!bookmarked && row) {
    await indexedDb.bookmarkedRows.delete(row.id);
  }
}

export const toggleMarkedNoDuplicatesRow = async (rowId, noDuplicate) => {
  let row = await indexedDb.markedNoDuplicates.get({rowId: rowId});

  if (noDuplicate && !row) {
    await indexedDb.markedNoDuplicates.add({
      rowId: rowId
    });
  }

  if (!noDuplicate && row) {
    await indexedDb.markedNoDuplicates.delete(row.id);
  }
}

export const toggleMarkedOkRow = async (rowId, ok) => {
  let row = await indexedDb.markedOkRows.get({rowId: rowId});

  if (ok && !row) {
    await indexedDb.markedOkRows.add({
      rowId: rowId
    });
  }

  if (!ok && row) {
    await indexedDb.markedOkRows.delete(row.id);
  }
}

export const updateMatchedRows = async (rowToUpdate) => {
  let data = await indexedDb.matchedRows.get({rowId: rowToUpdate.id});

  if (data) {
    await indexedDb.matchedRows.update(data.id, {
      rowId: data.rowId,
      matchings: rowToUpdate.matches
    })
  } else {
    await indexedDb.matchedRows.add({
      rowId: rowToUpdate.id,
      matchings: rowToUpdate.matches
    })
  }
}

export const getAllEditedRows = async () => {
  return await indexedDb.editedRows.toArray();
}

export const toggleEditedRow = async (row) => {
  let existingRow = await indexedDb.editedRows.get({ rowId: row.id });
  if (!existingRow) {
    await indexedDb.editedRows.add({ rowId: row.id, editDate: row.edit })
  } else {
    await indexedDb.editedRows.update(existingRow.rowId, { rowId: row.id, editDate: row.edit })
  }
}

export const clearEditedRows = async () => {
  await indexedDb.editedRows.clear()
}
