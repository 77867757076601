import React, { createContext, useContext, useReducer } from 'react';

const MainDispatchContext = createContext(null);
const MainStateContext = createContext(null);

const initialState = {
  helpMenuContentTitle: '',
  helpMenuContentUrl: '',
  helpMenuOpen: false,
  notifications: [],
  refreshTable: '',
  rowsToMatch: [],                              // TableContext
  noFuzzy: false
}

const MainReducer = (state, action) => {
  switch (action.type) {
    case REFRESH_TABLE:
      return {
        ...state,
        refreshTable: new Date().toString(),
        tableData: []
      }

    case SET_HELP_MENU_CONTENT_URL:
      return {
        ...state,
        helpMenuContentTitle: action.helpMenuContentTitle || '',
        helpMenuContentUrl: action.helpMenuContentUrl || '',
        helpMenuOpen: true
      }

    case SET_HELP_MENU_OPEN:
      return {
        ...state,
        helpMenuOpen: action.helpMenuOpen
      }

    case SET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.notifications
      }

    case SET_ROWS_TO_MATCH:
      return {
        ...state,
        rowsToMatch: action.rowsToMatch,
        noFuzzy: action.noFuzzy ?? false
      }

    case VIEW_CHANGED:
      return {
        ...state,
        tableFilter: [],
        tablePagination: {pageIndex: 0,pageSize: 25}
      }

    default:
      return {
        ...state
      }
  }
}
export const REFRESH_TABLE = 'refreshTable'
export const SET_HELP_MENU_CONTENT_URL = 'setHelpMenuContentUrl';
export const SET_HELP_MENU_OPEN = 'setHelpMenuOpen'
export const SET_NOTIFICATIONS = 'setNotifications';
export const SET_ROWS_TO_MATCH = 'setRowsToMatch';
export const VIEW_CHANGED = 'viewChanged';

export function MainContextProvider({ children }) {
  const [state, dispatch] = useReducer(MainReducer, {...initialState}, undefined);

  return (
    <MainStateContext.Provider value={state}>
      <MainDispatchContext.Provider value={dispatch}>
        {children}
      </MainDispatchContext.Provider>
    </MainStateContext.Provider>
  );
}

export const useMainDispatch = () => {
  const context = useContext(MainDispatchContext);

  if (context === undefined) {
    throw new Error('[MainContext] MainDispatch must be used within a MainContextProvider');
  } else {
    return context
  }
}

export const useMainState = () => {
  const context = useContext(MainStateContext);

  if (undefined === context) {
    throw new Error('[MainContext] MainState must be used within a MainContextProvider');
  }
  else {
    return context;
  }
}