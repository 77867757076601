import React from 'react';
import { Header, WithHeader } from '@ekultur/header-microfrontend';
import { login, logout } from '@ekultur/authentication';
import { useLocation } from 'react-router-dom';

import { MENU_OPENED, RENDERED, useAppDispatch } from '../../app/AppContext';
import { useAuthsDispatch, useAuthsState, USER_LOGGED_OUT } from '../../app/auths/AuthsContext';

export const EkulturHeader = () => {
  const { userIsAuthenticated } = useAuthsState();
  const appDispatch = useAppDispatch();
  const authsDispatch = useAuthsDispatch();
  const location = useLocation();

  const loginHandler = () => {
    const prevPath = location.state && location.state.previousPath ? location.state.previousPath : '/';

    login({
      locationPostLogin: prevPath,
      loginHost: window._env_.REACT_APP_LOGIN_HOST
    });
  };

  const logoutHandler = () => {
    logout({
      onLogout: () => authsDispatch({type: USER_LOGGED_OUT})
    });
  };

  return (
    <WithHeader>
      <Header
        appId={window._env_.REACT_APP_ID}
        loggedIn={userIsAuthenticated}
        loginHandler={loginHandler}
        logoutHandler={logoutHandler}
        mobileOpen={false}
        mobileOpenHandler={() => appDispatch({type: MENU_OPENED})}
        onRender={() => appDispatch({type: RENDERED})}
        version={window._env_.REACT_APP_VERSION}
      />
    </WithHeader>
  )
}