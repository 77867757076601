import 'whatwg-fetch';

import { getDefaultListSelectorItems } from '../declarations/KnownAgentTypeUuids';
import { MatchApi } from '../services/MatchApi';
import { SolrSearchParams } from '../declarations/SolrSearchParameters';

export class ListSelectorItems {
  primus = null;
  solrTypes = null;
  kulturNavTypes = null;
  sortedList = null;

  constructor(api) {
    this.sortedList = getDefaultListSelectorItems();
    this.primus = api;
    this.match = new MatchApi();
  }

  async getListItems() {
    let primusAgentTypes = await this._getAgentTypesFromPrimus();
    let primusTermTypes = await this._getTermTypesFromPrimus();
    let primusClassificationTypes = await this._getClassificationTypesFromPrimus();

    this.sortedList.properName.items.forEach((item) => {
      if (primusAgentTypes.hasOwnProperty(item.id)) {
        item.included = true;
        item.count = primusAgentTypes[item.id].count;

        if (item.id === 'organizations') {
          item.id = `(${primusAgentTypes[item.id].ids.join(' ')})`;
        }
      }
    });

    this.sortedList.namedObjects.items.forEach((item) => {
      if (primusAgentTypes.hasOwnProperty(item.id)) {
        item.included = true;
        item.count = primusAgentTypes[item.id].count;
      }
    });

    this.sortedList.name.items.forEach((item) => {
      if (primusAgentTypes.hasOwnProperty(item.id)) {
        item.included = true;
        item.count = primusAgentTypes[item.id].count;

        if (item.id === 'namedOther') {
          item.id = `(${primusAgentTypes[item.id].ids.join(' ')})`;
        }
      }
    });

    this.sortedList.terms.items.forEach((item) => {
      if (primusTermTypes.hasOwnProperty(item.objectType)) {
        item.included = true;
        item.count = primusTermTypes[item.objectType].count;
      }
    });

    Object.keys(primusClassificationTypes).forEach((key) => {
      this.sortedList.classifications.items.push({
        label: primusClassificationTypes[key].label,
        count: primusClassificationTypes[key].count,
        objectType: 'ct_25',
        id: primusClassificationTypes[key].id
      })
    });

    this.sortedList.properName.items = this.sortedList.properName.items.filter((item) => item.included);
    this.sortedList.namedObjects.items = this.sortedList.namedObjects.items.filter((item) => item.included);
    this.sortedList.terms.items = this.sortedList.terms.items.filter((item) => item.included);

    this.sortedList.name.items = this.sortedList.name.items.filter((item) => item.included);

    return this.sortedList;
  }


  async _getAgentTypesFromPrimus() {
    let agentTypes = {};
    let knownTypes = this._getKnownTypes();
    let organizationTypes = await this._getKulturNavOrganizations();
    let solrParams = new SolrSearchParams();

    organizationTypes.push('ct_23-19aa878b-5bda-4847-b0e6-39c34f0b2ee2');
    organizationTypes.push('ct_23-7d306c1b-ba63-4218-acef-47e9a815b694');
    organizationTypes.push('ct_23-fb8b920d-b709-4783-ab98-f6fcc1d0ea6f');

    solrParams.addToQuery('agent_type_id', '*');
    solrParams.setQueryOpAnd(true);
    solrParams.setFields('agent_type_id');
    solrParams.setResultLimit(1);
    solrParams.setFacetFields(['agent_type_id']);

    await this.primus.search(solrParams).then((results, error) => {
      if (results?.facets) {
        results.facets[0].items.forEach((facet) => {
          if (organizationTypes.includes(facet.id)) {
            if (!agentTypes.hasOwnProperty('organizations')) {
              agentTypes['organizations'] = {
                ids: [],
                count: 0
              }
            }
            agentTypes['organizations'].ids.push(facet.id);
            agentTypes['organizations'].count += facet.count;
          } else if (knownTypes.includes(facet.id)) {
            agentTypes[facet.id] = {
              id: facet.id,
              count: facet.count
            }
          } else {
            if (!agentTypes.hasOwnProperty('namedOther')) {
              agentTypes['namedOther'] = {
                ids: [],
                count: 0
              }
            }
            agentTypes['namedOther'].ids.push(facet.id);
            agentTypes['namedOther'].count += facet.count;
          }
        });
      } else throw new Error(error || results?.message);
    });

    return agentTypes;
  }

  async _getTermTypesFromPrimus() {
    let searchParams = new SolrSearchParams();
    let termTypes = {};
    let typeIds = [];

    this.sortedList.terms.items.forEach((item) => {
      typeIds.push(item.objectType);
    });

    typeIds.forEach((id) => {
      searchParams.addToQuery('object_type', id);
    });

    searchParams.setFacetFields(['object_type']);
    searchParams.setResultLimit(1);

    await this.primus.search(searchParams).then((results, error) => {
      if (results?.facets) {
        results.facets[0].items.forEach((facet) => {
          termTypes[facet.id] = {
            type: facet.id,
            count: facet.count
          }
        });
      } else throw new Error(error || results?.message);
    });

    return termTypes;
  }

  async _getClassificationTypesFromPrimus() {
    let classificationTypes = {};
    let searchParams = new SolrSearchParams();

    searchParams.addToQuery('object_type', 'ct_25');
    searchParams.setFacetFields(['m_path']);
    searchParams.setFacetMincount(1);

    let typeResult = await this.primus.search(searchParams);
    searchParams = new SolrSearchParams();
    searchParams.addToQuery('object_type', 'ct_25');
    searchParams.addToQuery('is_leaf', 'false');
    searchParams.setQueryOpAnd(true);

    let nameResult = await this.primus.search(searchParams);
    if (typeResult?.facets && nameResult?.artifacts) {
      typeResult.facets[0].items.forEach((facet) => {
        if (facet.id !== '' && !classificationTypes.hasOwnProperty(facet.id.split('/')[1])) {
          let nameObjectIndex = nameResult.artifacts.findIndex((a) => a.id === facet.id.split('/')[1]);
          let label = '';

          if (nameObjectIndex !== -1) {
            label = nameResult.artifacts[nameObjectIndex].artifact_name;
          }

          classificationTypes[facet.id.split('/')[1]] = {
            count: facet.count,
            id: facet.id.split('/')[1],
            label: label
          }
        } else if (facet.id !== '') {
          classificationTypes[facet.id.split('/')[1]].count += facet.count;
        }
      });
    } else throw new Error(typeResult?.message || nameResult?.message);

    return classificationTypes;
  }

  _getKnownTypes() {
    let ids = [];

    this.sortedList.properName.items.forEach((item) => {
      ids.push(item.id);
    });

    this.sortedList.namedObjects.items.forEach((item) => {
      ids.push(item.id);
    });

    this.sortedList.name.items.forEach((item) => {
      ids.push(item.id);
    });

    return ids;
  }

  async _getKulturNavOrganizations() {
    let types = [];
    await this.match.getListTypes().then((res) => {
      if (res?.organizations?.entities) {
        res.organizations.entities.forEach((entity) => {
          types.push(`ct_23-${entity.uuid}`);
        });
      } else throw new Error(res?.message);
    });
    return types;
  }
  async _mixKulturNavOrganisations() {
    let listItems = {};
    let organization_types = this.knownTypes['ct_23-fb8b920d-b709-4783-ab98-f6fcc1d0ea6f'].query;
    let available_types = {};
    let hasOrg = false;

    organization_types = organization_types.substring(0, organization_types.length-1);

    this.solrTypes.forEach((s) => {
      if (s.id === 'ct_23-3abed8a2-9818-4206-a8b6-55c34d45cfaf') {
        available_types['ct_23-9f515c98-c2ab-4559-a3d5-a4a9ad6bf64c'] = {
          count: s.count
        }
      }
      else {
        available_types[s.id] = {
          count: s.count
        }
      }
    })

    this.kulturNavTypes['organizations'].entities.forEach((o) => {
      if (available_types[`ct_23-${o.uuid}`]) {
        hasOrg = true;
        organization_types += ` ct_23-${o.uuid}`;
      }
    });

    this.kulturNavTypes['known_types'].entities.forEach((t) => {
      if (available_types[`ct_23-${t.uuid}`] && !organization_types.includes(t.uuid)) {
        if (this.knownTypes[`ct_23-${t.uuid}`]) {
          if (t.uuid === '9f515c98-c2ab-4559-a3d5-a4a9ad6bf64c' || t.uuid === '3abed8a2-9818-4206-a8b6-55c34d45cfaf') {
            listItems[`ct_23-9f515c98-c2ab-4559-a3d5-a4a9ad6bf64c`] = {
              label: this.knownTypes['ct_23-9f515c98-c2ab-4559-a3d5-a4a9ad6bf64c'].display,
              id: this.knownTypes['ct_23-9f515c98-c2ab-4559-a3d5-a4a9ad6bf64c'].query,
              group: this.knownTypes['ct_23-9f515c98-c2ab-4559-a3d5-a4a9ad6bf64c'].group
            }
          }
          else {
            listItems[`ct_23-${t.uuid}`] = {
              label: this.knownTypes[`ct_23-${t.uuid}`].display,
              id: `ct_23-${t.uuid}`,
              group: this.knownTypes[`ct_23-${t.uuid}`].group
            }
          }
        }
        else {
          listItems[`ct_23-${t.uuid}`] = {
            label: t.name['*'],
            id: `ct_23-${t.uuid}`,
            group: 'menu.listSelectorGroups.custom'
          }
        }
      }
      else if (available_types[`ct_23-${t.uuid}`] && organization_types.includes(t.uuid)) {
        hasOrg = true;
      }
    });


    if (hasOrg) {
      listItems['ct_23-fb8b920d-b709-4783-ab98-f6fcc1d0ea6f'] = {
        label: this.knownTypes['ct_23-fb8b920d-b709-4783-ab98-f6fcc1d0ea6f'].display,
        group: this.knownTypes['ct_23-fb8b920d-b709-4783-ab98-f6fcc1d0ea6f'].group,
        id: organization_types + ')'
      }
    }

    let result = [];

    for (const key in listItems) {
      result.push(listItems[key]);
    }

    if (result.length > 1) {
      result.push({
        label: 'menu.listSelectorTypes.all',
        group: 'menu.listSelectorGroups.custom',
        id: '*'
      })
    }

    result.sort((a,b) => {
      if (a.group < b.group) {
        return -1;
      }
      if (a.group > b.group) {
        return 1;
      }
      return 0;
    });

    return Promise.resolve(result)
  }
}
