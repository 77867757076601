import { Box, Button } from '@mui/material';
import React from 'react';
import { TvattLoader } from "../TvattLoader/TvattLoader";
import { getClickableItem } from "./DetailPanelUtils";

export const MAX_USAGE_CHIPS = 6;

export const DetailPanelUsageList = ({ usages, loading, handleClickField, setSidePanelOpen, t }) => {
  return (
    <Box display='flex' gap={1} flexDirection='column'>
      {loading ? (
        <Box p='auto'>
          <TvattLoader />
        </Box>
      ) : (
        <>
          {usages.slice(0, MAX_USAGE_CHIPS).map((usage) => {
            const openIn = !usage.object_type?.startsWith('ct_');
            return getClickableItem(usage.artifact_id, usage.object_type, usage.artifact_name, handleClickField,
              openIn, t(`table.default.detailpane.tooltips.${openIn ? 'open' : 'copy'}`));
          })}
          {usages.length > MAX_USAGE_CHIPS && (
            <Button
              color='primary'
              size='small'
              width='fit-content'
              onClick={() => setSidePanelOpen(true)}
            >
              {`${t('table.default.detailpane.seeAll')} (${usages.length})`}
            </Button>
          )}
        </>
      )}
      {/*{showAll ? (*/}
      {/*  <>*/}
      {/*    {usages.map((usage) => (*/}
      {/*      getClickableItem(usage.artifact_id, usage.object_type, usage.artifact_name, handleClickField,*/}
      {/*        !usage.object_type?.startsWith('ct_'))*/}
      {/*    ))}*/}
      {/*    <CustomListItem label={<ExpandLess />} onClick={() => setShowAll(false)} />*/}
      {/*  </>*/}
      {/*) : (*/}
      {/*  <>*/}
      {/*    {usages.slice(0, MAX_USAGE_CHIPS).map((usage) => (*/}
      {/*      getClickableItem(usage.artifact_id, usage.object_type, usage.artifact_name, handleClickField,*/}
      {/*        !usage.object_type?.startsWith('ct_'))*/}
      {/*    ))}*/}
      {/*    {usages.length > MAX_USAGE_CHIPS && (*/}
      {/*      <CustomListItem label={`+${usages.length - MAX_USAGE_CHIPS}`} onClick={() => setShowAll(true)} />*/}
      {/*    )}*/}
      {/*  </>*/}
      {/*)}*/}
    </Box>
  )
}