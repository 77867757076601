import { HttpService } from './HttpService';

export class MatchApi extends HttpService {
  constructor(baseUrl = process.env.REACT_APP_WASH_API_HOST) {
    super();
    this.baseUrl = baseUrl;
  }

  async checkOk(p) {
    const res = await p;
    if (!res?.ok) {
      return new Promise((reject) => {
        reject(p);
      })
    }
    return res;
  }

  async getBase() {
    return this.json(this.get('/'));
  }

  getBaseUrl() {
    return this.baseUrl;
  }

  async getAnalysisUuids() {
    return this.json(
      this.get('/analysis/')
    )
  }

  async getListTypes() {
    return this.json(
      this.get('/listtypes/')
    )
  }

  async json(p) {
    const res = await this.checkOk(p);
    return res.json();
  }

  async matchBuilding(matchPayload) {
    if (!matchPayload) {
      throw Error('[MatchApi] No match request payload found');
    }
    const searchString = await this.serialize(matchPayload);
    return this.json(this.get(`/building/?${searchString}`));
  }

  async matchConcept(matchPayload) {
    if (!matchPayload) {
      throw Error('[MatchApi] No match request payload found');
    }
    const searchString = await this.serialize(matchPayload);
    return this.json(this.get(`/concept/?${searchString}`));
  }

  async matchGroup(matchPayload) {
    if (!matchPayload) {
      throw Error('[MatchApi] No match request payload found');
    }
    const searchString = await this.serialize(matchPayload);
    return this.json(this.get(`/group/?${searchString}`));
  }

  async matchPerson(matchPayload) {
    if (!matchPayload) {
      throw Error('[MatchApi] No match request payload found');
    }
    const searchString = await this.serialize(matchPayload);
    return this.json(this.get(`/person/?${searchString}`));
  }

  async matchNavalVessel(matchPayload) {
    if (!matchPayload) {
      throw Error('[MatchApi] No match request payload found');
    }
    const searchString = await this.serialize(matchPayload);
    return this.json(this.get(`/navalvessel/?${searchString}`))
  }

  async matchOrganization(matchPayload) {
    if (!matchPayload) {
      throw Error('[MatchApi] No match request payload found');
    }
    const searchString = await this.serialize(matchPayload);
    return this.json(this.get(`/organization/?${searchString}`));
  }

  async matchStructure(matchPayload) {
    if (!matchPayload) {
      throw Error('[MatchApi] No match request payload found');
    }
    const searchString = await this.serialize(matchPayload);
    return this.json(this.get(`/structure/?${searchString}`));
  }

  async serialize(obj) {
    const str = [];
    for (const p in obj)
      if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    return str.join('&');
  }
}
