export const ERROR_TYPES = {
  TYPE_ERROR: 1,
  NAME_WARNING: 2,
  STATE_ERROR: 3,
  TIME_ERROR: 4,
  AUTHORITY_STATE_ERROR: 5,
  BEGIN_WARNING: 6,
  TERM_NAME_WARNING: 7,
  TERM_STATE_ERROR: 8,
  TERM_TOO_LONG: 9
}

export const getErrorTypeDetails = (type) => {
  switch(type) {
    case ERROR_TYPES.TYPE_ERROR:
      return {
        title: 'analysis.title.type',
        description: 'analysis.description.type'
      }
    case ERROR_TYPES.NAME_WARNING:
      return {
        title: 'analysis.title.name',
        description: 'analysis.description.name'
      }
    case ERROR_TYPES.STATE_ERROR:
      return {
        title: 'analysis.title.state',
        description: 'analysis.description.state'
      }
    case ERROR_TYPES.TIME_ERROR:
      return {
        title: 'analysis.title.time',
        description: 'analysis.description.time'
      }
    case ERROR_TYPES.AUTHORITY_STATE_ERROR:
      return {
        title: 'analysis.title.authority',
        description: 'analysis.description.authority'
      }
    case ERROR_TYPES.BEGIN_WARNING:
      return {
        title: 'analysis.title.begin',
        description: 'analysis.description.begin'
      }
    case ERROR_TYPES.TERM_NAME_WARNING:
      return {
        title: 'analysis.title.terms.name',
        description: 'analysis.description.terms.name'
      }
    case ERROR_TYPES.TERM_STATE_ERROR:
      return {
        title: 'analysis.title.terms.state',
        description: 'analysis.description.terms.state'
      }
    case ERROR_TYPES.TERM_TOO_LONG:
      return {
        title: 'analysis.title.terms.termLength',
        description: 'analysis.description.terms.termLength'
      }
    default: {
      break;
    }
  }
}