export const TERM_CT_PREFIXES = ['ct_6', 'ct_9', 'ct_20', 'ct_21', 'ct_22', 'ct_25'];

export const getCtPrefix = (idString) => {
  const strArr = idString.replace(/[^\w-]/g, '').split('-');
  return strArr.length ? strArr[0].toLowerCase() : '';
};

export const getIsTerm = (id) => {
  return TERM_CT_PREFIXES.includes(getCtPrefix(id));
};
