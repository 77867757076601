import React from 'react';
import { Edit } from '@mui/icons-material';
import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const TypeListItem = ({onClick}) => {
  const { t } = useTranslation('translations');

  return (
    <ListItemButton onClick={onClick}>
      <ListItemIcon>
        <Edit />
      </ListItemIcon>
      <ListItemText primary={t('menu.actionHeader.listItems.changeType')} />
    </ListItemButton>
  )
}