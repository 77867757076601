import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { createSetInvalidOperation, createSetValidOperation } from '../../utils/primusMetaOperationHandler';
import { dumpAllIndexedData, toggleBookmarkedRow, toggleEditedRow } from '../../services/IndexedDbService';
import { getDefaultColumns, getTermsColumns } from '../../declarations/TvattTableColumnDeclarations';
import { PrimusApi } from '../../services/PrimusApi';
import { TopLevelTransition } from '../../components/TopLevelTransition/TopLevelTransition';
import { TvattTable } from '../../components/TvattTable/TvattTable';
import { useAuthenticatedRouteMatch } from '../../app/auths/useAuthenticatedRouteMatch';
import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from '../../contexts/MainContext';
import { SET_INDEXED_DATA, SET_TABLE_DATA, useTableDispatch, useTableState } from "../../contexts/TableContext";
import { usePrimusState } from "../../contexts/PrimusContext";
import { isUUID } from "validator";

export const DuplicateView = () => {
  const mainDispatch = useMainDispatch();
  const tableDispatch = useTableDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const match = useAuthenticatedRouteMatch('/duplicate');

  const [tableLoading, setTableLoading] = useState(false);

  const {notifications, refreshTable } = useMainState();
  const { data, indexedData } = useTableState();
  const { baseUrl, selectedList, selectedListStatus, version } = usePrimusState();
  const { t } = useTranslation('translations');

  let api;

  const bookmarkRow = useCallback((row) => {
    let tempTableData = data;
    const index = tempTableData.findIndex((r) => r.id === row.id);

    if(index !== -1) {
      tempTableData[index].bookmarked = !row.original.bookmarked;
      toggleBookmarkedRow(row.id, tempTableData[index].bookmarked).then(() => {
        return dumpAllIndexedData();
      }).then((data) => {
        tableDispatchCallback({
          type: SET_INDEXED_DATA,
          indexedData: data
        });
      });
    }

    tableDispatchCallback({
      type: SET_TABLE_DATA,
      data: [...tempTableData]
    })
  }, [data, tableDispatchCallback]);

  const toggleStatus = useCallback((row, checked) => {
    let tempTableData = data;
    let index = tempTableData.findIndex((r) => row.id === r.id);

    api = new PrimusApi(baseUrl);

    if (index !== -1) {
      tempTableData[index].isLoading = true;
      tableDispatchCallback({type: SET_TABLE_DATA, data: [...tempTableData]});

      api.getUsage([tempTableData[index].id]).then((result) => {
        if (result[tempTableData[index].id].length === 0) {
          let operation = checked
            ? createSetInvalidOperation([row], -1)
            : createSetValidOperation([row], -1);

          return api.executeOperationStep(operation);
        }
        else {
          return Promise.reject(result[tempTableData[index].id].length);
        }
      }).then(() => {
        tempTableData[index].isLoading = false;
        tempTableData[index].inValid = checked;
        tempTableData[index].edit = new Date().toISOString();
      }).catch((newAmount) => {
        tempTableData[index].isLoading = false;
        tempTableData[index].frequency = newAmount;

        mainDispatchCallback({
          type: SET_NOTIFICATIONS,
          notifications: [...notifications,
            {
              text: t('notifications.statusInUse', {item: row.original.caption}),
              type: 'information',
              duration: 6000
            }
          ]
        });
      }).finally(() => {
        toggleEditedRow(tempTableData[index]).then(dumpAllIndexedData)
          .then((data) => {
            tableDispatchCallback({
              type: SET_INDEXED_DATA,
              indexedData: data
            });
          });
        tableDispatchCallback({type: SET_TABLE_DATA, data: [...tempTableData]});
      });
    }
  }, [mainDispatchCallback, data, notifications]);

  const columns = useMemo(() => {
    if (isUUID(selectedList.split('-').splice(1).join('-')) || selectedList.startsWith('(')) {
      if (selectedList.startsWith('ct_25')) {
        return getTermsColumns(t, api, bookmarkRow, toggleStatus);
      }
      return getDefaultColumns(t, api, toggleStatus, bookmarkRow);
    }
    else {
      return getTermsColumns(t, api, bookmarkRow, toggleStatus);
    }
  }, [t, api, bookmarkRow, toggleStatus]);

  useEffect(() => {
    if (!!selectedList && match) {
      setTableLoading(true);
      let api = new PrimusApi(baseUrl);

      api.getSelectedListData(indexedData, selectedList, t, true, selectedListStatus, version > 222).then((list) => {
        tableDispatchCallback({
          type: SET_TABLE_DATA,
          data: [...list]
        });

        setTableLoading(false);
      }).catch((e) => {
        mainDispatchCallback({
          type: SET_NOTIFICATIONS,
          notifications: [...notifications,
            {
              text: t('table.default.jobs.errorSettingTableData'),
              type: 'error',
              error: e,
            }
          ]
        });
      });
    }
  }, [refreshTable, selectedListStatus, baseUrl, selectedList, match])

  if (match) {
    return (
      <TopLevelTransition>
        <TvattTable
          columns={columns}
          groupBy={['caption']}
          isLoading={tableLoading}
          sortBy={[{ id: 'duplicates', desc: true }]}
        />
      </TopLevelTransition>
    )
  }
}