import React, { useCallback, useEffect } from 'react';
import { refreshToken } from '@ekultur/authentication';
import { useLocation } from 'react-router-dom';

import { useAuthsDispatch, USER_LOGGED_IN } from './AuthsContext';

export const WithAuthentication = ({ children }) => {
  const dispatch = useAuthsDispatch();
  const dispatchCallback = useCallback(dispatch, [dispatch]);
  const location = useLocation();

  useEffect(() => {
    if (!location.pathname.startsWith('/oauth2/callback')) {
      refreshToken({
        apiGateway: window._env_.REACT_APP_API_GATEWAY,
        onSuccess: () => dispatchCallback({type: USER_LOGGED_IN})
      })
    }
  }, [dispatchCallback, location.pathname])

  return (
    <>
      {children}
    </>
  )
}