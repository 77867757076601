import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { isUUID } from "validator";
import { useTranslation } from 'react-i18next';

import { createSetInvalidOperation, createSetValidOperation } from '../../utils/primusMetaOperationHandler';
import { getDefaultColumns, getTermsColumns } from '../../declarations/TvattTableColumnDeclarations';
import { dumpAllIndexedData, toggleBookmarkedRow, toggleEditedRow } from '../../services/IndexedDbService';
import { PrimusApi } from '../../services/PrimusApi';
import { TopLevelTransition } from '../../components/TopLevelTransition/TopLevelTransition';
import { TvattTable } from '../../components/TvattTable/TvattTable';
import { useAuthenticatedRouteMatch } from '../../app/auths/useAuthenticatedRouteMatch';
import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from '../../contexts/MainContext';
import { SET_INDEXED_DATA, SET_TABLE_DATA, useTableDispatch, useTableState } from "../../contexts/TableContext";
import { usePrimusState } from "../../contexts/PrimusContext";

export const ListView = () => {
  const mainDispatch = useMainDispatch();
  const tableDispatch = useTableDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);
  const match = useAuthenticatedRouteMatch('/');

  const [tableLoading, setTableLoading] = useState(false);

  const { notifications, refreshTable } = useMainState();
  const { baseUrl, selectedList, selectedListStatus, version } = usePrimusState();
  const { data, indexedData } = useTableState();

  const dispatchSetTableData = (data) => {
    tableDispatchCallback({
      type: SET_TABLE_DATA,
      data: [...data]
    })
  };

  const { t } = useTranslation('translations');

  const api = useMemo(() => new PrimusApi(baseUrl), [baseUrl]);

  const bookmarkRow = useCallback((row) => {
    let tempTableData = data;
    const index = tempTableData.findIndex((r) => r.id === row.id);

    if(index !== -1) {
      tempTableData[index].bookmarked = !row.original.bookmarked;
      toggleBookmarkedRow(row.id, tempTableData[index].bookmarked).then(() => {
        return dumpAllIndexedData();
      }).then((data) => {
        tableDispatchCallback({
          type: SET_INDEXED_DATA,
          indexedData: data
        });
      });
    }

    dispatchSetTableData(tempTableData);
  }, [tableDispatchCallback, data]);

  const toggleStatus = useCallback((row, checked) => {
    let tempTableData = data;
    let index = tempTableData.findIndex((r) => row.id === r.id);

    if (index !== -1) {
      tempTableData[index].isLoading = true;
      dispatchSetTableData(tempTableData);

      api.getUsage([tempTableData[index].id]).then((result) => {
        const resultLength = result[tempTableData[index].id].length
        if (resultLength === 0) {
          let operation = checked
            ? createSetInvalidOperation([row], -1)
            : createSetValidOperation([row], -1);

          return api.executeOperationStep(operation);
        }
        else {
          return Promise.reject(resultLength);
        }
      }).then(() => {
        tempTableData[index].isLoading = false;
        tempTableData[index].inValid = checked;
        tempTableData[index].edit = new Date().toISOString();
      }).catch((newAmount) => {
        tempTableData[index].isLoading = false;
        tempTableData[index].frequency = Number(newAmount) || 0;

        mainDispatchCallback({
          type: SET_NOTIFICATIONS,
          notifications: [...notifications,
            {
              text: t('notifications.statusInUse', {item: row.original.caption}),
              type: 'information',
              duration: 6000
            }
          ]
        });
      }).finally(() => {
        toggleEditedRow(tempTableData[index]).then(dumpAllIndexedData)
          .then((data) => {
            tableDispatchCallback({
              type: SET_INDEXED_DATA,
              indexedData: data
            });
          });
        dispatchSetTableData(tempTableData);
      });
    }
  }, [mainDispatchCallback, data, notifications]);

  let columns = useMemo(() => {
    if (isUUID(selectedList.split('-').splice(1).join('-')) || selectedList.startsWith('(')) {
      if (selectedList.startsWith('ct_25')) {
        return getTermsColumns(t, api, bookmarkRow, toggleStatus);
      }
      return getDefaultColumns(t, api, toggleStatus, bookmarkRow);
    }
    else {
      return getTermsColumns(t, api, bookmarkRow, toggleStatus);
    }
  }, [t, toggleStatus, bookmarkRow, api, selectedList]);

  useEffect(() => {
    if (!!selectedList && match) {
      setTableLoading(true);
      let api = new PrimusApi(baseUrl);
      api.getSelectedListData(indexedData, selectedList, t, false, selectedListStatus, version > 222).then((list) => {
        dispatchSetTableData(list);
        setTableLoading(false);
      }).catch((e) => {
        mainDispatchCallback({
          type: SET_NOTIFICATIONS,
          notifications: [...notifications,
            {
              text: t('table.default.jobs.errorSettingTableData'),
              type: 'error',
              error: e,
            }
          ]
        });
      });
    }
  }, [refreshTable, selectedListStatus, baseUrl, selectedList, match, version])

  if (match) {
    return (
      <TopLevelTransition>
        <TvattTable columns={columns} isLoading={tableLoading} />
      </TopLevelTransition>
    )
  }
}