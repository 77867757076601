import { TollOutlined } from '@mui/icons-material';
import { Badge, IconButton } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isUUID } from "validator";

import { REFRESH_TABLE, useMainDispatch } from '../../contexts/MainContext';
import { usePrimusState } from "../../contexts/PrimusContext";
import {
  SET_INDEXED_DATA,
  SET_TABLE_DATA,
  SET_TABLE_DATA_SELECTION,
  useTableDispatch,
  useTableState
} from "../../contexts/TableContext";
import {
  getDefaultColumns,
  getDuplicatesColumns,
  getTermsColumns
} from '../../declarations/TvattTableColumnDeclarations';
import { dumpAllIndexedData, toggleBookmarkedRow, toggleMarkedNoDuplicatesRow } from '../../services/IndexedDbService';
import { PrimusApi } from '../../services/PrimusApi';
import { createSetInvalidOperation, createSetValidOperation } from '../../utils/primusMetaOperationHandler';
import { StepperDialog } from '../StepperDialog/StepperDialog';

export const DuplicateCellButton = ({ renderedCellValue, row }) => {
  const [step0Data, setStep0Data] = useState([]);
  const [duplicateDialogOpen, setDuplicateDialogOpen] = useState(false);

  let api;
  const mainDispatch = useMainDispatch();
  const tableDispatch = useTableDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const { baseUrl, selectedList } = usePrimusState();
  const { data, selectedData } = useTableState();
  const { t } = useTranslation('translations');

  const bookmarkRow = (row) => {
    let tempTableData = data;
    let index = tempTableData.findIndex((r) => r.id === row.id);

    if (index !== -1) {
      tempTableData[index].bookmarked = !row.original.bookmarked;
      toggleBookmarkedRow(row.id, tempTableData[index].bookmarked).then(() => {
        return dumpAllIndexedData();
      }).then((data) => {
        tableDispatchCallback({
          type: SET_INDEXED_DATA,
          indexedData: data
        });
      });
    }
    setStep0Data((state) => [...state]);

    tableDispatchCallback({
      type: SET_TABLE_DATA,
      data: [...tempTableData]
    })
  }

  const toggleStatus = (r, checked) => {
    api = new PrimusApi(baseUrl);
    let operation = checked
      ? createSetInvalidOperation([r], -1)
      : createSetValidOperation([r], -1)

    api.executeOperationStep(operation).then((res) => {
      let tempTableData = data;
      let index = tempTableData.findIndex((d) => r.id === d.id);

      if (index !== -1) {
        tempTableData[index].inValid = !checked;
      }

      setStep0Data((state) => {
        let temp = state;
        let index = temp.findIndex((d) => r.id === d.id);

        if (index !== -1) {
          temp.splice(index, 1);
        }

        return [...temp];
      });

      let temp = {};

      Object.keys(selectedData).forEach((artifactId) => {
        if (artifactId !== r.id && !artifactId.includes('caption')) {
          temp[artifactId] = selectedData[artifactId];
        }
      });

      tableDispatchCallback({type: SET_TABLE_DATA_SELECTION, selectedData: {...temp}});
      tableDispatchCallback({type: SET_TABLE_DATA, data: [...tempTableData]});
    });
  }

  const dialogCancelled = () => {
    setDuplicateDialogOpen(false);
  }

  const dialogConfirmed = (rows, winningRowId, noDuplicate = false) => {
    let tempTableData = data;

    if (noDuplicate) {
      Object.keys(rows).forEach((artifactId) => {
        let index = tempTableData.findIndex((d) => d.id === artifactId);
        if (index !== -1) {
          tempTableData[index].duplicates = 1;
          tempTableData[index].duplicateData = [];
        }
        toggleMarkedNoDuplicatesRow(artifactId, true).then(() => {
          return dumpAllIndexedData();
        }).then((data) => {
          tableDispatchCallback({
            type: SET_INDEXED_DATA,
            indexedData: data
          });

          mainDispatchCallback({
            type: REFRESH_TABLE
          })
        });
      });
    }
    setDuplicateDialogOpen(false);
  }

  const setDialogOpen = () => {
    let tempData = [];
    let tempSelected = {};

    row.original.duplicateData.forEach((d) => {
      const index = data.findIndex((r) => r.id === d.id);
      if (index !== -1) {
        tempData.push(data[index]);
        tempSelected[data[index].id] = true;
      }
    });

    tableDispatchCallback({
      type: SET_TABLE_DATA_SELECTION,
      selectedData: {...tempSelected}
    });

    setStep0Data(tempData);
    setDuplicateDialogOpen(true);
  }

  const columns = useMemo(() => {
    return getDuplicatesColumns(t, selectedList.includes('ct_'));
  }, [t])

  const duplicateColumns = useMemo(() => {
    if (isUUID(selectedList.split('-').splice(1).join('-')) || selectedList.startsWith('(')) {
      if (selectedList.startsWith('ct_25')) {
        return getTermsColumns(t, api, bookmarkRow, toggleStatus);
      }
      return getDefaultColumns(t, api, toggleStatus, bookmarkRow);
    }
    else {
      return getTermsColumns(t, api, bookmarkRow, toggleStatus);
    }
  }, [t, api, bookmarkRow, toggleStatus]);

  return (
    <>
      <IconButton
        aria-label='duplicates'
        onClick={setDialogOpen}
      >
        <Badge badgeContent={renderedCellValue} color='primary'>
          <TollOutlined color='warning' />
        </Badge>
      </IconButton>
      <StepperDialog
        key={row.index}
        columns={columns}
        duplicateColumns={duplicateColumns}
        disableBackdropDismiss={true}
        displayObjectInTitle={false}
        duplicateRows={step0Data}
        enableRowSelection={true}
        open={duplicateDialogOpen}
        onCancel={dialogCancelled}
        onConfirm={dialogConfirmed}
        step0={true}
        translationKey='duplicates'
      />
    </>
  )
}