import React, { Fragment, useCallback } from 'react';
import { Box, Button, Chip, IconButton, Typography } from '@mui/material';
import { Cancel, FilterAltOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import {
  useTableDispatch,
  useTableState,
  SET_TABLE_FILTER,
  SET_TABLE_FILTER_NAME,
  SET_TABLE_SORTING
} from "../../contexts/TableContext";

export const FilterHeader = () => {
  const tableDispatch = useTableDispatch();
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const { t } = useTranslation('translations');
  const { filter } = useTableState();

  const getBooleanLabel = (filterId, value) => {
    switch (filterId) {
      case 'edit':
        return value ? t('table.default.header.isEdited') : t('table.default.header.isNotEdited');

      case 'errors':
        return value ? t('table.default.header.hasErrors') : t('table.default.header.noErrors');

      case 'matchings':
        if (value === null) return t('table.default.header.noMatchPerformed');
        return value ? t('table.default.header.hasMatch') : t('table.default.header.noMatch');

      default:
        return '?';
    }
  }

  const formatLabel = (filterValue) => {
    switch (filterValue.id) {
      case 'agentTypeIdValue':
        return t('table.default.header.type')

      case 'authority':
      case 'authorityIdValue': 
        return filterValue.value ? t('filters.hasAuthority') : t('filters.noAuthority');

      case 'beginDate':
        if (filterValue.value === 'or') return t('filters.hasDobDod')
        return filterValue.value ? t('filters.hasDob') : t('filters.noDob');

      case 'bookmarked':
        return filterValue.value ? t('filters.isBookmarked') : t('filters.notBookmarked');
      
      case 'caption':
        return `${t('table.default.header.term')}: ${filterValue.value}`;

      case 'code':
        return `${t('table.default.header.code')}: ${filterValue.value}`;

      case 'duplicates':
        return filterValue.value ? t('filters.hasDuplicates') : t('filters.noDuplicates');

      case 'edit':
        return filterValue.value ? t('table.default.header.isEdited') : t('table.default.header.isNotEdited');

      case 'endDate': 
        return filterValue.value ? t('filters.hasDod') : t('filters.noDod');

      case 'errors':
        return t('table.default.header.warning');

      case 'frequency':
        return filterValue.value ? t('filters.hasFrequency') : t('filters.noFrequency');

      case 'hierarchy':
        return t('table.default.header.hierarchy');

      case 'inValid':
        return filterValue.value ? t('filters.isInvalid') : t('filters.isValid');

      case 'matchings':
        return t('table.default.header.matchings')

      default:
        return 'No Valid label'  
    }
  }

  const clearFilters = () => {
    tableDispatchCallback({
      type: SET_TABLE_FILTER,
      filter: []
    });

    tableDispatchCallback({
      type: SET_TABLE_FILTER_NAME,
      filterName: ''
    });

    tableDispatchCallback({
      type: SET_TABLE_SORTING,
      sorting: [{id: 'caption', desc: false}]
    })
  }

  const handleRemoveFilter = (filterId) => {
    let tempFilters = filter;

    tempFilters = tempFilters.filter((f) => f.id !== filterId);

    tableDispatchCallback({
      type: SET_TABLE_FILTER,
      filter: [...tempFilters]
    });

    tableDispatchCallback({
      type: SET_TABLE_FILTER_NAME,
      filterName: ''
    });
  }

  const handleRemoveFilterFromArray = (filterId, value) => {
    let tempFilters = filter;
    let index = tempFilters.findIndex((f) => f.id === filterId);

    if (index !== -1) {
      tempFilters[index].value = tempFilters[index].value.filter((v) => v !== value);
    }

    tableDispatchCallback({
      type: SET_TABLE_FILTER,
      filter: [...tempFilters]
    })
  }

  if (filter.length === 0) {
    return null;
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: '#e5f6fd',
        display: 'flex',
        flexDirection: 'row',
        flexShrink: 0,
        gap: '8px',
        height: '60px',
        paddingLeft: '8px'
      }}
    >
      <Button onClick={clearFilters}>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            justifyContent: 'center',
            width: '100%'
          }}
        >
          <FilterAltOff />
          {t('filters.clear')}
        </Box>
      </Button>
      { 
        filter.map((filter) => (
          <Fragment key={filter.id}>
            { (Array.isArray(filter.value) && filter.value.length > 0) &&
              <Box
                sx={{
                  alignItems: 'center',
                  backgroundColor: '#d3e3e9',
                  borderRadius: 50,
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4px',
                  padding: '2px 2px 2px 8px'
                }}
              >
                <Typography>
                  {formatLabel(filter)}
                </Typography>

                {
                  filter.value.map((value) => (
                    <Chip 
                      key={`${filter.id}-${value}`}
                      label={typeof value !== 'string' ? getBooleanLabel(filter.id, value) : value}
                      onDelete={() => handleRemoveFilterFromArray(filter.id, value)}
                      variant='outlined'
                    />
                  ))
                }

                <IconButton onClick={() => handleRemoveFilter(filter.id)}>
                  <Cancel />
                </IconButton>
              </Box>
            }
            { !Array.isArray(filter.value) &&
              <Chip 
                label={formatLabel(filter)}
                onDelete={() => handleRemoveFilter(filter.id)}
              />
            }
          </Fragment>
        ))
      }
    </Box>
  )
}