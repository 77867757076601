export const generateTableTranslation = (t) => {
  return {
    actions: t('table.default.actions'),
    and: t('table.default.and'),
    cancel: t('table.default.cancel'),
    changeFilterMode: t('table.default.changeFilterMode'),
    changeSearchMode: t('table.default.changeSearchMode'),
    clearFilter: t('table.default.clearFilter'),
    clearSearch: t('table.default.clearSearch'),
    clearSort: t('table.default.clearSort'),
    clickToCopy: t('table.default.clickToCopy'),
    collapse: t('table.default.collapse'),
    collapseAll: t('table.default.collapseAll'),
    columnActions: t('table.default.columnActions'),
    copiedToClipboard: t('table.default.copiedToClipboard'),
    dropToGroupBy: t('table.default.dropToGroupBy'),
    edit: t('table.default.edit'),
    expand: t('table.default.expand'),
    expandAll: t('table.default.expandAll'),
    filterArrIncludes: t('table.default.filterArrIncludes'),
    filterArrIncludesAll: t('table.default.filterArrIncludesAll'),
    filterArrIncludesSome: t('table.default.filterArrIncludesSome'),
    filterBetween: t('table.default.filterBetween'),
    filterBetweenInclusive: t('table.default.filterBetweenInclusive'),
    filterByColumn: t('table.default.filterByColumn'),
    filterContains: t('table.default.filterContains'),
    filterEmpty: t('table.default.filterEmpty'),
    filterEndsWith: t('table.default.filterEndsWith'),
    filterEquals: t('table.default.filterEquals'),
    filterEqualsString: t('table.default.filterEqualsString'),
    filterFuzzy: t('table.default.filterFuzzy'),
    filterGreaterThan: t('table.default.filterGreaterThan'),
    filterGreaterThanOrEqualTo: t('table.default.filterGreaterThanOrEqualTo'),
    filterInNumberRange: t('table.default.filterInNumberRande'),
    filterIncludesString: t('table.default.filterIncludesString'),
    filterIncludesStringSensitive: t('table.default.filterIncludesStringSensitive'),
    filterLessThan: t('table.default.filterLessThan'),
    filterLessThanOrEqualTo: t('table.default.filterLessThanOrEqualTo'),
    filterMode: t('table.default.filterMode'),
    filterNotEmpty: t('table.default.filterNotEmpty'),
    filterNotEquals: t('table.default.filterNotEquals'),
    filterStartsWith: t('table.default.filterStartsWith'),
    filterWeakEquals: t('table.default.filterWeakEquals'),
    filteringByColumn: t('table.default.filteringByColumn'),
    goToFirstPage: t('table.default.goToFirstPage'),
    goToLastPage: t('table.default.goToLastPage'),
    goToNextPage: t('table.default.goToNextPage'),
    goToPreviousPage: t('table.default.goToPreviousPage'),
    grab: t('table.default.grab'),
    groupByColumn: t('table.default.groupByColumn'),
    groupedBy: t('table.default.groupedBy'),
    hideAll: t('table.default.hideAll'),
    hideColumn: t('table.default.hideColumn'),
    max: t('table.default.max'),
    min: t('table.default.min'),
    move: t('table.default.move'),
    noRecordsToDisplay: t('table.default.noRecordsToDisplay'),
    noResultsFound: t('table.default.noResultsFound'),
    of: t('table.default.of'),
    or: t('table.default.or'),
    pinToLeft: t('table.default.pinToLeft'),
    pinToRight: t('table.default.pinToRight'),
    resetColumnSize: t('table.default.resetColumnSize'),
    resetOrder: t('table.default.resetOrder'),
    rowActions: t('table.default.rowActions'),
    rowNumber: t('table.default.rowNumber'),
    rowNumbers: t('table.default.rowNumbers'),
    rowsPerPage: t('table.default.rowsPerPage'),
    save: t('table.default.save'),
    search: t('table.default.search'),
    selectedCountOfRowCountRowsSelected: t('table.default.selectedCountOfRowCountRowsSelected'),
    select: t('table.default.select'),
    showAll: t('table.default.showAll'),
    showAllColumns: t('table.default.showAllColumns'),
    showHideColumns: t('table.default.showHideColumns'),
    showHideFilters: t('table.default.showHideFilters'),
    showHideSearch: t('table.default.showHideSearch'),
    sortByColumnAsc: t('table.default.sortByColumnAsc'),
    sortByColumnDesc: t('table.default.sortByColumnDesc'),
    sortedByColumnAsc: t('table.default.sortedByColumnAsc'),
    sortedByColumnDesc: t('table.default.sortedByColumnDesc'),
    thenBy: t('table.default.thenBy'),
    toggleDensity: t('table.default.toggleDensity'),
    toggleFullScreen: t('table.default.toggleFullScreen'),
    toggleSelectAll: t('table.default.toggleSelectAll'),
    toggleSelectRow: t('table.default.toggleSelectRow'),
    toggleVisibility: t('table.default.toggleVisibility'),
    ungroupByColumn: t('table.default.ungroupByColumn'),
    unpin: t('table.default.unpin'),
    unpinAll: t('table.default.unpinAll'),
    unsorted: t('table.default.unsorted'),
  }
}