import { Explore, OpenInNew } from "@material-ui/icons";
import {
  Abc,
  AccountBalance,
  CollectionsBookmark, ContentCopy,
  DesignServices,
  Event,
  Image,
  Palette,
  Person, PushPin,
  Sailing,
  Summarize,
  ViewInAr,
  Voicemail
} from "@mui/icons-material";
import { Box, Chip, TextField, Tooltip } from "@mui/material";
import React from "react";
import {
  HISTORY_EVENT_TYPE_BIRTH_ID,
  HISTORY_EVENT_TYPE_DEATH_ID,
  SUPEROBJECT_HISTORY_EVENT_TYPE_ID
} from "../../declarations/UUIDs";

export const getEventObject = (date, precision, isEnd) => {
  return {
    create: true,
    collection: {
      collection_id: "e6ea5640-0464-4874-8b2d-a2fa532504fb-59a702ea-360f-42eb-a000-d6d7b9fa4d3c",
      is_root_model: false,
      meta_type: 'sub_model',
      object_type: 'SuperobjectCollection'
    },
    from_date: date ?? null,
    object_type: 'HistoryEvent',
    superobject_type_id: SUPEROBJECT_HISTORY_EVENT_TYPE_ID,
    type_id: isEnd ? HISTORY_EVENT_TYPE_DEATH_ID : HISTORY_EVENT_TYPE_BIRTH_ID,
    timespan_historic: {
      from_date: date ?? null,
      object_type: 'TimespanHistoric',
      from_date_precision: precision,
      meta_type: 'sub_model',
    },
    valid: true,
  }
};

export const getDatePrecision = (input) => {
  if (!input) return 'year';
  if (/^\d{4}-\d{2}-\d{2}$/.test(input)) return 'date';
  if (/^\d{4}-\d{2}$/.test(input)) return 'month';
  if (/^\d{4}$/.test(input)) return 'year';
  return null;
};


export const isDateChanged = (originalDate, stateDate) => {
  if (!originalDate && !stateDate) return false;
  return getDateString(originalDate) !==  getDateString(stateDate);
};

export const getDateString = (input) => {
  if (!input) return '';
  let dateArr = input.split('-');
  let year = dateArr[0] || '1';
  let month = dateArr.length > 1 ? dateArr[1] || '1' : '1';
  let day = dateArr.length > 2 ? dateArr[2] || '1' : '1';
  return `${year.padStart(4, '0')}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
}

export const getDateTextField = (value, onChange, label, disableEditing, yearFormatLabel) => {
  let invalidYear = !!value && (value.startsWith('-') || Number(value.split('-')[0]) === 0);
  let isValid = !!getDatePrecision(value) && !invalidYear;
  let dateFormat = 'YYYY / YYYY-MM / YYYY-MM-DD'
  let errorText = invalidYear ? yearFormatLabel : dateFormat;
  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      error={!isValid}
      placeholder={dateFormat}
      helperText={!isValid ? errorText : undefined}
      disabled={disableEditing}
    />
  );
}

export const getNameFromArtifact = (artifact) => {
  if (artifact.first_name?.first_name) {
    if (artifact.last_name?.last_name) {
      return `${artifact.last_name.last_name}, ${artifact.first_name.first_name}` +
        `${artifact.middle_name?.middle_name ? ' ' + artifact.middle_name.middle_name : ''}`;
    }
    return artifact.first_name.first_name;
  } else if (artifact.last_name?.last_name) {
    return `${artifact.middle_name?.middle_name ? artifact.middle_name.middle_name + ' ' : ''}` +
      `${artifact.last_name.last_name}`;
  } else {
    return artifact.name?.name ?? '';
  }
}

export function getPrimusUrl () {
  return process.env.REACT_APP_ENVIRONMENT === 'beta'
    ? 'https://beta.primus.org'
    : 'https://online.primus.org'
}

export const getIcon = (objectType) => {
  if (!objectType) return <ViewInAr />;
  switch (objectType) {
    case 'thing':
      return <ViewInAr />
    case 'agent':
      return <Person />;
    case 'Image':
      return <Image />;
    case 'photograph_copy':
    case 'photograph':
      return <Image />;
    case 'artwork':
      return <Palette />;
    case 'NavalVessel':
      return <Sailing />;
    case 'recording':
      return <Voicemail />;
    case 'design':
      return <DesignServices />;
    case 'architecture':
      return <AccountBalance />;
    case 'Collection':
      return <CollectionsBookmark />;
    case 'KulturNav':
      return <Explore />;
    default:
      if (objectType.startsWith('SpectrumProcedure')) return <Summarize />;
      if (objectType.startsWith('ct_')) return <Abc />;
      if (objectType.endsWith('Event')) return <Event />;
      return <ViewInAr />;
  }
};

export const DEFAULT_COLLECTION_ID = 'e6ea5640-0464-4874-8b2d-a2fa532504fb-59a702ea-360f-42eb-a000-d6d7b9fa4d3c'

export const getClickableItem = (objid, objectType, label, handleClickField, openIn, tooltip) => (
  <Tooltip
    key={objid}
    title={tooltip}
    enterDelay={600}
    slotProps={{ tooltip: { sx: { maxWidth: '400px' }}}}
  >
    <Chip
      color={objectType?.startsWith('ct_') || objectType === 'agent' ? undefined : 'primary'}
      size='small'
      sx={{ width: 'fit-content' }}
      onClick={(e) => handleClickField(e, objid, openIn)}
      disabled={objid === DEFAULT_COLLECTION_ID}
      icon={objectType ? getIcon(objectType) : null}
      label={
        <Box display='flex' alignItems='center' gap={0.5}>
          {label}
          {openIn ? <OpenInNew fontSize='inherit' /> : <ContentCopy fontSize='inherit' /> }
        </Box>
      }
    />
  </Tooltip>
)

export const getTermPinItem = (objid, label, handleClick, isPinned) => (
  <Chip
    key={objid}
    size='small'
    sx={{ width: 'fit-content' }}
    onClick={() => handleClick(objid)}
    variant={isPinned ? 'filled' : 'outlined'}
    label={
      <Box display='flex' alignItems='center' gap={0.5}>
        {label}
        <PushPin sx={{ fontSize: '16px !important' }} />
      </Box>
    }
  />
)
