import React, {useCallback, useState} from 'react';
import { Check, Close, Edit } from '@mui/icons-material';
import {
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { dumpAllIndexedData, toggleEditedRow } from "../../services/IndexedDbService";

import { createSetInvalidOperation, createSetValidOperation } from '../../utils/primusMetaOperationHandler';
import { PrimusApi } from '../../services/PrimusApi';
import { useMainDispatch, useMainState, SET_NOTIFICATIONS } from '../../contexts/MainContext';
import { usePrimusState } from "../../contexts/PrimusContext";
import { useTableDispatch, useTableState, SET_TABLE_DATA, SET_INDEXED_DATA } from "../../contexts/TableContext";

export const StatusListItem = () => {
  const [dialogData, setDialogData] = useState({});
  const [disableButtons, setDisableButtons] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const mainDispatch = useMainDispatch();
  const tableDispatch = useTableDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  const { notifications } = useMainState();
  const { baseUrl } = usePrimusState();
  const { data, selectedData } = useTableState();
  const { t } = useTranslation('translations');

  const cancelDialog = () => {
    setOpenDialog(false);
  }

  const startStatusJob = async () => {
    setLoading(true);
    setDisableButtons(true);

    let api = new PrimusApi(baseUrl);
    let operation = invalid
      ? createSetInvalidOperation(dialogData.toSetStatus, -1)
      : createSetValidOperation(dialogData.toSetStatus, -1);

    api.executeOperationStep(operation).then(() => {
      setLoading(false);
      setDisableButtons(false);
      setOpenDialog(false);
    }).catch((e) => {
      mainDispatchCallback({
        type: SET_NOTIFICATIONS,
        notifications: [...notifications,
          {
            text: t('table.default.jobs.connectionErrorPrimus'),
            type: 'error',
            error: e
          }
        ]
      });
    });

    let tempTableData = data;
    async function setTableData() {
      for (const row of dialogData.toSetStatus) {
        let index = tempTableData.findIndex((r) => r.id === row.id);
        if (index !== -1) {
          tempTableData[index].inValid = invalid;
          tempTableData[index].edit = new Date().toISOString();
          await toggleEditedRow(tempTableData[index]);
        }
      }
    }

    await setTableData().then(dumpAllIndexedData).then((data) => {
      tableDispatchCallback({
        type: SET_INDEXED_DATA,
        indexedData: data
      });
      tableDispatchCallback({
        type: SET_TABLE_DATA,
        data: [...tempTableData]
      });
    })
  }

  const showJobDialog = (invalid) => {
    let authority = [];
    let usage = [];
    let status = [];

    setInvalid(invalid);

    Object.keys(selectedData).forEach((artifactId, index) => {
      if (!artifactId.includes('caption')) {
        let row = data.find((row) => row.id === artifactId);
        if (row.authorityIdValue !== "") {
          authority.push(row);
        }
        if (row.frequency > 0) {
          usage.push(row)
        }
        if (row.authorityIdValue === '' && (!row.frequency || row.frequency === 0)) {
          status.push(row)
        }
      }
    });

    setDialogData({
      toSetStatus: status,
      lockedAuthority: authority,
      lockedUsage: usage
    });

    setOpenDialog(true);
  }

  return (
    <>
      <Dialog
        open={openDialog}
      >
        <DialogContent>
          { dialogData.toSetStatus?.length > 0 &&
            <DialogTitle>{t(invalid ? 'dialogs.status.statusChangeInvalid' : 'dialogs.status.statusChangeValid')}</DialogTitle>
          }
          {
            dialogData.toSetStatus?.map((row) => {
              return (
                <div key={row.id}>
                  {row.caption}
                </div>
              )
            })
          }

          { dialogData.lockedAuthority?.length > 0 &&
            <DialogTitle>{t('dialogs.status.lockedAuthority')}</DialogTitle>
          }
          {
            dialogData.lockedAuthority?.map((row) => {
              return (
                <div key={row.id}>
                  {row.caption}
                </div>
              )
            })
          }

          { dialogData.lockedUsage?.length > 0 &&
            <DialogTitle>{t('dialogs.status.lockedUsage')}</DialogTitle>
          }
          {
            dialogData.lockedUsage?.map((row) => {
              return (
                <div key={row.id}>
                  {row.caption}
                </div>
              )
            })
          }
        </DialogContent>
        { loading &&
          <LinearProgress />
        }
        <DialogActions>
          <Button
            disabled={disableButtons}
            onClick={startStatusJob}
          >
            {t('dialogs.status.confirm')}
          </Button>
          <Button
            disabled={disableButtons}
            onClick={cancelDialog}
          >
            {t('dialogs.status.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
      <ListItemButton onClick={() => setExpanded(!expanded)}>
        <ListItemIcon>
          <Edit />
        </ListItemIcon>
        <ListItemText primary={t('menu.actionHeader.listItems.changeStatus')} />
      </ListItemButton>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          <ListItemButton sx={{pl: 4}} onClick={() => showJobDialog(false)}>
            <ListItemIcon>
              <Check />
            </ListItemIcon>
            <ListItemText primary={t('menu.actionHeader.listItems.valid')} />
          </ListItemButton>
          <ListItemButton sx={{pl: 4}} onClick={() => showJobDialog(true)}>
            <ListItemIcon>
              <Close />
            </ListItemIcon>
            <ListItemText primary={t('menu.actionHeader.listItems.invalid')} />
          </ListItemButton>
        </List>
      </Collapse>
    </>
  )
}