import React, { useEffect, useState } from 'react';
import { MaterialReactTable } from 'material-react-table';
import { MoreHoriz } from '@mui/icons-material';
import {
  Alert,
  Badge,
  Box,
  Button,
  DialogActions,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Typography
} from '@mui/material';

import { createJoinOperation, getJoinOperationObjectParams } from '../../utils/primusMetaOperationHandler';
import { DetailPanel } from '../DetailPanels/DetailPanel';
import { generateTableTranslation } from '../../utils/tvattTableTranslation';
import { getClickableItem } from "../DetailPanels/DetailPanelUtils";
import { Step0Dialog } from "./Step0Dialog";
import { SET_TABLE_DATA_SELECTION, useTableState } from "../../contexts/TableContext";
import { TermDetailPanel } from "../DetailPanels/TermDetailPanel";

export const MergeDialog = ({
  activeStep,
  api,
  checkStatus,
  columns,
  disableButtons,
  dispatchTableCallback,
  dispatchNotification,
  displayObjectInTitle=false,
  duplicateColumns = null,
  duplicateRows,
  handleClickField,
  invalidateMergedRows,
  isTerm,
  isTestEnv,
  onCancel,
  onConfirm,
  matchRow,
  open=false,
  progress,
  setActiveStep,
  showProgress,
  t,
}) => {
  const [data, setData] = useState([]);
  const [mergeableData, setMergeableData] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedRowIndex, setSelectedRowIndex] = useState({});
  const [duplicateData, setDuplicateData] = useState(duplicateRows);
  const [disableNextButton, setDisableNextButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const [totalUsage, setTotalUsage] = useState(0);
  const [usageData, setUsageData] = useState([])
  const [step0SelectedRows, setStep0SelectedRows] = useState({});
  const { data: tableData, selectedData: selectedTableData } = useTableState();

  const [nextButtonLabel, setNextButtonLabel] = useState(t('dialogs.duplicates.next') || '')

  const tableLocalization = generateTableTranslation(t);

  const nextStep = () => {
    if (activeStep === -1) {
      if (Object.keys(step0SelectedRows).length > 0) {
        let tempSelected = {};
        Object.keys(step0SelectedRows).forEach((d) => {
          tempSelected[d] = true
        });

        dispatchTableCallback({
          type: SET_TABLE_DATA_SELECTION,
          selectedData: {...tempSelected}
        })
      }

      setActiveStep(0);
    } else if (activeStep === 0) {
      setNextButtonLabel(t('dialogs.duplicates.confirm'))
      setActiveStep(1);
    } else if (activeStep === 1) {
      onConfirm();
      dispatchNotification('information', 'table.default.jobs.startingMerge', 3000, undefined, undefined, true);
      let params = getJoinOperationObjectParams(mergeableData.map((d) => d.id), selectedRow.objectType);
      api.getOperationObject(params).then((obj) => {
        let operationObject = obj;
        let winnerIndex = operationObject.join_items.findIndex((item) => selectedRow.id === item.join_item_id);
        operationObject.join_items[winnerIndex].join_item_selected = true;
        return operationObject;
      }).then((obj) => {
        if (obj) {
          let operation = createJoinOperation(mergeableData);
          operation.operation_objects.push(obj)
          if (isTestEnv) console.info('[MergeDialog] Operation created', operation);
          return api.executeOperationStep(operation);
        } else {
          return new Error('Failed to make join operation');
        }
      }).then((res) => {
        function callbackFn() {
          api.getUsage([selectedRow.id]).then((res) => {
            let usageNr = res[selectedRow.id] ? res[selectedRow.id].length : 0;
            invalidateMergedRows(mergeableData, Object.keys(selectedRowIndex)[0], usageNr);
            dispatchTableCallback({
              type: SET_TABLE_DATA_SELECTION,
              selectedData: {}
            });
          }).catch((e) => {
            dispatchNotification('error', 'table.default.jobs.errorGettingUsage', undefined, undefined, e, true)
          });
        }
        checkStatus(res, callbackFn);
      }).catch((e) => {
        if (isTestEnv) console.error('[MergeDialog] Error with join operation:', e);
        dispatchNotification('error', 'table.default.jobs.operationError', undefined, undefined, e, true);
      });
    }
  }

  const previousStep = () => {
    if (activeStep === 1) {
      setNextButtonLabel(t('dialogs.duplicates.next'));
      setActiveStep(0);
    }
  }

  useEffect(() => {
    setDuplicateData(duplicateRows);
  }, [duplicateRows])

  useEffect(() => {
    if (open && activeStep === 0 && usageData.length === 0) {
      setData([]);
      setMergeableData([])
      setLoading(true);

      let ids = [];

      if (Object.keys(selectedRow).length < 1) {
        let skip = false;
        Object.keys(selectedTableData).filter((d) => !d.includes('caption')).forEach((artifactId, i) => {
          let index = tableData.findIndex((row) => row.id === artifactId);
          if (index > -1) {
            if (tableData[index].authorityIdValue === '') setDisableNextButton(false);
            setData((state) => [...state, tableData[index]]);
            if (!tableData[index].hasChildren) setMergeableData((state) => [...state, tableData[index]]);
            ids.push(artifactId);
            if (tableData[index].authorityIdValue === 'KulturNav' && !skip) {
              setSelectedRowIndex({ [i]: true });
              setSelectedRow(tableData[tableData.findIndex((r) => r.id === artifactId)]);
              skip = true;
            }
          }
        })
      }

      api.getUsage(ids).then((result) => {
        setUsageData(result);

        setData((data) => {
          let tempData = data;

          tempData.forEach((row) => {
            row.usage = result[row.id].length;
            setTotalUsage((old) => old + result[row.id].length);
          });

          setMergeableData(tempData.filter((row) => !row.hasChildren));

          return tempData;
        });

        setLoading(false);
      }).catch((e) => {
        if (isTestEnv) console.error('[StepperDialog] Error when getting usage', e);
        dispatchNotification('error', 'table.default.jobs.errorGettingUsage', undefined, undefined, e, true)
      });
    }
  }, [activeStep, open, selectedTableData, tableData])

  let openIn = isTerm ? '' : 'Primus';

  return (
    <>
      {activeStep === -1 ? (
        <Step0Dialog
          disableButtons={disableButtons}
          duplicateColumns={duplicateColumns}
          duplicateData={duplicateData}
          nextStep={nextStep}
          onCancel={onCancel}
          onConfirm={onConfirm}
          setStep0SelectedRows={setStep0SelectedRows}
          step0SelectedRows={step0SelectedRows}
          t={t}
        />
      ) : (
        <>
          <DialogTitle>
            {t('dialogs.duplicates.title')}
            {displayObjectInTitle && matchRow.original.caption}
          </DialogTitle>

          <Box
            sx={{
              margin: '0 auto',
              width: 'fit-content'
            }}
          >
            <Stepper activeStep={activeStep}>
              <Step>
                <StepLabel>{t('dialogs.duplicates.step1')}</StepLabel>
              </Step>
              <Step>
                <StepLabel>{t('dialogs.duplicates.step2')}</StepLabel>
              </Step>
            </Stepper>
          </Box>
          {activeStep === 0 && (
            <Box
              sx={{
                width: '90vw',
                overflowY: 'auto'
              }}
            >
              <Alert
                icon={false}
                severity={'info'}
                sx={{
                  margin: '10px'
                }}
              >
                {t('dialogs.duplicates.alert1')}
              </Alert>

              <MaterialReactTable
                columns={columns}
                data={data}
                enableMultiRowSelection={false}
                enablePagination={false}
                enablePinning={false}
                enableRowActions={false}
                enableRowSelection={(row) => !row.original.hasChildren}
                enableStickyHeader={true}
                enableStickyFooter={true}
                enableTopToolbar={false}
                enableBottomToolbar={false}
                icons={{MoreHorizIcon: () => (
                  <MoreHoriz
                    sx={{ backgroundColor: '#707070', borderRadius: '6px', color: '#FFFFFF', fontSize: '30px'}}
                  />
                )}}
                localization={tableLocalization}
                onRowSelectionChange={(state) => {
                  let index = tableData.findIndex((r) => r.id === data[Object.keys(state())[0]].id);
                  setSelectedRow(tableData[index]);
                  setSelectedRowIndex((s) => state(s));
                }}
                positionActionsColumn={'last'}
                state={{rowSelection: selectedRowIndex, isLoading: loading}}
                renderDetailPanel={({ row }) => row.original.hasOwnProperty('code')
                  ? <TermDetailPanel rowData={row.original} toggleIsExpanded={row.toggleExpanded} disableEditing />
                  : <DetailPanel disableEditing row={row} />
                }
                muiDetailPanelProps={{
                  sx: {
                    width: '100%',
                    '.MuiCollapse-vertical': {
                      width: '100%'
                    }
                  }
                }}
              />

              <Typography m={2}>
                {t('dialogs.duplicates.info1')}
              </Typography>
            </Box>
          )}
          {activeStep === 1 && (
            <Box
              sx={{
                padding: '15px',
                width: '90vw'
              }}
            >
              {selectedRow && (
                <Box mb={2}>
                  {selectedRow.authorityIdValue !== '' ? (
                    <Badge color='primary' variant='dot'>
                      <DialogContentText>{t('dialogs.duplicates.chosen')}</DialogContentText>
                      {getClickableItem(selectedRow.id, selectedRow.objectType,
                        `${selectedRow.caption} - ${t('dialogs.duplicates.usage')}: ${totalUsage}`,
                        handleClickField, openIn)}
                    </Badge>
                  ) : (
                    <>
                      <DialogContentText>{t('dialogs.duplicates.chosen')}</DialogContentText>
                      {getClickableItem(selectedRow.id, selectedRow.objectType,
                        `${selectedRow.caption} - ${t('dialogs.duplicates.usage')}: ${totalUsage}`,
                        handleClickField, openIn)}
                    </>
                  )}
                </Box>
              )}
              <Box display='flex' flexDirection='column' gap='4px'>
                <DialogContentText>{t('dialogs.duplicates.invalidated')}</DialogContentText>
                {mergeableData.map((row) => {
                  if (row.id !== selectedRow.id) {
                    if (row.authorityIdValue !== '') {
                      return (
                        <Badge key={row.id} color='primary' variant='dot'>
                          {getClickableItem(row.id, row.objectType,
                            `${row.caption} - ${t('dialogs.duplicates.usage')}: 0`, handleClickField, openIn)}
                        </Badge>
                      )
                    }
                    return getClickableItem(row.id, row.objectType,
                      `${row.caption} - ${t('dialogs.duplicates.usage')}: 0`, handleClickField, openIn);
                  }
                  return null;
                })}
              </Box>
              <Alert
                icon={false}
                severity={'info'}
                sx={{
                  marginTop: '10px'
                }}
              >
                {t('dialogs.duplicates.alert2')}
              </Alert>

              {showProgress &&
                <LinearProgress variant={'determinate'} value={progress}/>
              }

            </Box>
          )}
          <DialogActions>
            <Button
              disabled={disableButtons}
              onClick={(e) => {
                e.stopPropagation();
                onCancel();
              }}
            >
              {t('dialogs.duplicates.cancel')}
            </Button>
            <Button
              disabled={activeStep < 1 || disableButtons}
              onClick={(e) => {
                e.stopPropagation();
                previousStep();
              }}
            >
              {t('dialogs.duplicates.previous')}
            </Button>
            <Button
              disabled={Object.keys(selectedRowIndex).length === 0 || disableButtons || disableNextButton
                || mergeableData.length < 2}
              onClick={(e) => {
                e.stopPropagation();
                nextStep();
              }}
            >
              {nextButtonLabel}
            </Button>
          </DialogActions>
        </>
      )}
    </>
  )
}