import { Close } from "@mui/icons-material";
import { Box, Drawer, IconButton, List, ListItem, Typography } from '@mui/material';
import React from 'react';
import { getTermPinItem } from "./DetailPanelUtils";

export const SidePanelChildrenList = ({ childrenList, handleClick, open, setOpen, t, pinnedRows }) => {
  return (
    <Drawer open={open} anchor='right' onClose={() => setOpen(false)}>
      <Box overflow='hidden'>
        <Box
          sx={{
            alignItems: 'center',
            backgroundColor: (theme) => theme.palette.primary.dark,
            color: 'white',
            display: 'flex',
            height: '50px',
            justifyContent: 'flex-start',
            padding: '0 10px',
            width: '100%'
          }}
        >
          <Typography
            sx={{
              flexGrow: 1,
              textAlign: 'center'
            }}
            variant='h5'
          >
            {t('table.default.detailpane.children')}
          </Typography>
          <IconButton onClick={() => setOpen(false)} sx={{ color: 'white' }}>
            <Close />
          </IconButton>
        </Box>
        <Box height='calc(100% - 50px)' overflow='auto'>
          <List dense>
            {childrenList.map((c) => {
              return (
                <ListItem key={c.id} sx={{ paddingTop: 0 }}>
                  {getTermPinItem(c.id, c.name, handleClick, pinnedRows.indexOf(c.id) > -1)}
                </ListItem>
              )
            })}
          </List>
        </Box>
      </Box>
    </Drawer>
  )
}