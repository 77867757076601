import { Box, CircularProgress, Fade, MenuItem, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { isUUID } from "validator";

import { SET_NOTIFICATIONS, useMainDispatch, useMainState } from "../../contexts/MainContext";
import { SET_SELECTED_LIST_STATUS, usePrimusDispatch, usePrimusState } from "../../contexts/PrimusContext";
import { SET_TABLE_PINNED_ROWS, useTableDispatch } from "../../contexts/TableContext";
import { SolrSearchParams } from "../../declarations/SolrSearchParameters";
import { PrimusApi } from "../../services/PrimusApi";

export const StatusSelector = () => {
  const { t } = useTranslation('translations');
  const { baseUrl, selectedList, selectedListStatus } = usePrimusState();
  const { notifications } = useMainState();

  const [countData, setCountData] = useState({
    'true': 0,
    'false': 0,
    total: 0
  });
  const [loading, setLoading] = useState(true);

  const primusDispatch = usePrimusDispatch();
  const primusDispatchCallback = useCallback(primusDispatch, [primusDispatch]);
  const mainDispatch = useMainDispatch();
  const mainDispatchCallback = useCallback(mainDispatch, [mainDispatch]);
  const tableDispatch = useTableDispatch();
  const tableDispatchCallback = useCallback(tableDispatch, [tableDispatch]);

  useEffect(() => {
    if (selectedList) {
      setLoading(true);
      setCountData({
        'true': 0,
        'false': 0,
        total: 0
      });
      let api = new PrimusApi(baseUrl);
      let searchParameters = new SolrSearchParams();

      if ((isUUID(selectedList.split('-').splice(1).join('-')) || selectedList.startsWith('('))
        && !selectedList.startsWith('ct_25')) {
        searchParameters.setObjectType('agent');
        searchParameters.addToQuery('agent_type_id', selectedList);
        searchParameters.setQueryOpAnd(true);
        searchParameters.setResultLimit(1);
        searchParameters.setFacetFields(['valid']);
      } else if (selectedList.startsWith('ct_25')) {
        searchParameters.setObjectType('ct_25');
        searchParameters.addToQuery('m_path', `*${selectedList}*`);
        searchParameters.setQueryOpAnd(true);
        searchParameters.setResultLimit(1);
        searchParameters.setFacetFields(['valid']);
      } else {
        searchParameters.setObjectType(selectedList);
        searchParameters.setResultLimit(1);
        searchParameters.setFacetFields(['valid']);
      }

      // TODO: Handle duplicate view
      api.search(searchParameters).then((result) => {
        if (result?.status) {
          let tempData = {
            'true': 0,
            'false': 0,
            total: result.search_count
          };
          result.facets[0].items.forEach((facet) => {
            tempData[facet.id] = facet.count
          });

          setCountData(tempData);
        }
      }).finally(() => {
        setLoading(false);
      }).catch((e) => {
        setLoading(false);
        mainDispatchCallback({
          type: SET_NOTIFICATIONS,
          notifications: [...notifications,
            {
              text: t('table.default.jobs.errorGettingUsage'),
              type: 'error',
              error: e,
            }
          ]
        });
      })
    } else {
      setLoading(false);
    }
  }, [selectedList]);

  useEffect(() => {
    setCountData({
      'true': 0,
      'false': 0,
      total: 0
    })
  }, [baseUrl]);

  return (
    <>
      {!loading ? (
        <Fade in={true} unmountOnExit={true}>
          <TextField
            label={t('menu.actionHeader.status')}
            onChange={(event) => {
              if (event.target.value !== 'all') {
                tableDispatchCallback({
                  type: SET_TABLE_PINNED_ROWS,
                  pinnedRows: {
                    top: [],
                    bottom: []
                  }
                });
              }
              primusDispatchCallback({
                type: SET_SELECTED_LIST_STATUS,
                selectedListStatus: event.target.value
              });
            }}
            select
            sx={{
              width: 'fit-content',
              minWidth: '240px'
            }}
            value={selectedListStatus}
          >
            <MenuItem value={'valid'}>
              {`${t('menu.actionHeader.listItems.validPosts')} (${countData['true']})`}
            </MenuItem>

            <MenuItem value={'invalid'}>
              {`${t('menu.actionHeader.listItems.invalidPosts')} (${countData['false']})`}
            </MenuItem>

            <MenuItem value={'all'}>
              {`${t('menu.actionHeader.listItems.all')} (${countData.total})`}
            </MenuItem>
          </TextField>
        </Fade>
      ) : (
        <Fade in={true} unmountOnExit={true}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              padding: '.5rem .625rem',
              width: '15%'
            }}
          >
            <CircularProgress />
          </Box>
        </Fade>
      )}
    </>
  )
}